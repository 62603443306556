/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on December 17, 2018 */



@font-face {
    font-family: 'itau_displaybold';
    src: url('itaudisplay_bd-webfont.woff2') format('woff2'),
         url('itaudisplay_bd-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'itau_displayblack';
    src: url('itaudisplay_blk-webfont.woff2') format('woff2'),
         url('itaudisplay_blk-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'itau_displayheavy';
    src: url('itaudisplay_he-webfont.woff2') format('woff2'),
         url('itaudisplay_he-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'itau_displaylight';
    src: url('itaudisplay_lt-webfont.woff2') format('woff2'),
         url('itaudisplay_lt-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'itau_displayregular';
    src: url('itaudisplay_rg-webfont.woff2') format('woff2'),
         url('itaudisplay_rg-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'itau_displayxbold';
    src: url('itaudisplay_xbd-webfont.woff2') format('woff2'),
         url('itaudisplay_xbd-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}