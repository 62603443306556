// Default Input Text Theme
// -----------------------------------------------------------------------------

.forms-input {
  position: relative;

  * {
    transition: all 0.2s ease;
  }

  &.mat-form-field {
    width: 100%;
  }

  .mat-form-field-label {
    color: palette('zbrown', 'mid-dark');
  }

  .mat-input-element {
    color: palette('zbrown', 'dark');
    @extend %font-bold;
  }

  .mat-radio-label-content {
    color: palette('zbrown', 'dark');
  }

  .mat-form-field-underline {
    background-color: palette('zgray', 'light');

    .mat-form-field-ripple {
      background-color: darken(palette('zgray', 'light'), 5%);
    }
  }

  &.mat-focused {
    .mat-form-field-ripple {
      background-color: palette('brand', 'mid-dark') !important;
    }
  }

  .mat-hint {
    color: palette('zbrown', 'dark');
  }

  + .input-subtext {
    display: block;
    margin-top: -15px;
  }
}

.forms-input-large {
  @extend .forms-input;

  .mat-input-element {
    font-size: 1.5rem;
  }
}

// input text with width auto
// -----------------------------------------------------------------------------

.forms-input-width-auto {
  .mat-form-field-label {
    color: palette('zbrown', 'mid-dark');
  }

  .mat-input-element {
    color: palette('zbrown', 'dark');
    @extend %font-bold;
  }

  .mat-radio-label-content {
    color: palette('zbrown', 'dark');
  }

  .mat-form-field-underline {
    background-color: palette('zgray', 'light');

    .mat-form-field-ripple {
      background-color: darken(palette('zgray', 'light'), 5%);
    }
  }

  &.mat-focused {
    .mat-form-field-ripple {
      background-color: palette('brand', 'mid-dark') !important;
    }
  }

  .mat-hint {
    color: palette('zbrown', 'dark');
  }
}

// X-Light Input Text Theme
// -----------------------------------------------------------------------------

.forms-input-xlight {
  .mat-form-field-label {
    color: palette('zgray', 'x-light');
  }

  .mat-input-element {
    color: palette('zgray', 'x-light');
    @extend %font-bold;
  }

  .mat-form-field-underline {
    background-color: palette('zgray', 'light') !important;

    .mat-form-field-ripple {
      background-color: darken(palette('zgray', 'x-light'), 5%);
    }
  }

  .mat-form-field-disabled .mat-form-field-underline {
    background-image: linear-gradient(
      to right,
      rgba(palette('zgray', 'light'), 0.42) 0%,
      rgba(palette('zgray', 'light'), 0.42) 33%,
      transparent 0%
    );
  }

  .mat-hint {
    color: palette('zgray', 'x-light');
  }
}

// Success Input Text
// -----------------------------------------------------------------------------

.forms-input-success {
  .mat-input-element {
    color: palette('state', 'success-xlight');
    @extend %font-bold;
  }
}

// Danger Input Text
// -----------------------------------------------------------------------------

.forms-input-danger {
  .mat-input-element {
    color: palette('state', 'danger-xlight');
    @extend %font-bold;
  }
}

// Input Text Readonly
// -----------------------------------------------------------------------------

.forms-input-readonly {
  .mat-form-field-underline {
    height: 0;
    opacity: 0;
  }
}

// Input Textarea Unstyled
// -----------------------------------------------------------------------------

.forms-input-textarea {
  .mat-input-element {
    resize: none;
  }
}

// Input Suffix
// -----------------------------------------------------------------------------

.forms-input-suffix {
  color: palette('actions', 'warning');
}

// Input Autocomplete Disabled
// -----------------------------------------------------------------------------

.forms-autocomplete-disabled {
  .forms-autocomplete-label {
    @extend %font-bold;
    color: palette('zbrown', 'dark');
    @include flex-and-direction(row, center, space-between);
  }
}

// Code Verify Input
// -----------------------------------------------------------------------------

.forms-input-digit {
  .mat-input-element {
    font-size: 2rem;
    @extend %font-xbold;
    text-align: center;
    height: 30px;
    line-height: 30px;
  }

  .mat-form-field-underline {
    height: 2px;
    border-top-width: 2px;
    border-color: palette('zgray', 'base');
  }

  .mat-form-field-ripple {
    opacity: 0 !important;
  }
}

// Select
// -----------------------------------------------------------------------------

.forms-select-wrapper {
  position: relative;

  // .mat-hint {
  //   text-transform: lowercase;
  // }
}

// Select Default Theme
// -----------------------------------------------------------------------------

.forms-select {
  @extend .forms-input;
  .mat-input-wrapper {
    width: 100%;
  }
  .mat-select-arrow {
    width: 0;
    height: 0;
    border: 0;
    margin: 0 14px;
    margin-bottom: 15px;
    @include itauicons('\e9c2');
  }

  .mat-select-value-text,
  .mat-select-arrow {
    color: palette('zbrown', 'dark');
    @extend %font-bold;
  }

  .mat-select-disabled {
    .mat-select-arrow {
      display: none;
    }
  }
}

.forms-select-line {
  &.mat-select {
    position: relative;
    top: 4px;
  }
}

// Select no-underline
// -----------------------------------------------------------------------------

.forms-select-no-underline {
  margin-bottom: -10px;

  .mat-form-field-underline {
    display: none;
  }
}

// Select no-placeholder
// -----------------------------------------------------------------------------

.forms-select-no-placeholder {
  margin-bottom: -10px;

  .mat-form-field-underline {
    display: none;
  }

  .mat-form-field-label {
    top: 0;
  }

  .mat-select-arrow {
    margin-bottom: 15px;
  }
}

// Select large
// -----------------------------------------------------------------------------

.forms-select-large {
  &.mat-form-field {
    width: auto;
  }

  .mat-select-trigger {
    min-width: 330px;
    height: 40px;
  }

  .mat-select-value-text,
  .mat-select-arrow {
    @extend %font-bold;
    font-size: 1.5rem;
  }

  .mat-select-arrow {
    margin-top: -5px;
  }

  .mat-select-underline {
    display: none;
  }
}

// Select X-Light Theme
// -----------------------------------------------------------------------------

.forms-select-xlight {
  @extend .forms-input-xlight;

  .mat-select-value-text,
  .mat-select-arrow {
    color: palette('zgray', 'x-light');
    @extend %font-bold;
  }
}

// Input Action
// -----------------------------------------------------------------------------

.forms-input-action {
  position: absolute;
  transform: translateY(-16px);
}

// Checkbox
// -----------------------------------------------------------------------------

.forms-checkbox {
  .mat-checkbox-layout {
    white-space: normal;
  }

  .mat-checkbox-label {
    font-size: 0.875rem;
    padding: 0 0 0 5px;
    color: palette('zbrown', 'dark');
  }

  .mat-checkbox-ripple {
    opacity: 0;
    transition: none;
  }

  &:not(.mat-checkbox-disabled) .mat-checkbox-frame {
    border-color: palette('zbrown', 'dark');
  }

  &.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: palette('zbrown', 'dark');
  }

  .mat-checkbox-checkmark-path {
    stroke: palette('zgray', 'x-light') !important;
  }
}

.forms-checkbox-icon {
  position: relative;
  top: -1px;
  margin-left: 8px;
  font-size: 1.5rem;
  vertical-align: middle;
}

.forms-checkbox-icon {
  position: relative;
  top: -1px;
  margin-left: 8px;
  font-size: 1.5rem;
  vertical-align: middle;
}

.forms-checkbox-xlight {
  .mat-checkbox-label {
    color: palette('zgray', 'x-light');
  }

  &.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: palette('zgray', 'x-light');
  }

  &:not(.mat-checkbox-disabled) .mat-checkbox-frame {
    border-color: palette('zgray', 'x-light');
  }

  .mat-checkbox-checkmark-path {
    stroke: palette('zbrown', 'dark') !important;
  }
}

.forms-checkbox-info {
  .mat-checkbox-label {
    color: palette('zbrown', 'dark');
  }

  &.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: palette('state', 'info');
  }

  .mat-checkbox-frame {
    border-color: palette('state', 'info');
  }

  .mat-checkbox-checkmark-path {
    stroke: palette('zgray', 'x-light') !important;
  }
}

.forms-checkbox-brand {
  .mat-checkbox-label {
    color: palette('zbrown', 'dark');
  }

  &.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: palette('brand', 'mid-dark');
  }

  .mat-checkbox-frame {
    border-color: palette('brand', 'mid-dark');
  }

  .mat-checkbox-checkmark-path {
    stroke: palette('zgray', 'x-light') !important;
  }
}

// Radio
// -----------------------------------------------------------------------------

.forms-radio {
  &.forms-radio-inline {
    flex-direction: row;

    .mat-radio-button:not(:first-of-type) {
      margin-left: 1rem;
    }
  }

  &.forms-radio-responsive {
    @media (max-width: 767px) {
      display: flex;
      flex-direction: column !important;

      .mat-radio-button:not(:first-child) {
        margin-left: 0px !important;
        margin-top: 15px !important;
      }
    }
  }

  &.forms-radio-dark {
    .mat-radio-outer-circle {
      border-color: palette('zbrown', 'dark');
    }

    .mat-radio-button.mat-accent .mat-radio-inner-circle {
      background-color: palette('zbrown', 'dark');
    }

    .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
      border-color: palette('zbrown', 'dark');
    }
  }

  &.forms-radio-xlight {
    .mat-radio-outer-circle {
      border-color: palette('zgray', 'x-light');
    }

    .mat-radio-button.mat-accent .mat-radio-inner-circle {
      background-color: palette('zgray', 'x-light');
    }

    .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
      border-color: palette('zgray', 'x-light');
    }

    & .mat-radio-button.mat-accent .mat-radio-label-content {
      color: palette('zgray', 'x-light');
    }
  }
}

// Slider
// -----------------------------------------------------------------------------

.forms-slider-container {
  position: relative;
}

.forms-slider-value-indicator {
  display: block;
  position: absolute;
  top: -15px;
  width: 100%;
  height: 40px;
  margin-left: 0px;
  padding-left: 10px;
  border-left: 1px dashed palette('zgray', 'x-light');
  box-sizing: border-box;
}

.forms-slider-value-indicator-label {
  font-size: 0.9rem;
  @extend %font-bold;
  position: relative;
  top: -14px;

  small {
    position: relative;
    top: 4px;
  }
}

.forms-slider-value-indicator-label-left {
  font-size: 0.9rem;
  @extend %font-bold;
  text-align: right;
  position: relative;
  top: -32px;
  left: -12px;

  small {
    position: relative;
    top: 4px;
  }
}

.forms-slider {
  &.mat-slider {
    padding: 0;
    width: 100%;
  }

  &.mat-slider-horizontal .mat-slider-wrapper {
    left: 0;
    right: 0;
  }
}

.forms-slider-xlight {
  .mat-slider-track-background,
  &:hover .mat-slider-track-background {
    background-color: palette('zgray', 'x-light');
  }

  .mat-slider-track-fill {
    background-color: palette('brand', 'mid-dark');
  }

  &.mat-slider-min-value:hover .mat-slider-thumb {
    border-color: palette('zgray', 'x-light');
  }

  .mat-slider-thumb,
  &.mat-slider-active .mat-slider-thumb,
  &.mat-slider-active:hover .mat-slider-thumb {
    background-color: palette('brand', 'mid-dark');
    border-color: palette('brand', 'mid-dark');
  }

  &.mat-slider-min-value .mat-slider-thumb {
    border-color: palette('zgray', 'x-light');
    background-color: palette('zgray', 'x-light');
  }

  &.mat-slider-min-value.mat-slider-active .mat-slider-thumb {
    border-color: palette('zgray', 'x-light');
  }

  &.mat-slider-min-value.mat-slider-active:hover .mat-slider-thumb {
    border-color: palette('brand', 'mid-dark');
  }

  .mat-slider-thumb {
    width: 24px;
    height: 24px;
    right: -12px;
    bottom: -12px;
    border-radius: 12px;
    transform: scale(1);
  }

  .mat-slider-track-wrapper,
  .mat-slider-track-background,
  .mat-slider-track-fill {
    height: 3px !important;
  }
}

// Fields Separator
// -----------------------------------------------------------------------------

.forms-field-separator {
  width: 100%;
  display: block;
  text-align: center;
}

// Input Text Validation Styles
// -----------------------------------------------------------------------------

.forms-input {
  .mat-hint-danger,
  .mat-error {
    text-transform: lowercase;
  }

  &.ng-touched.ng-invalid {
    .mat-form-field-label {
      color: palette('state', 'danger-xlight') !important;
    }

    .mat-input-element {
      color: palette('state', 'danger-xlight') !important;
      @extend %font-bold;
    }

    .mat-form-field-underline {
      background-color: palette('state', 'danger-xlight') !important;

      .mat-form-field-ripple {
        background-color: darken(palette('state', 'danger-xlight'), 5%) !important;
      }
    }

    &.mat-focused {
      .mat-form-field-ripple {
        background-color: palette('state', 'danger-xlight') !important;
      }
    }
  }

  &.mat-form-field-no-underline {
    .mat-form-field-underline {
      display: none;
    }
  }
}

.forms-input-xlight {
  &.ng-touched.ng-invalid {
    .mat-hint-danger,
    .mat-error,
    .mat-form-field-label {
      color: palette('state', 'danger-xlight') !important;
    }

    .mat-input-element {
      color: palette('state', 'danger-xlight') !important;
      @extend %font-bold;
    }

    .mat-form-field-underline {
      background-color: palette('state', 'danger-xlight');

      .mat-form-field-ripple {
        background-color: darken(palette('state', 'danger-xlight'), 5%);
      }
    }

    &.mat-focused {
      .mat-form-field-ripple {
        background: palette('state', 'danger-xlight') !important;
      }
    }
  }
  .forms-autocomplete-disabled {
    .forms-autocomplete-label {
      @extend %font-bold;
      color: palette('zgray', 'x-light');
    }
  }
}

// Select Validation
// -----------------------------------------------------------------------------

.forms-select {
  .mat-hint-danger,
  .mat-error {
    text-transform: lowercase;
  }

  &.ng-touched.ng-invalid {
    .mat-select-value-text,
    .mat-select-arrow {
      color: palette('state', 'danger-xlight') !important;
      @extend %font-bold;
    }

    .mat-form-field-label {
      color: palette('state', 'danger-xlight') !important;
    }

    .mat-select-underline {
      background-color: palette('state', 'danger-xlight') !important;
    }

    &:focus:not(.mat-select-disabled).mat-primary {
      .mat-select-arrow {
        color: palette('state', 'danger-xlight') !important;
      }

      .mat-form-field-label {
        color: palette('state', 'danger-xlight') !important;
      }

      .mat-select-underline {
        background-color: palette('state', 'danger-xlight') !important;
      }
    }
  }
}

.forms-select-xlight {
  &.ng-touched.ng-invalid {
    .mat-select-value-text,
    .mat-select-arrow {
      color: palette('state', 'danger-xlight') !important;
      @extend %font-bold;
    }

    .mat-hint-danger,
    .mat-error,
    .mat-form-field-label {
      color: palette('state', 'danger-xlight') !important;
    }

    .mat-form-field-underline {
      background-color: palette('state', 'danger-xlight');
    }

    &:focus:not(.mat-select-disabled).mat-primary {
      .mat-select-arrow {
        color: palette('state', 'danger-xlight') !important;
      }

      .mat-form-field-label {
        color: palette('state', 'danger-xlight') !important;
      }

      .mat-form-field-underline {
        background-color: palette('state', 'danger-xlight') !important;
      }
    }
  }
}

// Select Panel
// -----------------------------------------------------------------------------

.mat-select-panel:not([class*='mat-elevation-z']) {
  min-width: calc(100% + 0px) !important;
  margin-left: 16px;
}

// Hint
// -----------------------------------------------------------------------------

.mat-hint-danger,
.mat-error {
  color: palette('state', 'danger-xlight') !important;
  caret-color: darken(palette('state', 'danger-xlight'), 5%) !important;
}

// Selected Row
// -----------------------------------------------------------------------------

.forms-row {
  border-radius: 4px;
  padding: 1.5rem 1.5rem 0.5rem 1.5rem;
  border: 1px solid lighten(palette('zgray', 'mid-light'), 8%);
}

.forms-active-row {
  border-radius: 4px;
  padding: 1.5rem 1.5rem 0.5rem 1.5rem;
  border: 1px solid transparent;
  background-color: palette('zgray', 'light');
}

.list-disabled {
  background-color: #eff1f4;
}

// HTML5 API custom styles
// -----------------------------------------------------------------------------

input[type='number'] {
  -moz-appearance: textfield;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.mat-option {
  height: auto !important;
  padding: 8px 16px !important;
  line-height: normal !important;
  white-space: normal !important;
  text-overflow: unset !important;
  overflow: hidden !important;
  color: palette('zbrown', 'dark');
}

// -----------------------------------------------------------------------------

.input-message {
  margin-top: -15px;
  margin-bottom: 15px;
  z-index: 1;
}

.input-message-small {
  margin-top: -20px;
  font-size: 12px;
}
