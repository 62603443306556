// Accordion Module
// -----------------------------------------------------------------------------

.accordion {
  float: right;
  width: 270px;
  margin: 3.5rem 0 5rem 0;

  * {
    transition: all 0.2s ease;
  }

  .panel {
    border-bottom: 1px solid palette('zgray', 'light');
  }

  .panel-title {
    @extend %title-xdark;
    margin: 1.5rem 0;
    cursor: pointer;

    .caret {
      @include itauicons('\e9c2');
      @extend %font-regular;
      float: right;
      margin-top: 3px;
      margin-right: 1.5rem;
      margin-left: 1.5rem;
    }
  }

  // Opened State
  .panel.dropup {
    .panel-title {
      color: palette('brand', 'mid-dark');
      margin-bottom: 2.25rem;

      .caret {
        @include itauicons('\e9c6');
      }
    }
  }
}

.accordion-fixed {
  position: fixed;
  width: 270px;
  padding-left: 28px;
}

.accordion-link {
  @extend %body-dark;
  display: block;
  text-decoration: none;
  padding-left: 0.5rem;
  margin-left: -0.7rem;
  margin-bottom: 2rem;
  border-left: 3px solid transparent;
  outline: 0;
  cursor: pointer;

  &.is-active,
  &:hover {
    border-left: 3px solid palette('brand', 'mid-dark');
  }
}

.accordion-img {
  margin: 0 auto;
  display: block;
}
// -----------------------------------------------------------------------------
