// Text Helpers
// -----------------------------------------------------------------------------

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-ucase {
  text-transform: uppercase !important;
}

.text-end {
  text-align: end !important;
}

// Float Helpers
// -----------------------------------------------------------------------------

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

// Spacing
// -----------------------------------------------------------------------------

$spacing-sizes: 0.25, 0.5, 0.75, 1;

@mixin set-spacing($name, $value) {
  .helper-m#{$name} {
    margin: $value;
  }

  .helper-p#{$name} {
    padding: $value;
  }

  .helper-mt#{$name} {
    margin-top: $value;
  }

  .helper-pt#{$name} {
    padding-top: $value;
  }

  .helper-mr#{$name} {
    margin-right: $value;
  }

  .helper-pr#{$name} {
    padding-right: $value;
  }

  .helper-mb#{$name} {
    margin-bottom: $value;
  }

  .helper-pb#{$name} {
    padding-bottom: $value;
  }

  .helper-ml#{$name} {
    margin-left: $value;
  }

  .helper-pl#{$name} {
    padding-left: $value;
  }
}

@include set-spacing(0, 0);

@each $size in $spacing-sizes {
  @for $i from 0 through 4 {
    $name: str-replace(#{$size + $i}, '.');
    $value: #{$size + $i}rem;
    @include set-spacing($name, $value);
  }
}

// Overflow
// -----------------------------------------------------------------------------

.no-scroll {
  overflow: hidden;
}

// Column separator
// -----------------------------------------------------------------------------

.helper-col-separator {
  border-right: 2px solid palette('zgray', 'mid-light');
}

// Image
// -----------------------------------------------------------------------------

.helper-img-full {
  max-width: 100%;
}

.helper-img-small {
  height: 40px;
}

.helper-rounded-img {
  border-radius: 4px;
}

// White Space
// -----------------------------------------------------------------------------

.helper-nowrap {
  white-space: nowrap;
}

.helper-preline {
  white-space: pre-line;
}

// Disable
// -----------------------------------------------------------------------------

.helper-disable {
  pointer-events: none !important;
  cursor: not-allowed !important;
  opacity: 0.7 !important;
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;

  * {
    pointer-events: none !important;
    cursor: not-allowed !important;
    -webkit-touch-callout: none !important;
    -webkit-user-select: none !important;
    -khtml-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
  }
}

// Border Helper
// -----------------------------------------------------------------------------

.helper-bb1 {
  border-bottom: 1px solid palette('zgray', 'light');
}

.dropdown-installments-option.helper-bsupport1 {
  border: 2px solid palette('state', 'base');
}

// Cursor
// -----------------------------------------------------------------------------

.cursor-pointer {
  cursor: pointer;
}

// Text
// -----------------------------------------------------------------------------

.text-lowercase {
  text-transform: lowercase;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-line-through {
  text-decoration: line-through;
}

.text-underline {
  // padding-bottom: 0.125rem;
  border-bottom: 1px solid;
  border-color: inherit;
}

// List
// -----------------------------------------------------------------------------

.list-style-none {
  list-style: none;
}

// Fles Grow
// -----------------------------------------------------------------------------

$grows: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10);

@each $grow in $grows {
  .flex-#{$grow} {
    flex: $grow;
  }
}

// Vertical Alignment
// -----------------------------------------------------------------------------

.vertical-middle {
  vertical-align: middle;
}
@mixin flex-and-direction($direction: row, $align: center, $justify-content: center) {
  display: flex;
  flex-direction: $direction;
  align-items: $align;
  justify-content: $justify-content;
}
