@import 'responsive';
@import 'variableFontSize';
// Buttons Module
// -----------------------------------------------------------------------------

%resetBorder {
  border: 0;
}

%resetButton {
  font-weight: 600;
  border-radius: 4px;
  outline: 0;
  cursor: pointer;
  transition: all 0.3s ease;
}

// Button (regular button - may have icon)
// -----------------------------------------------------------------------------

.btn,
%btn {
  // fix spacement for 'included icon'
  @extend %resetBorder;
  @extend %resetButton;
  padding: 1rem;
  box-sizing: border-box;
  background-color: palette('actions', 'warning');
  box-shadow: 0 3px 5px rgba(palette('zgray', 'x-dark'), 0.2);
  font-size: 1rem;
  color: palette('zgray', 'x-light');
  transition: all 0.3s ease;

  &:hover {
    background-color: darken(palette('actions', 'warning'), 5%);
  }

  &:disabled {
    cursor: default;
    background-color: palette('zgray');
    box-shadow: 0 0 10px palette('dark-ghost', 10);
  }
}

.btn {
  min-width: 320px;
  &.btn--auto {
    min-width: 100%;
    max-width: 320px;
  }
  @include for-phone-tablet {
    min-width: 100%;
    width: 100%;
  }
}

// Button VARIATIONS
// -----------------------------------------------------------------------------

.btn {
  &--small {
    @extend %btn;
    max-width: initial;
    padding: 0.5rem 1.2rem;
    font-size: 0.875rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    & :first-child {
      &.zf-icon {
        display: inline-flex;
        margin-right: 0.5rem;
      }
    }

    & :last-child {
      &.zf-icon {
        display: inline-flex;
        margin-left: 0.5rem;
      }
    }
  }

  &--full {
    width: 100%;
  }

  &--ids {
    width: 320px;
    height: 48px;
    padding: 8px 16px 8px 16px;
    font-weight: 700;
    font-size: 18px;
    line-height: 32px;
    border-radius: 4px;
    &__full {
      width: 100%;
    }
  }
}

.btnOutline {
  @extend %resetButton;
  border: 1px solid;
  border-color: palette('actions', 'warning');
  padding: 0.8rem 1rem;
  box-sizing: border-box;
  font-size: 1rem;
  color: palette('actions', 'warning');
  transition: all 0.3s ease;

  min-width: 320px;
  &.btn--auto {
    min-width: 100%;
    max-width: 320px;
  }
  @include for-phone-tablet {
    min-width: 100%;
    width: 100%;
  }

  &:hover {
    border-color: darken(palette('actions', 'warning'), 5%);
  }

  &:disabled {
    cursor: default;
    border-color: palette('zgray');
  }

  &--info {
    color: palette('state', 'base');
    border-color: palette('state', 'base');

    &:hover {
      color: darken(palette('state', 'base'), 5%);
      border-color: darken(palette('state', 'base'), 5%);
    }
  }
}

// Button Link (text - may have icon)
// -----------------------------------------------------------------------------

.btnLink,
%btnLink {
  @extend %resetBorder;
  @extend %resetButton;
  display: flex;
  // padding: 0 0.5rem; // remove after create a 'text/label' class
  align-items: center;
  font-size: 0.875rem;
  line-height: 1.4rem;
  background-color: transparent;
  box-shadow: 0 0 0 transparent;
  color: palette('actions', 'warning');
  text-decoration: none;

  &:not(:disabled) {
    .loading {
      display: none;
    }
  }

  &:disabled {
    @extend .helper-disable;
  }

  &:hover {
    color: darken(palette('actions', 'warning'), 5%);
  }

  & :first-child {
    &.zf-icon {
      display: flex;
      margin-right: 0.5rem;
      font-size: 1.1rem;
    }
  }

  & :nth-child(2n) {
    &.zf-icon {
      display: flex;
      margin-left: 0.5rem;
      font-size: 1.1rem;
    }
  }
}

.btnLink {
  @extend %font-bold;
}

// Button Link VARIATIONS
// -----------------------------------------------------------------------------

.btnLink {
  &--info {
    color: palette('state', 'base');

    &:hover {
      color: darken(palette('state', 'base'), 5%);
    }
  }

  &--outlined {
    @extend %btnLink;
    @extend %font-bold;
    border: palette('state', 'base') solid 1px;
    width: 100%;
    color: palette('state', 'base');
    &:hover {
      color: palette('state', 'base');
    }
  }

  &--outlined-warning {
    border: palette('state', 'warning') solid 1px;
    color: palette('state', 'warning');
  }

  &--dark {
    // used on 'switch button' // refactor for not use it [27]
    @extend %btnLink;
    @extend %font-bold;
    color: palette('zbrown', 'dark');

    &:hover {
      color: palette('zbrown', 'dark');
    }
  }

  &--midDark {
    // used on app footer // remove after create a '.footer' component [1]
    @extend %btnLink;
    @extend %font-bold;
    color: palette('zbrown', 'mid-dark');

    &:hover {
      color: darken(palette('zbrown', 'mid-dark'), 6%);
    }
  }

  &--light {
    @extend %btnLink;
    @extend %font-regular;
    color: palette('zgray', 'x-light');

    &:hover {
      color: palette('actions', 'warning');
    }

    & :first-child {
      &.zf-icon {
        display: flex;
        margin-right: 0.25rem;
        font-size: 0.875rem;
      }
    }

    & :nth-child(2n) {
      &.zf-icon {
        display: flex;
        margin-left: 0.25rem;
        font-size: 0.875rem;
      }
    }
  }

  &--inline {
    // fix: keep only 'display'

    display: inline-flex;
  }

  &--underline {
    position: relative;
    border-radius: 0;

    &::before {
      content: '';
      position: absolute;
      bottom: 15px;
      border-bottom: solid 1px;
      width: 100%;
    }
  }
}

// Button Icon (only icon)
// -----------------------------------------------------------------------------

.btnIcon,
%btnIcon {
  @extend %resetBorder;
  @extend %resetButton;
  height: 24px; // check for adjust
  display: flex;
  background-color: transparent;
  font-size: 1.5rem;
  line-height: 24px; // check for adjust
  align-items: center;
  color: palette('actions', 'warning');
  transition: all 0.2s ease;

  &:hover {
    color: darken(palette('actions', 'warning'), 5%);
    transform: scale(1.1);

    & .zf-icon {
      color: darken(palette('actions', 'warning'), 5%);
    }
  }

  & .zf-icon {
    color: palette('actions', 'warning');
  }
}

// Button Icon VARIATIONS
// -----------------------------------------------------------------------------

.btnIcon {
  &--dark {
    @extend %btnIcon;
    color: palette('zbrown', 'dark');

    &:hover {
      color: darken(palette('zbrown', 'dark'), 5%);

      & .zf-icon {
        color: darken(palette('zbrown', 'dark'), 5%);
      }
    }

    & .zf-icon {
      color: palette('zbrown', 'dark');
    }
  }

  &--light {
    @extend %btnIcon;
    color: palette('zgray', 'x-light');

    &:hover {
      color: palette('actions', 'warning');

      & .zf-icon {
        color: palette('actions', 'warning');
      }
    }

    & .zf-icon {
      color: palette('zgray', 'x-light');
    }
  }

  &--danger {
    @extend %btnIcon;
    color: palette('state', 'danger');

    &:hover {
      color: darken(palette('state', 'danger'), 5%);

      & .zf-icon {
        color: darken(palette('state', 'danger'), 5%);
      }
    }

    & .zf-icon {
      color: palette('state', 'danger');
    }
  }

  &--success {
    @extend %btnIcon;
    color: palette('state', 'success');

    &:hover {
      color: darken(palette('state', 'success'), 5%);

      & .zf-icon {
        color: darken(palette('state', 'success'), 5%);
      }
    }

    & .zf-icon {
      color: palette('state', 'success');
    }
  }

  &--disabled {
    // refactor (maybe with [disabled] selector
    @extend %btnIcon;
    color: palette('zbrown'); // update color

    &:hover {
      transform: initial;
      color: palette('zbrown'); // update color

      & .zf-icon {
        color: palette('zbrown'); // update color
      }
    }

    & .zf-icon {
      color: palette('state', 'zbrown');
    }
  }

  &--small {
    // refactor for remove
    @extend %btnIcon;
    height: auto; // check for adjust
    font-size: 1rem;
    line-height: 1.2; // check for adjust

    &:hover {
      & .zf-icon {
        color: darken(palette('state', 'danger'), 5%); // check for adjust
      }
    }

    &--info {
      @extend %btnIcon;
      color: palette('state', 'info');

      &:hover {
        color: darken(palette('state', 'info'), 5%);

        & .zf-icon {
          color: darken(palette('state', 'info'), 5%);
        }
      }
    }

    & .zf-icon {
      color: palette('state', 'danger'); //check for adjust
    }
  }
}

// Button Card
// -----------------------------------------------------------------------------

.btnCard {
  // refactor when 'check' mat-card
  display: flex;
  flex-direction: column;
  padding: 2rem;
  text-align: left;
  background-color: palette('zgray', 'x-light');

  &:hover {
    background-color: darken(palette('zgray', 'x-light'), 2%);
  }

  > p {
    margin-bottom: 0;
  }
}

// -----------------------------------------------------------------------------
// Button Debit Account

.btnDebitAcc {
  @extend %font-regular;
  color: #106eb0;
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  max-width: fit-content;
  font-weight: 600;
  line-height: 1.4rem;
  background-color: transparent;
  box-shadow: 0 0 0 transparent;
  & :first-child {
    &.zf-icon {
      font-weight: 500;
      display: flex;
      margin-right: 0.5rem;
      font-size: 1.5rem;
    }
  }

  & :nth-child(2n) {
    &.zf-icon {
      font-weight: 500;
      display: flex;
      margin-left: 0.5rem;
      font-size: 1.5rem;
    }
  }
  &:hover {
    color: darken(palette('state', 'base'), 5%);

    & .zf-icon {
      color: darken(palette('state', 'base'), 5%);
    }
  }
}
