// Ngx File Drop
// -----------------------------------------------------------------------------

@import "responsive";

.file-drop-container {
  min-width: 100%;

  .ngx-file-drop__drop-zone {
    height: auto !important;
    min-height: 90px;
    position: relative;
    border: 2px dashed palette("zbrown", "mid-light") !important;
    border-radius: 4px !important;

    &.ngx-file-drop__drop-zone--over {
      background-color: transparent;
      border-width: 4px;
      opacity: 0.5;
    }

    .ngx-file-drop__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: auto !important;
      color: inherit !important;
      box-sizing: border-box;
      padding: 1.5rem 2rem;

      @include for-phone-tablet {
        padding: 16px;
      }

      .ngx-file-drop__drop-zone-label {
        display: block;
        color: palette("zgray", "mid-dark");
        @extend %font-bold;
        margin-top: 2rem;
      }

      input[type="button"],
      button[type="button"] {
        display: block;
        margin: auto;
        padding: 0;
        background: transparent;
        box-shadow: none;
        @extend %font-bold;
        color: palette("brand", "mid-dark");

        &:hover {
          color: darken(palette("brand", "mid-dark"), 10%);
        }
      }

      .doc-upload {
        display: flex;
        flex-direction: row;
        margin-top: 2rem;

        .sample {
          display: flex;
          flex-direction: row;

          figure {
            background-size: contain !important;
            border-bottom: 4px solid;
            width: 73px;
            height: 103px;

            @media (max-width: 599px) {
              width: 50px;
              height: 80px;
            }

            margin-bottom: 0.25rem;
            padding-bottom: 0.25rem;

            &.docs {
              &-danger {
                background: url("/assets/img/upload/docs-danger.png") no-repeat;
              }

              &-success {
                background: url("/assets/img/upload/docs-success.png") no-repeat;
              }
            }

            &.files {
              &-danger {
                background: url("/assets/img/upload/files-danger.png") no-repeat;
              }

              &-success {
                background: url("/assets/img/upload/files-success.png")
                  no-repeat;
              }
            }

            + strong {
              display: block;
              font-size: 0.75rem;
              line-height: 1.5rem;
            }
          }

          .item:not(:first-child) {
            margin-left: 0.5rem;
          }

          &.files {
            margin-left: 2rem;
          }
        }
      }
    }
  }
}
