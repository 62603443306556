// Transaction History
// -----------------------------------------------------------------------------

.history-tasks {
  ol,
  ul {
    margin: 0;
    padding: 0;
  }

  padding-left: 24px;
  padding-right: 40px;

  * {
    color: palette('zbrown', 'dark');
    font-size: 1rem;
  }

  span {
    color: #7a746f;
  }

  h3 {
    margin: 0;
    padding: 0;
  }

  .divider {
    display: block;
    border-bottom: 1px solid palette('zbrown', 'mid-dark');
    padding: 0 2.5rem;
    margin: 0px -2.5rem;
  }

  ul.padding-first-element-table {
    > li {
      padding-top: 8px;
    }
  }

  ul.padding-table {
    > li {
      padding-top: 24px;
    }
  }

  ul.process {
    > li {
      position: relative;

      button {
        position: absolute;
        right: 0;

        span {
          font-size: 2rem;
          @include itauicons('\e9c6');

          &.hidden {
            @include itauicons('\e9c2');
          }
        }
      }

      div {
        &:first-child {
          min-width: 6rem;
        }

        &.process__icon {
          min-width: 2rem;
          position: relative;
          padding-bottom: 5rem;

          &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            left: calc(50% - 1px);
            bottom: 0;
            width: 1px;
            background-color: palette('zbrown', 'mid-dark');
            margin-top: 40px;
          }

          span {
            color: palette('zbrown', 'mid-dark');
            font-size: 2rem;
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            transform: translateY(-0.5rem);
          }
        }
      }
    }
  }

  ul.tasks {
    &.hidden {
      display: none;
    }

    > li {
      div {
        &:first-child {
          min-width: 6rem;
        }

        &.tasks__icon {
          color: palette('zbrown', 'dark');
          min-width: 2rem;
          position: relative;
          padding-bottom: 5rem;

          &::before,
          &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            left: calc(50% - 1px);
            bottom: 0;
            width: 1px;
            background-color: palette('zbrown', 'mid-dark');
          }

          span {
            background-color: palette('zgray', 'x-light');
            position: absolute;
            width: 1.5rem;
            height: 1.5rem;
            top: 0;
            right: 0;
            bottom: 0;
            left: 3px;
            border: 1px solid palette('zbrown', 'mid-dark');
            border-radius: 100%;
            box-sizing: border-box;
            z-index: 1;
          }
        }
      }

      &:last-of-type div:nth-child(2) {
        &::before {
          height: 0.5rem;
        }

        &::after {
          content: none;
        }
      }
    }
  }
}

// -----------------------------------------------------------------------------
