@import "responsive";

$font12: 0.75em; //base 16
$font12R: 0.85em; // base 14
$font14: 0.875em; //base 16
$font14R: 1em; // base 14
$font16: 1.14rem;
$font18: 1.28rem;
$font20: 1.42rem;
$font30: 2.14rem;


@include for-phone-tablet {
    .fontSize18R{
        font-size: $font18;
    }
    .fontSize20R{
        font-size: $font20;
    }
}