// Land Rover Overrides
// -----------------------------------------------------------------------------

// Document
// -----------------------------------------------------------------------------

html,
body {
  ::selection {
    color: palette('zgray', 'dark');
    background: palette('zgray'); /* WebKit/Blink Browsers */
  }

  ::-moz-selection {
    color: palette('zgray', 'dark');
    background: palette('zgray'); /* Gecko Browsers */
  }
}

// Toolbar
// -----------------------------------------------------------------------------

.toolbar {
  border-bottom-color: palette('zgray', 'light');
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

// Toolbar Brand
// -----------------------------------------------------------------------------

.toolbar-brand {
  margin-right: 1rem;
}

.toolbar-brand-logo {
  width: 85px;
  height: 45px;
  background-image: url('/assets/img/land-rover.png');
}

.toolbar-brand-title {
  color: palette('zgray', 'x-dark');
}

// Toolbar Navigation
// -----------------------------------------------------------------------------

.toolbar-navigation {
  .toolbar-btn {
    color: palette('zgray', 'x-dark');
    font-size: 1rem;
    text-transform: uppercase;

    &::before {
      box-shadow: 0 4px 0 palette('dark-ghost', 50);
    }

    &.is-active:before {
      box-shadow: 0 4px 0 palette('zgray', 'x-dark');
    }
  }
}

// Toolbar Actions
// -----------------------------------------------------------------------------

.toolbar-actions {
  .toolbar-btn-actions {
    color: palette('zgray', 'x-dark') !important;

    & .zf-icon {
      color: palette('zgray', 'x-dark') !important;

      @media only screen and (max-width: 1024px) {
        color: palette('zgray', 'x-dark') !important;
      }
    }

    &:hover {
      color: palette('zgray', 'x-dark') !important;

      & .zf-icon {
        color: palette('zgray', 'x-dark') !important;

        @media only screen and (max-width: 1024px) {
          color: palette('zgray', 'x-dark') !important;
        }
      }
    }
  }
}

.toolbar-actions-menu-button {
  &:hover {
    color: palette('zgray', 'x-dark') !important;
  }
}

// Forms - Slider
// -----------------------------------------------------------------------------

.forms-slider-xlight {
  .mat-slider-track-background,
  &:hover .mat-slider-track-background {
    background-color: palette('zgray', 'x-light');
  }

  .mat-slider-track-fill {
    background-color: palette('state', 'danger');
  }

  &.mat-slider-min-value:hover .mat-slider-thumb {
    border-color: palette('zgray', 'x-light');
  }

  .mat-slider-thumb,
  &.mat-slider-active .mat-slider-thumb,
  &.mat-slider-active:hover .mat-slider-thumb {
    background-color: palette('state', 'danger');
    border-color: palette('state', 'danger');
  }

  &.mat-slider-min-value .mat-slider-thumb {
    border-color: palette('zgray', 'x-light');
    background-color: palette('zgray', 'x-light');
  }

  &.mat-slider-min-value.mat-slider-active .mat-slider-thumb {
    border-color: palette('zgray', 'x-light');
  }

  &.mat-slider-min-value.mat-slider-active:hover .mat-slider-thumb {
    border-color: palette('state', 'danger');
  }
}

// Forms - Select
// -----------------------------------------------------------------------------

.forms-select {
  .mat-select-placeholder {
    top: -20px;
  }
}

// Forms Checkbox Brand
// -----------------------------------------------------------------------------

.forms-checkbox-brand {
  .mat-checkbox-label {
    color: palette('zbrown', 'dark');
  }

  &.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: palette('zgray', 'dark');
  }

  .mat-checkbox-frame {
    border-color: palette('zgray', 'dark');
  }

  .mat-checkbox-checkmark-path {
    stroke: palette('zgray', 'x-light') !important;
  }
}

// Forms Input
// -----------------------------------------------------------------------------

.forms-input {
  .mat-input-underline {
    background-color: palette('zgray', 'mid-dark') !important;

    .mat-input-ripple {
      background-color: darken(palette('zgray', 'mid-dark'), 5%) !important;
    }
  }

  &.ng-touched.ng-invalid {
    .mat-input-underline {
      background-color: palette('state', 'danger') !important;

      .mat-input-ripple {
        background-color: darken(palette('state', 'danger'), 5%) !important;
      }
    }
  }
}

// Tooltip
// -----------------------------------------------------------------------------

.mat-tooltip {
  background-color: palette('zgray', 'x-dark');
}

// Buttons
// -----------------------------------------------------------------------------

.btnn {
  border-radius: 0;
  box-shadow: 0 0 0 transparent !important;
}

.btn-link--text {
  color: palette('state', 'base') !important;
}

.post-footer-link,
.btnLink {
  @include itauicons('\e9c4');
}

.btnLink-,
.btn-link--text,
li .btnLink {
  &::before {
    display: none;
  }
}

// Alert
// -----------------------------------------------------------------------------

.alert-dark-bg {
  background-color: palette('zgray', 'light');

  span,
  .zf-icon,
  .alert-text {
    color: palette('zgray', 'x-dark') !important;
  }
}

// Panel
// -----------------------------------------------------------------------------

.panel-heading {
  * {
    color: palette('zgray', 'x-dark');
  }

  *:hover {
    color: palette('zgray', 'x-dark');
  }
}

//  Icon
// -----------------------------------------------------------------------------

.icon-brand-big,
.icon-active-brand {
  color: palette('zgray', 'x-dark');
}

// Accordion
// -----------------------------------------------------------------------------

.accordion-link {
  &.is-active,
  &:hover {
    border-left: 3px solid palette('zgray', 'x-dark');
  }
}

// Spinner
// -----------------------------------------------------------------------------

.spinner-brand {
  svg path {
    stroke: palette('zgray', 'x-dark') !important;
  }
}

// Autocomplete
// -----------------------------------------------------------------------------

.autocomplete {
  border-bottom: 1px solid;
  border-radius: 0;
}

// -----------------------------------------------------------------------------

.slider-container {
  border-bottom: 1px solid palette('zgray', 'light');
  clear: both;

  &__legend {
    padding: 18px 0 0;

    .text-base {
      margin-top: 13px;
    }
  }
}

.dropdown,
.dropdown-menu {
  strong.text-base {
    font-size: 1.1rem !important;
    font-weight: 400 !important;
  }
}

.slider {
  transform: translateY(-32px);

  &.no-range {
    .ng5-slider-bar,
    .ng5-slider-tick {
      background: palette('actions', 'warning');
    }
  }

  .ng5-slider {
    .ng5-slider-bar {
      left: 5px;
      height: 2px;
      width: calc(100% - 10px);
    }

    .ng5-slider-selection {
      background: palette('actions', 'warning');
      margin-left: 3px;
    }

    .ng5-slider-pointer.ng5-slider-pointer-min {
      background-color: palette('actions', 'warning');
      border-radius: 100%;
      top: -7px;
      bottom: 0;
      width: 16px;
      height: 16px;
    }

    .ng5-slider-pointer.ng5-slider-pointer-max {
      background: url('/assets/img/default-return-slider.png') no-repeat center;
      background-size: cover;
      border-radius: 8px;
      top: -67px;
      bottom: 0;
      margin-left: -44px;
      width: 104px;
      height: 151px;
      z-index: 0;
    }

    .ng5-slider-pointer:after {
      display: none;
    }

    .ng5-slider-bubble {
      top: 10px;
      bottom: auto;
    }

    .ng5-slider-tick {
      background: palette('actions', 'warning');
      border: 1px solid palette('actions', 'warning');
      border-radius: 100%;
      top: 1px;
      margin-left: 5px;
      width: 4px;
      height: 4px;
    }

    .ng5-slider-tick-value {
      top: 16px;
    }

    .ng5-slider-tick-value::first-letter,
    .ng5-slider-tick-legend::first-letter {
      font-size: 1rem;
      font-weight: bold;
    }

    .ng5-slider-tick-value {
      font-size: 0.75rem;
    }

    .ng5-slider-tick-legend {
      font-size: 0.9rem;
    }

    .ng5-slider-tick-legend {
      color: palette('state', 'base');
      top: 50px;
    }

    .ng5-slider-tick-legend::before {
      @include itauicons('\e9c2', false);
      color: palette('actions', 'warning');
      font-size: 1.1rem;
      position: absolute;
      width: 20px;
      height: 20px;
      top: -16px;
      left: -6px;
    }

    .ng5-slider-tick.ng5-slider-selected {
      background: palette('actions', 'warning');
    }
  }
}

.dialog-actions {
  .btn-confirm {
    background-color: palette('zgray', 'x-dark');
  }
}

.wrapper-secondary {
  .caption-xlight,
  .body-xlight-sbold,
  .btnIcon--light {
    color: palette('zgray', 'x-dark');
    .zf-icon {
      color: palette('zgray', 'x-dark');
    }
  }
}

.pricing-slider {
  &.no-range {
    .ngx-slider-bar,
    .ngx-slider-tick {
      background: palette('zgray', 'x-dark');
    }
  }
  .ngx-slider {
    .ngx-slider-pointer.ngx-slider-pointer-min {
      background: palette('zgray', 'x-dark');
    }
  }
}

// Dashboard Button Color
// -----------------------------------------------------------------------------
.wrapper div .negotiations-tabs button {
  color: palette('zgray', 'x-dark');
  border-bottom: 2px solid palette('zgray', 'x-dark');
}
&.wrapper div .negotiations-tabs button.selected {
  color: palette('zgray', 'x-dark');
  border-bottom: 2px solid palette('zgray', 'x-dark');
}
