// Help Layout
// -----------------------------------------------------------------------------

.help {
  padding: 3.5rem 3.5rem;
}

.help-content {
  position: relative;
  overflow-y: auto !important;
  margin-bottom: 5rem;

  &.help--update-user {
    max-height: calc(100vh - 2rem);
    height: 100%;
  }
}

// Usado em 1 ou + components, por isso movido para um nível superior
.mobileCET{
  font-size: 12px !important;
  & b,strong{
    font-size: 12px !important;

  }
}

// -----------------------------------------------------------------------------
