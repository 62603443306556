// Proponent
// -----------------------------------------------------------------------------

@import url("https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap");

.proponent {
  background-color: palette("zgray", "x-light");
  min-height: 100vh;

  * {
    @extend %font-regular;
  }

  .custom-toastr {
    background-color: palette("zgray", "dark");
    color: palette("zgray", "x-light");
    @extend %font-regular;
    font-size: 0.75rem;
    line-height: 1rem;
    max-width: 360px;
    margin: 0 auto;

    .custom-toastr__row {
      padding: 1rem;

      .custom-toastr__info {
        font-size: 0.75rem;
        width: 248px;
      }

      .countdown {
        position: relative;
        height: 20px;
        width: 20px;

        img {
          display: block;
          height: 20px;
          width: 20px;
        }
      }
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
    @extend %font-bold;
  }

  h6 {
    font-size: 1rem;
  }

  p {
    margin: 0;
  }

  .button {
    cursor: pointer;
    display: block;
    @extend %font-bold;
    font-size: 1rem;
    line-height: 1rem;
    text-align: center;
  }

  .button[disabled] {
    background-color: palette("zgray", "base");
  }

  .button__submit {
    @extend .button;
    background-color: palette("actions", "warning-light");
    color: palette("zgray", "x-light");
    padding: 1rem 3rem;
    border-radius: 24px;
    -webkit-border-radius: 24px;
    -moz-border-radius: 24px;
    -ms-border-radius: 24px;
    -o-border-radius: 24px;
    &.button--light {
      color: palette("actions", "warning-light");
      background-color: palette("zgray", "x-light");
    }
  }

  .button__back {
    @extend .button;
    color: palette("zgray", "mid-dark");
  }

  .button__add {
    @extend .button;
    color: palette("actions", "warning-light");
    @extend %font-regular;
    font-size: 0.75rem;
    line-height: 0.75rem;
    margin-top: 0.5rem;
    text-decoration: underline;
    text-indent: 1rem;
    z-index: 1;
  }

  .button__remove {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-6px);
    margin-right: 1rem;
    z-index: 1;
  }

  .button__bordered {
    @extend .button__submit;
    background-color: transparent;
    border: 2px solid palette("zgray", "x-light");
  }

  .logo {
    display: block;
    max-width: 48px;
  }

  .container {
    margin: 0 auto;
    max-width: 360px !important;
    padding: 2.75rem 1rem;
    position: relative;

    .step,
    .info {
      @extend %font-regular;
    }

    .step {
      color: palette("zgray", "mid-dark");
      font-size: 0.875rem;
    }

    .info {
      color: palette("zgray", "dark");
      font-size: 1.5rem;
    }
  }

  .container--success {
    background-color: palette("actions", "warning-light");
    color: palette("zgray", "x-light");
    @extend %font-regular;
    font-size: 1.5rem;
    padding-top: 5rem;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }

  .header {
    background-color: palette("brand", "mid-dark");
    border-bottom: 4px solid palette("zgray", "mid-light");
    height: 60px;

    img {
      display: inline;
      max-width: 36px;
    }
  }

  .forms-autocomplete-disabled .forms-autocomplete-label,
  .forms-input .mat-form-field-label,
  .simulator-autocomplete-input .mat-form-field-label,
  .forms-select .mat-form-field-label,
  .forms-input .mat-input-element,
  .forms-input .mat-form-field-placeholder,
  .simulator-autocomplete-input .mat-input-element,
  .simulator-autocomplete-input .mat-form-field-placeholder,
  .simulator-autocomplete-input .mat-input-element {
    color: palette("zgray", "x-dark");
    @extend %font-regular;
    text-indent: 1rem;
  }

  .forms-input .mat-form-field-underline,
  .simulator-autocomplete-input .mat-form-field-underline {
    background-color: palette("zgray", "mid-light") !important;
  }

  .forms-autocomplete-disabled .forms-autocomplete-label {
    display: block;
    @extend %font-bold;
  }

  .form {
    margin-top: 0;

    &__header {
      padding: 3rem 0 1rem;
    }

    &__main:not(:first-child) {
      margin-top: 1rem;
    }

    .divider {
      border: 0;
    }

    .mat-error {
      text-indent: 1rem;
    }
  }

  &.proponent--dialog {
    color: palette("zgray", "dark");
    min-height: auto;
    border-radius: 0.5rem;

    .container {
      padding: 2rem 1.25rem;
    }

    img {
      margin: 0.675rem auto 0;
      max-width: 126px;
    }
  }

  .balloon {
    background-color: palette("state", "base");
    border-radius: 0.5em;
    color: palette("zgray", "x-light");
    display: block;
    @extend %font-bold;
    font-size: 0.875rem;
    padding: 1rem;
    min-height: 2rem;
    transform: translateY(-6px);
    &::after {
      content: "";
      position: absolute;
      bottom: 6px;
      left: 20px;
      width: 0;
      height: 0;
      border: 16px solid transparent;
      border-top-color: palette("state", "base");
      border-bottom: 0;
      margin-left: -16px;
      margin-bottom: -16px;
    }
  }
}


// -----------------------------------------------------------------------------
