// Table Styles
// -----------------------------------------------------------------------------

.table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}

.table-header {
  th {
    font-size: 14px;
    padding: 1rem;
    border-bottom: 1px solid palette('zgray', 'light');

    &.min-wide {
      width: 1%;
      white-space: nowrap;
      padding: 1rem 2rem;
    }
  }

  span {
    display: block;
    @extend %font-bold;
    font-size: 0.9rem;
    color: palette('zbrown', 'dark');
  }
}

.table-header-collapsed {
  th {
    padding: 1rem 0;
  }
}

.table-body,
.table-footer {
  td {
    font-size: 14px;
    padding: 1rem 0;
    text-align: center;
    border-bottom: 1px solid palette('zgray', 'light');
    color: palette('zbrown', 'dark');
  }

  &.contrast td {
    border-bottom-color: palette('zgray', 'mid-light');
  }

  tr:last-child td {
    border-bottom: 0;
  }
}

.table-footer {
  tr td {
    border-top: 2px solid palette('zbrown', 'dark');
  }
}

.table-head-label {
  @extend %body-xdark-sbold;
  display: block;
  text-align: left;
  text-overflow: ellipsis;
  max-width: 500px;
  white-space: nowrap;
  overflow: hidden;
  font-size: 0.9rem;
  color: palette('zbrown', 'dark');
}

.table-row-label {
  @extend %font-bold;
  display: block;
  text-align: left;
  text-overflow: ellipsis;
  max-width: 500px;
  white-space: nowrap;
  overflow: hidden;
  color: palette('zbrown', 'dark');
  font-size: 0.9rem;
  padding: 0 1rem;

  &:first-child {
    padding-left: 0;
  }
}

.table-row-text {
  @extend %body-dark;
  display: block;
  text-align: left;
  padding: 0 1rem;

  &:first-child {
    padding-left: 0;
  }
}

.table-row-action {
  padding: 0 1rem;
}

.table__actions {
  & a,
  button {
    float: right;
  }
}

// Column Border
// -----------------------------------------------------------------------------

.table-columns-border {
  .table-header,
  .table-body {
    th,
    td {
      border-right: 1px solid palette('zgray', 'light');
    }

    tr th:last-child,
    tr td:last-child {
      border-right: 0;
    }
  }
}

// -----------------------------------------------------------------------------
