@use '@voxel/foundation/scss/foundation-ids' as *;
@use '@voxel/foundation/scss/foundation-angular' as *;
@use '@voxel/foundation/scss/fonts' as *;
@use '@voxel/tokens/tokens' as *;
// @use 'sass:meta';
// @use '@ids/styles/scss/styles' as *;

@import 'bootstrap';

// Material Core Theme
// -----------------------------------------------------------------------------
@import 'theme/zflow-md-theme';

// Jaguar Theme (Default)
// -----------------------------------------------------------------------------
@import 'base/config', 'base/functions', 'base/mixins', 'base/gap', 'base/document', 'layout/wrapper', 'layout/dialog',
  'layout/help', 'allchannels';

// Land Rover Theme
// -----------------------------------------------------------------------------

.credlinejaguar {
  @import 'base/config-jaguar', 'base/functions', 'base/mixins', 'base/document', 'layout/wrapper', 'layout/dialog',
    'layout/help', 'allchannels';

  @import 'overrides/jaguar';
}

.credlinelandrover {
  @import 'base/config-land-rover', 'base/functions', 'base/mixins', 'base/document', 'layout/wrapper', 'layout/dialog',
    'layout/help', 'allchannels';

  @import 'overrides/land-rover';
}

@import 'new/bootstrap-new';
@import 'new/new-helpers';

// Voxel Theme
// -----------------------------------------------------------------------------

@import '@voxel/tokens/varejo/tokens.css';
// @import '@ids/tokens/varejo/tokens.css';

// -----------------------------------------------------------------------------
