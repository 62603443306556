// Dialog Module
// -----------------------------------------------------------------------------

mat-dialog-container {
  padding: 0 !important;
  overflow: inherit !important;
}


// -----------------------------------------------------------------------------
