// Custom Icons
// -----------------------------------------------------------------------------

.icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 22px;
}

.zf-icon {
  display: inline-flex;
  align-self: center;

  &--xs {
    font-size: 0.25rem;
    line-height: 0.25rem;
  }

  &--sm {
    font-size: 0.5rem;
    line-height: 0.5rem;
  }

  &--md {
    font-size: 1rem;
    line-height: 1rem;
  }

  &--lg {
    font-size: 1.5rem;
    line-height: 1.5rem;
  }

  &--xl {
    font-size: 3rem;
    line-height: 3rem;
  }

  &--xxl {
    font-size: 3.5rem;
    line-height: 3.5rem;
  }

  &--xxxl {
    font-size: 7rem;
    line-height: 7rem;
  }
}

.icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 22px;
}

.icon-success {
  color: palette('state', 'success');
}

// Circle Icons
// -----------------------------------------------------------------------------

.icon-circle {
  border-radius: 50%;
  text-align: center;
  @extend %font-bold;
  vertical-align: middle;
  margin-right: 0.4rem;
  font-size: 0.8rem;
  background-color: palette('brand', 'mid-dark');
  color: palette('zgray', 'x-light');
}

.icon-circle-success {
  @extend .icon-circle;
  background-color: palette('state', 'success');
  color: palette('zgray', 'x-light');
}

// Icons
// -----------------------------------------------------------------------------

.icon-add {
  @include itauicons('\e905');
}

.icon-check {
  @include itauicons('\e947');
}

// Active Icons
// -----------------------------------------------------------------------------

.icon-active-brand {
  color: palette('brand', 'mid-dark');
}

// Icon Sizes
// -----------------------------------------------------------------------------

.icon-xsmall-middark {
  color: palette('zbrown', 'mid-dark');
  font-size: 0.8rem;
  color: palette('zbrown', 'mid-dark');
}

.icon-xsmall {
  color: palette('brand', 'mid-dark');
  font-size: 0.8rem;
}

.icon-small {
  font-size: 1.5rem;
}

.icon-medium {
  font-size: 2rem;
}

.icon-large {
  font-size: 3.5rem;
}

.icon-brand-medium {
  font-size: 2.5rem;
  display: block;
  color: palette('brand', 'mid-dark');
}

.icon-brand-big {
  font-size: 3.5rem;
  display: block;
  color: palette('brand', 'mid-dark');
}

// -----------------------------------------------------------------------------
.agent-status-icon {
  float: right;
  font-size: 1.2rem;
  line-height: 1.2rem;
}

.agent-status-icon-expiring {
  @extend .agent-status-icon;
  @include itauicons('\ea66');

  color: palette('actions', 'warning');
}

.agent-status-icon-expired {
  @extend .agent-status-icon;
  @include itauicons('\ea88');
  color: palette('state', 'info');
}

// Icons Active
// -----------------------------------------------------------------------------

.icon-active-brand {
  color: palette('brand', 'mid-dark');
}

// Icons Circle
// -----------------------------------------------------------------------------

.icon-circle {
  @extend %font-bold;
  margin-right: 0.4rem;
  color: palette('zgray', 'x-light');
  font-size: 0.8rem;
  text-align: center;
  background-color: palette('brand', 'mid-dark');
  border-radius: 50%;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-circle-success {
  @extend .icon-circle;
  color: palette('zgray', 'x-light');
  background-color: palette('state', 'success');
}

.icon-circle-success-sm {
  @extend .icon-circle-success;
  min-width: 24px;
  min-height: 24px;
  font-size: var(--title-sm-font-size);
}

.icon-circle-success-md {
  @extend .icon-circle-success;
  min-width: 30px;
  min-height: 30px;
  font-size: var(--title-md-font-size);
}

.icon-circle-success-lg {
  @extend .icon-circle-success;
  min-width: 36px;
  min-height: 36px;
  font-size: var(--title-lg-font-size);
}

.icon-circle-danger {
  @extend .icon-circle;
  color: palette('zgray', 'x-light');
  background-color: palette('state', 'danger');
}

.icon-circle-danger-sm {
  @extend .icon-circle-danger;
  min-width: 24px;
  min-height: 24px;
  font-size: var(--title-sm-font-size);
}

.icon-circle-danger-md {
  @extend .icon-circle-danger;
  min-width: 30px;
  min-height: 30px;
  font-size: var(--title-md-font-size);
}

.icon-circle-danger-lg {
  @extend .icon-circle-danger;
  min-width: 36px;
  min-height: 36px;
  font-size: var(--title-lg-font-size);
}
// -----------------------------------------------------------------------------
