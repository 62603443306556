// Pagination
// -----------------------------------------------------------------------------

.pagination {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  color: palette('brand', 'mid-dark');
  font-size: 1rem;

  * {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-pre */
  }

  & > span {
    font-weight: 700;
    @extend %font-xbold;
    margin: 0 0.5rem;
    width: 1.4rem;
    text-align: center;
    padding: 0.1rem;

    &:not(.active):not(.fake-page):hover {
      cursor: pointer;
      background-color: rgba(palette('brand', 'mid-dark'), 0.25);
      border-radius: 4px;
    }
  }

  & .pagination_backward {
    transform: rotate(180deg);

    &.disabled {
      opacity: 0.25;
    }
  }

  & .pagination_forward {
    position: relative;
    top: 0.06rem;

    &.disabled {
      opacity: 0.25;
    }
  }

  & [class*='pagination_'] {
    font-size: 1.2rem;

    &:not(.disabled):hover {
      & .zf-icon {
        @extend %font-bold;
        cursor: pointer;
      }
    }
  }

  & .active {
    color: #fff;
    background-color: palette('brand', 'mid-dark');
    border-radius: 4px;
  }
}

.pagination_wrapper {
  width: 100%;
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
}

.pagination_column {
  display: flex;
  flex-direction: column;
}

.pagination-settings {
  height: 2.3rem;
}

// -----------------------------------------------------------------------------
