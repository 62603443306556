// Publisher
// -----------------------------------------------------------------------------

// refactor all components for a more generic one

.publisher-list {
  &.mat-card {
    padding: 2.5rem;
  }

  .publisher-list-row {
    min-height: 50px;
    padding: 1.5rem 0;
    border-bottom: 2px solid palette('zgray', 'light');

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
      border-bottom: 0;
    }
  }

  .publisher-list-thumb {
    float: left;
    height: 50px;
  }

  .publisher-list-title {
    @extend .title-xdark;
    margin: 0;
  }

  .publisher-list-caption {
    @extend .body-sm-middark;
  }
}

// Publisher Upload
// -----------------------------------------------------------------------------

.publisher-upload {
  position: relative;
  width: 100%;
}

input[type='file'].publisher-upload-input,
.publisher-upload-input input[type='file'] {
  position: absolute;
  width: 80%;
  height: 72px;
  z-index: 10;
  padding: 0;
  opacity: 0;
  cursor: pointer;
}

.publisher-upload-input input[type='file'] {
  display: block;
}

.publisher-upload-remove {
  @extend .btnIcon;
  position: relative;
  top: -2px;
}

.publisher-upload-area {
  width: 100%;
  text-align: center;
  padding: 1.5rem;
  box-sizing: border-box;
  background-color: palette('zgray', 'x-light');
  border: 2px dashed palette('zgray', 'light');
  color: palette('brand', 'mid-dark');
  border-radius: 4px;

  .zf-icon {
    font-size: 1.5rem;
    margin-right: 0.5rem;
    vertical-align: middle;
  }
}

.publisher-upload-area-text {
  position: relative;
  top: 2px;
}

// Publisher Upload With Preview
// -----------------------------------------------------------------------------

.publisher-upload-preview {
  .publisher-upload-input {
    input[type='file'] {
      width: 160px;
      height: 160px;
    }
  }

  .publisher-upload-area {
    width: 160px;
    height: 160px;
    border: 0;
    padding-top: 2.15rem;
    background-color: palette('zgray', 'light');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    overflow: hidden;
    transition: all 0.2s ease-in-out;

    .zf-icon {
      margin: 0;
      font-size: 2rem;
      transition: all 0.2s ease-in-out;
    }
  }

  .publisher-upload-area-text {
    display: block;
    margin-top: 1rem;
    transition: all 0.2s ease-in-out;
  }
}

.publisher-upload-preview-change {
  .publisher-upload-area .zf-icon,
  .publisher-upload-area-text {
    opacity: 0;
  }

  &:hover {
    .publisher-upload-area {
      box-shadow: 0 0 0 500px rgba(palette('brand', 'mid-dark'), 0.8) inset;

      .zf-icon {
        color: palette('zgray', 'x-light');
        opacity: 1;
      }
    }

    .publisher-upload-area-text {
      color: palette('zgray', 'x-light');
      opacity: 1;
    }
  }
}

// Btn Toggle Group
// -----------------------------------------------------------------------------

.btn-group {
  // refactor for a 'publisher' component

  &.mat-button-toggle-group {
    border: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
}

.btn-group-toggle {
  &.mat-button-toggle {
    margin-right: 1rem;
    padding: 0.5rem;
    border-radius: 4px;
    color: palette('brand', 'mid-dark');
    @extend %font-bold;
    border: 1px solid palette('zgray', 'light');

    .zf-icon {
      font-size: 1.5rem;
      margin-right: 0.5rem;
      vertical-align: middle;
    }
  }

  &.mat-button-toggle-checked {
    color: palette('zgray', 'x-light');
    border: 1px solid transparent;
    background-color: palette('brand', 'mid-dark');
  }
}

// -----------------------------------------------------------------------------
