.logs-timeline {
  .timeline {
    position: relative;
    font-size: 2rem;
    background-color: palette('zgray', 'x-light');
    padding-bottom: 0.25rem;
  }

  .logs-timeline-line {
    position: relative;
    padding-bottom: 60px;
    overflow: hidden;

    &::before {
      content: ' ';
      background-color: palette('dark-ghost', 20);
      position: absolute;
      width: 2px;
      height: 100%;
      top: 0;
      left: calc(50% - 2px);
    }

    &.logs-timeline-line-first {
      padding-bottom: 48px;
    }

    &.logs-timeline-line-last {
      &::before {
        height: 0.25rem;
      }
    }

    .logs-timeline-icon {
      background-color: palette('zgray', 'x-light');
      position: absolute;
      width: 12px;
      height: 12px;
      top: 0.25rem;
      left: calc(50% - 7px);
      box-sizing: border-box;
      border: 2px solid palette('dark-ghost', 20);
      border-radius: 12px;
    }
  }
}
