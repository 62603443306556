// Simple List
// -----------------------------------------------------------------------------

.simplelist {
  display: flex;
  flex-direction: column;

  & .simplelist_item {
    display: flex;
    flex-direction: row;
    padding: 0.75rem 0;

    & span {
      color: palette('zbrown', 'dark')
    }
  }
}


.simplelist_item_picture {
  display: flex;
  flex-direction: column;
  padding-right: 1rem;

  & span {
    font-size: 1.4rem;
    color: palette('zbrown', 'dark')
  }
}

.simplelist_item_block {
  display: flex;
  flex-direction: column;
}

.simplelist_item_name {
  margin-bottom: 0.25rem;
  @extend %font-bold;
}


// -----------------------------------------------------------------------------