// Notifications
// -----------------------------------------------------------------------------

.notifications {
  //
}

.notifications-title {
  @extend %title-dark;
  text-align: center;
  border-bottom: 1px solid palette('zgray', 'light');
  margin: 0;
  padding: 0;
  height: 65px;
  line-height: 67px;
}

.notifications-list-wrapper {
  height: 410px;
  overflow: hidden;
  overflow-y: scroll;
  border-bottom: 1px solid palette('zgray', 'light');
}

.notifications-list {
  list-style: none;
  padding: 0;
  margin: 0;

  > li {
    padding: 1.5rem;
    text-align: left;
    border-bottom: 1px solid palette('zgray', 'mid-light');
    cursor: pointer;
    transition: background 0.2s ease;

    &:hover {
      background-color: lighten(palette('zbrown', 'x-light'), 3%);
    }

    & a {
      text-decoration: none;
    }
  }
}

.notifications-header {
  text-align: left;
  margin-bottom: 0.5rem;
}

.notifications-header-title {
  @extend %body-dark-sbold;
}

.notifications-header-title-number {
  @extend %caption-middark;
  display: block;
}

.notification-link {
  @extend %font-bold;
  display: block;
  color: palette('actions', 'warning');
  font-size: 0.9rem;
}

.notification-action {
  @extend %font-bold;
  width: 100%;
  padding: 1.1rem 0;
  text-align: center;
  text-decoration: none;
  font-size: 0.9rem;
  color: palette('actions', 'warning');
  background-color: transparent;
}

.notifications-date {
  font-weight: 600;
}

// Notification labels
// -----------------------------------------------------------------------------

.notifications-label {
  @extend %body-dark-sbold;
  font-size: 0.875rem;
  @extend %font-regular;
  color: palette('zbrown', 'mid-dark');
}

.notifications-label-success {
  @extend .notifications-label;
  @extend %font-bold;
  color: palette('state', 'success');
}

.notifications-label-danger {
  @extend .notifications-label;
  @extend %font-bold;
  color: palette('state', 'danger');
}

// Full Notifications
// -----------------------------------------------------------------------------

.notifications-full {
  .notifications-title {
    padding: 1.5rem 2.5rem;
    text-align: left;
  }

  .notifications-header {
    margin-bottom: 2rem;
  }

  .notifications-header-title {
    font-size: 1.2rem;
  }

  .notifications-header-title-number {
    display: inline-block;
    font-size: 1rem;
    margin-left: 1rem;
  }

  .notifications-list li {
    padding: 2.5rem;
  }

  .notifications-label b {
    @extend %body-dark-sbold;
    font-size: 0.9rem;
    margin-right: 0.5rem;
  }
}

// Empty Message
// -----------------------------------------------------------------------------

.notifications-empty {
  height: 465px;
}

.notifications-empty-wrapper {
  height: 100%;
}

.notifications-empty-text {
  text-align: center;
  font-weight: 600;
  font-size: 1.5rem;
  color: palette('zgray', 'mid-dark');

  .zf-icon {
    display: block;
    font-size: 2rem;
    margin-top: 1rem;
  }
}

// -----------------------------------------------------------------------------
