.swiper-pagination-custom-wrapper {
  position: relative;
  height: 100%;

  .swiper-wrapper-container {
    &.swiper-with-side-slides-visible {
      overflow-x: hidden;

      .slide--faded {
        pointer-events: none;
      }

      .slide--hidden {
        pointer-events: none;
        opacity: 0;
      }

      .swiper-container {
        overflow: visible;
        z-index: 0;

        .slide-overlay {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: 9999;
          pointer-events: none;
          display: block;
          transition: background-image 0.1s ease-in-out;

          &--left {
            background-image: linear-gradient(to right, rgb(96, 87, 81) 100%, transparent 100%);
          }

          &--right {
            background-image: linear-gradient(to left, rgb(96, 87, 81) 100%, transparent 100%);
          }
        }
      }
    }
  }

  .swiper-actions {
    .swiper-pagination-custom {
      display: block;
      width: 100%;
      text-align: center;

      .swiper-pagination-bullet,
      .swiper-pagination-bullet-active {
        width: 10px;
        height: 10px;
      }
      &.swiper-pagination-custom-tiny {
        .swiper-pagination-bullet,
        .swiper-pagination-bullet-active {
          width: 8px;
          height: 8px;
        }

        .swiper-pagination-bullet {
          margin: auto 4px;
        }
      }

      .swiper-pagination-bullet {
        display: inline-block;
        margin: auto 5px;
        background: palette('zgray', 'base');
        opacity: 1;
      }

      .swiper-pagination-bullet-active {
        background: palette('brand', 'mid-dark');
      }
    }

    .swiper-button-prev-custom,
    .swiper-button-next-custom {
      width: 40px;
      height: 40px;
      display: inline-flex;
      position: absolute;
      top: calc(50% - 40px);
      border-radius: 20px;
      background: palette('brand', 'mid-dark');
      font-size: 24px;
      z-index: 1;
      color: #ffffff;

      &::before {
        width: 40px;
        height: 40px;
        text-align: center;
        line-height: 40px;
      }

      &.swiper-button-disabled {
        opacity: 0.2;
        pointer-events: none;
      }
    }

    .swiper-button-prev-custom {
      left: -20px;
      @include itauicons('keyboard_arrow_left');
    }

    .swiper-button-next-custom {
      right: -20px;
      @include itauicons('\e9c4');
    }

    .swiper-button-prev-voxel,
    .swiper-button-next-voxel {
      width: 40px;
      height: 40px;
      display: inline-flex;
      position: absolute;
      top: calc(50% - 40px);
      border-radius: 20px;
      background: white;
      font-size: 24px;
      z-index: 1;
      align-items: center;
      justify-content: center;

      &.swiper-button-disabled {
        opacity: 0.2;
        pointer-events: none;
      }
    }

    .swiper-button-prev-voxel {
      left: 20px;
    }

    .swiper-button-next-voxel {
      right: 20px;
    }
  }
}
