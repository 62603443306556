// Spacer Module
// -----------------------------------------------------------------------------

.spacer {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}


// -----------------------------------------------------------------------------
