// Panel Module
// -----------------------------------------------------------------------------

.panel {
}

.panel-info {
  @extend %body-middark;

  b {
    @extend %body-dark-sbold;
    display: block;
    margin-top: 0.5rem;
  }
  span + span {
    @extend %body-dark;
  }
}

.panel-info-danger {
  b {
    @extend %body-sbold-danger;
  }
}

.panel-action {
  margin-left: 1rem;
  font-size: 1.5rem;
  color: palette('zbrown', 'x-dark');
}

.panel-status {
  @extend %font-bold;
  font-size: 1rem;
  &--danger {
    color: palette('state', 'info');
  }
  &--warning {
    @extend %body-sbold-warning;
  }
}

.panel-alert {
  @extend %body-dark;

  .zf-icon {
    position: relative;
    top: -1px;
    font-size: 1.5rem;
    vertical-align: middle;
    margin-right: 0.3rem;
  }
}

// Panel Elements
// -----------------------------------------------------------------------------

.panel-review {
  @extend .panel-info;
  span {
    @extend %body-bold-success;
  }
  icon {
    span {
      font-family: 'itau-icons' !important;
    }
  }
}

// -----------------------------------------------------------------------------
