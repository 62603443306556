@import '../../node_modules/@zflow/itau-fonts/itaudisplay/stylesheet.css';

// Fonts
// -----------------------------------------------------------------------------

// TODO: fix font
// %font-bold {
//   font-family: 'itau_displaybold' !important;
// }

// %font-black {
//   font-family: 'itau_displayblack' !important;
// }

// %font-heavy {
//   font-family: 'itau_displayheavy' !important;
// }

// %font-light {
//   font-family: 'itau_displaylight' !important;
// }

// %font-regular {
//   font-family: 'itau_displayregular' !important;
// }

// %font-xbold {
//   font-family: 'itau_displayxbold' !important;
// }


// -----------------------------------------------------------------------------
