
// $gap-gutters: (
//   1: 0.25rem,
//   2: 0.5rem,
//   3: 1rem,
//   4: 1.5rem,
//   5: 2rem,
//   6: 2.5rem,
//   7: 3rem,
// ) !default;

$gap-gutters: (
  '0': 0rem,
  '025': 0.25rem,
  '05': 0.5rem,
  '1': 1rem,
  '125': 1.25rem,
  '15': 1.5rem,
  '2': 2rem,
  '225': 2.25rem,
  '25': 2.5rem,
  '3': 3rem,
  '325': 3.25rem,
  '35': 3.5rem,
  '4': 4rem,
  '425': 4.25rem,
  '45': 4.5rem,
  '5': 5rem,
);

.no-gap > * + * {
  margin-top: 0;
  margin-left: 0;
}

*[class^='gap'] {
  display: flex;
}

*[class^='gap-x'],
*[class^='gap-sm-x'],
*[class^='gap-md-x'],
*[class^='gap-lg-x'],
*[class^='gap-xl-x'],
*[class^='gap-xxl-x'] {
  flex-direction: row;
}

*[class^='gap-y'],
*[class^='gap-sm-y'],
*[class^='gap-md-y'],
*[class^='gap-lg-y'],
*[class^='gap-xl-y'],
*[class^='gap-xxl-y'] {
  flex-direction: column;
}

@mixin make-gap-direction($direction, $gutter) {
  & > * + * {
    @if $direction == 'y' {
      margin-top: $gutter;
    } @else {
      margin-left: $gutter;
    }
  }
}

@mixin make-gap-class($breakpoint, $direction, $infix, $gutter) {
  @if $breakpoint {
    .gap-#{$breakpoint}-#{$direction}#{$infix} {
      @include make-gap-direction($direction, $gutter);
    }
  } @else {
    .gap-#{$direction}#{$infix} {
      @include make-gap-direction($direction, $gutter);
    }
  }
}

@mixin make-gap($breakpoint: null) {
  @each $infix, $gutter in $gap-gutters {
    @include make-gap-class($breakpoint, 'x', $infix, $gutter);
  }
  @each $infix, $gutter in $gap-gutters {
    @include make-gap-class($breakpoint, 'y', $infix, $gutter);
  }
}

@include make-gap();

// @each $breakpoint, $size in $grid-breakpoints {
//   @if $size > 0 and $size != 100% {
//     @media (min-width: $size) {
//       @include make-gap($breakpoint);
//     }
//   }
// }
