// Chip Module
// -----------------------------------------------------------------------------

.chip {
  @extend %font-regular;
  --chip-feedback-warning: #f1ae2f;
  --chip-text-color-dark: #231d19;

  display: inline-block;
  padding: 0.3rem 0.5rem;
  border-radius: 4px;
  text-transform: lowercase;
  font-size: 0.875rem;
  @extend %font-regular;
  color: palette('zgray', 'x-light') !important;
  white-space: nowrap;
  background-color: palette('state', 'base');

  &.chip-dark {
    background-color: palette('zbrown', 'dark');
  }

  &.chip-base {
    background-color: palette('state', 'base');
  }

  &.chip-danger {
    background-color: palette('state', 'danger');
  }

  &.chip-dark {
    background-color: palette('zbrown', 'dark');
  }

  &.chip-info {
    background-color: palette('state', 'info');
  }

  &.chip-success {
    background-color: palette('state', 'success');
  }

  &.chip-warning {
    color: var(--chip-text-color-dark) !important;
    background-color: var(--chip-feedback-warning);
  }

  &.chip-danger {
    background-color: palette('state', 'danger');
  }

  &.chip-white {
    background-color: palette('zgray', 'x-light');
    color: palette('zbrown', 'dark') !important;
  }

  &.chip-outlined {
    border: 1px solid palette('state', 'base');
    background-color: transparent;
    color: palette('zbrown', 'mid-dark') !important;
    line-height: 18px;

    .chip-emphasis {
      color: palette('state', 'base');
      font-weight: 700;
    }

    &.chip-tiny {
      font-size: 11px;
    }
  }

  &.chip-outlined--warning {
    border-color: palette('actions', 'warning');
    .chip-emphasis {
      color: palette('actions', 'warning');
    }
  }

  &.chip-outlined--danger {
    border-color: palette('state', 'danger');
    .chip-emphasis {
      color: palette('state', 'danger');
    }
  }

  &.chip-tiny {
    @extend %font-xbold;
    padding: 1px 10px;
    font-size: 12px;
    line-height: 18px;
    padding: 1px 10px;
    border-radius: 20px;
    @extend %font-xbold;
  }

  &.align-top,
  &.align-middle,
  &.align-right {
    @media only screen and (max-width: 1024px) {
      position: static;
    }
    position: absolute;
    right: 0;
  }

  &.align-top {
    @media only screen and (max-width: 1024px) {
      position: static;
    }
    position: absolute;
    top: 0.5rem;
  }

  // Medium Devices
  @include tablet {
    padding: 0.25rem 0.6rem 0.25rem 0.6rem;
  }
}

// -----------------------------------------------------------------------------
