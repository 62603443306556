// Dashboard
// -----------------------------------------------------------------------------

.dashboard {
  display: flex;
  flex-direction: column;
}

//** Dashboard Tabled
// ---------------------------------------------------------------------------

.dashboardTabled,
%dashboardTabled {
  @media only screen and (max-width: 1024px) {
    min-width: max-content;
  }
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 0.8rem;

  & * {
    box-sizing: border-box;
  }
}

.dashboardTabled_panelhead,
%dashboardTabled_panelhead {
  width: 100%;
  display: flex;
  padding: 2rem 2rem 1rem;
  flex-direction: row;
  justify-content: space-between;
  color: palette('zbrown', 'dark');

  & > * {
    display: flex;
    flex-direction: column;
    padding: 0.2rem 0.4rem;
  }

  & div {
    @extend %font-xbold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.dashboardTabled_item,
%dashboardTable_item {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.dashboardTabled_row,
%dashboardTabled_row {
  width: 100%;
  display: flex;
  padding: 1rem 1rem;
  padding: 1.5rem 2rem;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid palette('zgray', 'light');
  color: palette('zbrown', 'dark');
  @extend %font-regular;

  & > * {
    padding: 0 0.4rem;
  }
}

//** Dashboard Tabled - Dealer Dashboard
// ---------------------------------------------------------------------------

.dashboardTabled {
  &--dealerDashboard {
    @extend %dashboardTabled;

    & .dashboardTabled_panelhead {
      @extend .dashboardTabled_panelhead;

      & > * {
        * {
          pointer-events: none !important;
        }

        flex-direction: row;

        &:hover {
          .zf-icon {
            opacity: 0.7;
          }

          &:not([class*='activeSort-']) {
            .zf-icon {
              transform: rotate(180deg);
            }
          }
        }
      }

      & [class*='activeSort-'] {
        .zf-icon {
          opacity: 1;
          @extend %font-xbold;
        }
      }

      & .activeSort-downwards {
        .zf-icon {
          transform: rotate(180deg);
        }
      }

      .zf-icon {
        opacity: 0;
        color: palette('brand', 'mid-dark');
        font-size: 1.1rem;
        margin-left: 0.15rem;
      }

      & :nth-child(9n-8) {
        flex: 0 0 9.6%;
      }

      & :nth-child(9n-7) {
        flex: 0 0 13%;
      }

      & :nth-child(9n-6) {
        flex: 0 0 13%;
      }

      & :nth-child(9n-5) {
        flex: 0 0 13%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      & :nth-child(9n-4) {
        flex: 0 9.6%;
        cursor: pointer;
      }

      & :nth-child(9n-3) {
        flex: 0 0 9.6%;
      }

      & :nth-child(9n-2) {
        flex: 0 0 9.6%;
      }

      & :nth-child(9n-1) {
        flex: 0 0 9.6%;
      }

      & :nth-child(9n) {
        flex: 0 0 13%;
      }
    }

    & .dashboardTabled_row {
      @extend %dashboardTabled_row;

      & > * {
        padding: 0 0.4rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      & :nth-child(9n-8) {
        flex: 0 0 9.6%;
      }

      & :nth-child(9n-7) {
        flex: 0 0 13%;
      }

      & :nth-child(9n-6) {
        flex: 0 0 13%;
      }

      & :nth-child(9n-5) {
        flex: 0 0 13%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      & :nth-child(9n-4) {
        flex: 0 0 9.6%;
      }

      & :nth-child(9n-3) {
        flex: 0 0 9.6%;
      }

      & :nth-child(9n-2) {
        flex: 0 0 9.6%;
      }

      & :nth-child(9n-1) {
        flex: 0 0 9.6%;
      }

      & :nth-child(9n) {
        flex: 0 0 13%;
      }
    }
  }
}

//** Dashboard Tabled - Itau Dashboard
// ---------------------------------------------------------------------------

.dashboardTabled {
  &--itauDashboard {
    @extend %dashboardTabled;

    .chip {
      font-size: 0.8rem;
    }

    & .cdk-virtual-scroll-viewport {
      overflow-x: hidden;
    }

    & .dashboardTabled_panelhead {
      @extend %dashboardTabled_panelhead;
      font-size: 0.9rem;
      padding: 1rem 2.1rem;

      & .zf-icon {
        color: palette('brand', 'mid-dark');
        font-size: 1rem;
        padding-left: 0.25rem;
      }

      & > div {
        &:not(.active) .zf-icon {
          opacity: 0;
          transition: all 0.3s ease;
        }

        &:not(.active):hover .zf-icon {
          opacity: 0.7;
        }

        &.active .zf-icon {
          opacity: 1;
        }

        * {
          pointer-events: none !important;
        }
      }

      & > :nth-child(6n-5) {
        flex: 0 0 10%;

        & span {
          top: 0px;
          position: relative;
        }
      }

      & > :nth-child(6n-4) {
        flex: 0 0 26%;
      }

      & > :nth-child(6n-3) {
        flex: 0 0 8%;
      }

      & > :nth-child(6n-2) {
        flex: 0 0 26%;
      }

      & > :nth-child(6n-1) {
        flex: 0 0 15%;
      }

      & > :nth-child(6n) {
        flex: 0 0 15%;
      }
    }

    & .dashboardTabled_row {
      @extend %dashboardTabled_row;
      cursor: pointer;
      padding: 1rem 2.1rem;

      &:hover {
        background-color: palette('zgray', 'light');
      }

      & > :nth-child(6n-5) {
        flex: 0 0 10%;
      }

      & > :nth-child(6n-4) {
        flex: 0 0 26%;
      }

      & > :nth-child(6n-3) {
        flex: 0 0 8%;
      }

      & > :nth-child(6n-2) {
        flex: 0 0 26%;
      }

      & > :nth-child(6n-1) {
        flex: 0 0 15%;
      }

      & > :nth-child(6n) {
        flex: 0 0 15%;
      }
    }
  }
}

//** Dashboard Tabled - Home Dashboard
// ---------------------------------------------------------------------------

.dashboardTabled {
  &--homeDashboard {
    @extend %dashboardTabled;

    .chip {
      font-size: 0.8rem;
    }

    & .mat-form-field-label-wrapper {
      box-sizing: content-box;
    }

    .dashboardTabled_filter {
      font-size: 0.9rem;
    }

    & .dashboardTabled_row {
      @extend %dashboardTabled_row;
      cursor: pointer;
    }

    & .cdk-virtual-scroll-viewport {
      overflow-x: hidden;
    }

    & .dashboardTabled_panelhead {
      @extend %dashboardTabled_panelhead;
      padding: 1rem 1rem;

      & .zf-icon {
        color: palette('brand', 'mid-dark');
        font-size: 1rem;
        padding-left: 0.25rem;
      }

      & > div {
        &:not(.active) .zf-icon {
          opacity: 0;
          transition: all 0.3s ease;
        }

        &:not(.active):hover .zf-icon {
          opacity: 0.7;
        }

        &.active .zf-icon {
          opacity: 1;
        }

        * {
          pointer-events: none !important;
        }
      }

      & > :nth-child(8n-7) {
        flex: 0 0 8%;

        & span {
          top: 2px;
          position: relative;
        }
      }

      & > :nth-child(8n-6) {
        flex: 0 0 18%;
      }

      & > :nth-child(8n-5) {
        flex: 0 0 6%;
      }

      & > :nth-child(8n-4) {
        flex: 0 24%;
      }

      & > :nth-child(8n-3) {
        flex: 0 0 13%;
      }

      & > :nth-child(8n-2) {
        flex: 0 0 13%;
      }

      & > :nth-child(8n-1) {
        flex: 0 0 15%;
      }

      & > :nth-child(8n) {
        flex: 0 0 3%;
      }
    }

    & .dashboardTabled_row {
      padding: 1rem 1rem;

      &:hover {
        background-color: palette('zgray', 'light');
      }

      & > :nth-child(8n-7) {
        flex: 0 0 8%;
      }

      & > :nth-child(8n-6) {
        flex: 0 0 18%;
      }

      & > :nth-child(8n-5) {
        flex: 0 0 6%;
      }

      & > :nth-child(8n-4) {
        flex: 0 0 24%;
      }

      & > :nth-child(8n-3) {
        flex: 0 0 13%;
      }

      & > :nth-child(8n-2) {
        flex: 0 0 13%;

        & div {
          & > .zf-icon {
            font-size: 1.5rem;
          }
        }

        & .text-success {
          font-size: 0.7rem;

          & .zf-icon {
            font-size: 0.8rem;
          }
        }

        &:hover {
          cursor: pointer;
          color: palette('brand', 'mid-dark');
          transform: scale(1.05);
        }
      }

      & > :nth-child(8n-1) {
        display: inline-flex;
        flex: 0 0 15%;
        align-items: center;
      }

      & > :nth-child(8n) {
        flex: 0 0 3%;
        font-size: 1.15rem;

        & button {
          color: palette('zgray', 'base');
          font-size: 1.15rem;

          &:hover {
            color: palette('brand', 'mid-dark');
          }
        }
      }
    }
  }
}

// -----------------------------------------------------------------------------
