:root {
  --padding-top-menu: 80px;
}

// Dropdown Custom Components
// -----------------------------------------------------------------------------

.dropdown {
  width: 100%;
}

// Dropdown Trigger
// -----------------------------------------------------------------------------

.dropdown-trigger {
  color: palette('zgray', 'x-light');
}

.dropdown-trigger-icon {
  @extend .btnIcon;
}

.dropdown-trigger-select {
  position: relative;
  width: 100%;
  border-bottom-color: palette('zgray', 'light');
  border-bottom-style: solid;
  border-bottom-width: 1px;

  .dropdown-trigger-select-label {
    display: block;
    color: palette('zbrown', 'mid-dark');
  }

  .dropdown-trigger-select-placeholder {
    display: block;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
  }

  .dropdown-trigger-select-placeholder-bordered {
    @extend .dropdown-trigger-select-placeholder;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    border-bottom-color: palette('zgray', 'mid-light');
    border-bottom-style: solid;
    border-bottom-width: 1px;
  }

  &::before {
    @include itauicons('\e9c2', false);
    position: absolute;
    right: -8px;
    bottom: 3px;
    display: block;
    width: 20px;
    height: 20px;
    color: palette('zbrown', 'dark');
  }
}

// Dropdown Menu
// -----------------------------------------------------------------------------

.dropdown-menu {
  padding: 1.5rem;
  background-color: palette('zgray', 'x-light');
}

.dropdown-menu-collapsed {
  padding: 0;
}

.dropdown-menu-scroll {
  @media only screen and (min-width: 1024px) {
    min-width: 30rem;
  }
  height: 30rem;
  overflow-y: scroll;
}

.dropdown-menu-scroll-wider {
  @media only screen and (min-width: 1024px) {
    min-width: 35rem;
  }
}

// Dropdown Menu Item
// -----------------------------------------------------------------------------

.dropdown-menu-item {
  * {
    transition: all 0.2s ease;
  }

  &:hover * {
    color: palette('brand', 'mid-dark');
  }
}

// Dropdown Menu Sizes
// -----------------------------------------------------------------------------

.dropdown-menu-small {
  min-width: 250px;
}

.dropdown-menu-medium {
  @media only screen and (max-width: 400px) {
    min-width: 300px;
  }
  min-width: 380px;
  max-width: 400px;
}

// Dropdown Menu Elements
// -----------------------------------------------------------------------------

.dropdown-menu-title {
  @extend %title-xdark;
  margin-bottom: 2rem;

  .dropdown-menu-title-icon {
    float: right;
    margin-left: 2.125rem;
    font-size: 1.5rem;
  }
}

// Pricing
.pricing-dropdown-menu {
  .ng2-dropdown-menu {
    overflow-x: hidden !important;
  }
}
// -----------------------------------------------------------------------------

// Installments
// -----------------------------------------------------------------------------

.dropdown-trigger-installments {
  position: relative;
  padding-bottom: 0.5rem;
  border-bottom-color: palette('zgray', 'light');
  border-bottom-style: solid;
  border-bottom-width: 1px;

  &--disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }

  .dropdown-trigger-installments-label {
    margin-bottom: 0.2rem;
    color: palette('zgray', 'x-light');
    font-size: 0.75rem;
  }

  .dropdown-trigger-installments-placeholder {
    @extend %font-xbold;
    color: palette('zgray', 'x-light');
    font-size: 1.8rem;
  }

  .dropdown-trigger-installments-separator {
    @extend %font-bold;
    margin-right: 0.25rem;
    margin-left: 0.25rem;
    color: palette('zgray', 'x-light');
    font-size: 1.2rem;
  }

  .dropdown-trigger-installments-icon {
    @include itauicons('\e9c2');
    position: absolute;
    top: 50%;
    right: -6px;
    width: 26px;
    height: 24px;
    color: palette('zgray', 'x-light');
    font-size: 1.3rem;
    line-height: 24px;
    text-align: center;
    transform: translateY(-50%);
  }

  .dropdown-trigger-installments-was {
    font-size: 1.3rem;
  }
}

.dropdown-trigger-installments-dark {
  padding: 1.5rem;

  .dropdown-trigger-installments-label,
  .dropdown-trigger-installments-placeholder,
  .dropdown-trigger-installments-separator,
  .dropdown-trigger-installments-icon {
    color: palette('zbrown', 'dark');
  }
}

.dropdown-installments-title {
  @extend %title-dark;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.dropdown-installments-option {
  padding: 1rem 1.5rem;
  color: palette('zbrown', 'dark');
  border-bottom-color: palette('zgray', 'light');
  border-bottom-style: dotted;
  border-bottom-width: 1px;
  transition: 0.2s all ease;

  &:hover {
    background-color: palette('zbrown', 'x-light');
  }
}

.dropdown-installments-option-description {
  @extend %caption-dark;
  @extend %font-regular;
  display: block;
  width: 300px;
}

.dropdown-installments-option-feature {
  color: palette('actions', 'warning');
}

.dropdown-installments-title {
  @extend %title-dark;
  margin: 0 0 1rem 0;
  padding: 0 1.5rem;
}

.dropdown-installments-icon {
  font-size: 1.8rem;
}

// Ng2-Dropdown Overrides
// -----------------------------------------------------------------------------

.ng2-dropdown-button {
  display: inline-block !important;
  min-width: 100% !important;
  width: 100% !important;
  max-width: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
  letter-spacing: normal !important;
  background-color: transparent !important;
  border: 10px !important;

  .dropdown-trigger-installments-icon {
    right: 0;
  }
}

.ng2-dropdown-button__caret {
  display: none !important;
}

.ng2-dropdown-menu {
  @media only screen and (max-width: 768px) {
    left: 0 !important;
    min-width: -webkit-fill-available;
  }

  min-width: 350px;
  z-index: 999 !important;
  min-height: auto !important;
  max-height: 88vh !important;
  padding: 0 !important;
  background-color: transparent !important;
  border-radius: 10px !important;
  box-shadow: none !important;

  .dropdown-trigger-installments-icon {
    right: 1.5rem;
  }

  &.ng2-dropdown-menu--open {
    width: auto !important;
    height: auto !important;
  }
}

.dropdown-menu_sair {
  margin: 1rem 0;
  width: -webkit-fill-available;
  button {
    min-height: var(--ids_size_general_2x);
    padding: var(--ids_size_general_5x) var(--ids_size_general_24x);
  }
}

.dropdown-menu_principal {
  background-color: var(--ids_color_bg_variant_01);

  .ng2-dropdown-menu--open {
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .dropdown-menu {
    background-color: var(--ids_color_bg_variant_01);

    .toolbar-actions-menu {
      height: 180px;
      background-color: palette('brand', 'mid-dark');
      color: palette('zgray', 'x-light');
    }

    .toolbar-actions-menu-item {
      padding-top: var(--padding-top-menu);
    }
  }

  .arrow {
    position: sticky;
    background-color: var(--ids_color_action_neutral_contrast);
    left: 0;
    width: 100%;
    z-index: 99;
  }

  .arrow-up {
    top: 0;
  }

  .arrow-down {
    bottom: 0;
  }
}

.ng2-menu-item {
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 10px;

  height: auto !important;
  padding: 0 !important;
  font-size: initial !important;
  letter-spacing: normal !important;
  line-height: normal !important;
  white-space: normal !important;
  background-color: transparent !important;
  overflow: visible !important;

  &--selected {
    background-color: transparent !important;
    outline: 0 !important;
  }
}

.ng2-dropdown-backdrop {
  position: fixed !important;
  z-index: 2 !important;
  background-color: palette('dark-ghost', 50);
  overflow: hidden !important;
}

// -----------------------------------------------------------------------------
