// Spinner
// -----------------------------------------------------------------------------

.spinner-inline {
  display: inline-block !important;
  vertical-align: middle;
  margin-right: 1rem;
}

.spinner-wrapper-fleft {
  float: left;
  vertical-align: middle;
}


// Spinner Colors
// -----------------------------------------------------------------------------

.spinner-xlight {
  svg circle {
    stroke: palette("zgray", "x-light") !important;
  }
}

.spinner-brand {
  svg circle {
    stroke: palette("brand", "mid-dark") !important;
  }
}

.spinner-dark {
  svg circle {
    stroke: palette("zbrown", "dark") !important;
  }
}


// -----------------------------------------------------------------------------
