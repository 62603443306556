// Report Graph Module
// -----------------------------------------------------------------------------

.report-graph {
  box-sizing: border-box;
}

// Slider
// -----------------------------------------------------------------------------

.report-graph-slider {
  padding: 2rem;
  background-color: palette('zbrown', dark);
  height: 370px;

  // Medium Devices
  @include tablet {
    height: 465px;
  }
}

.report-graph-slider-header {
  display: flex;
}

.report-graph-slider-previous {
  padding-left: 5px;
}

.report-graph-slider-next {
  padding-right: 5px;
}

.report-graph-slider-previous,
.report-graph-slider-next {
  background-color: palette('dark-ghost', 30);
  border: 0;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 20px;
  color: palette('zgray', 'x-light');
  font-size: 20px;
  margin-top: 0.5rem;
  outline: 0;
  cursor: pointer;
  transition: all 0.1s ease-in-out;

  &:hover {
    background-color: palette('dark-ghost', 50);
    color: palette('brand', 'mid-dark');
    transform: scale(1.1);
  }
}

.report-graph-slider-title {
  flex-grow: 1;
  margin: 0;
  text-align: center;
  transform: scale(0.8);

  small {
    @extend %body-xlight-sbold;
  }

  span {
    display: block;
    @extend %display-md-xlight;
  }

  // Medium Devices
  @include tablet {
    transform: scale(1);
  }
}

.report-graph-slider-body {
  display: flex;
  justify-content: center;
  margin: 0;
  margin-left: -40px;
  transform: scale(0.75);

  // Medium Devices
  @include tablet {
    margin: 2.5rem 0;
    margin-left: 0;
    transform: scale(1);
  }
}

.report-graph-slider-list {
  width: auto;
  list-style: none;
  margin: 0;
  padding: 0;
}

.report-graph-slider-list li {
  position: relative;
  display: flex;
  width: 100%;
  margin-bottom: 30px;
}

.report-graph-align-wrapper {
  display: table;
  height: 72px;
  box-sizing: border-box;

  &.first,
  &.second,
  &.third {
    margin-right: 40px;
    border-radius: 8px 0 0 8px;
    background-color: rgba(palette('zgray', 'x-dark'), 0.2);

    &::after {
      position: absolute;
      top: 0;
      margin-left: 0px;
      content: ' ';
      display: block;
      width: 27px;
      height: 72px;
      background-repeat: no-repeat;
      background-position: right;
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNyIgaGVpZ2h0PSI3MiIgdmlld0JveD0iMCAwIDI3IDcyIj4gIDxwYXRoIGZpbGwtb3BhY2l0eT0iLjIiIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTAuMDc0OTU3OTQxNSwwIEwyMC44NTA3NDI1LDAgQzI1LjI2NjM3OTYsMCAyNy44OTQ1MDMxLDMuNDQ4NDUzMTIgMjYuNzIwMDEzNCw3LjcwNTI2MTIzIEwxMS4xMDY0OTI3LDY0LjI5NDczODggQzkuOTMyMzY0Myw2OC41NTAyMzcgNS40MDUzOTE3NCw3MiAwLjk3ODE3MTM1OSw3MiBMMCw3MiBMMC4wNzQ5NTc5NDE1LDAgWiIvPjwvc3ZnPg==');
    }
  }

  &.first {
    width: 160px;
  }

  &.second {
    width: 135px;
  }

  &.third {
    width: 110px;
  }
}

.report-graph-slider-resume-a,
.report-graph-slider-resume-b {
  display: table-cell;
  vertical-align: middle;
}

.report-graph-slider-resume-a {
  padding-left: 1rem;

  b {
    display: block;
    @extend %title-xlight;
  }

  small {
    @extend %body-sm-xlight;
  }

  &.first {
    margin-left: 150px;
  }

  &.second {
    margin-left: 50px;
  }

  &.third {
    margin-left: 20px;
  }
}

.report-graph-slider-resume-b {
  line-height: 1.3rem;

  b {
    display: block;
    word-wrap: normal;
    @extend %body-basedark;
    @extend %font-regular;
  }

  b:before {
    content: ' ';
    display: inline-block;
    width: 4px;
    height: 4px;
    border-radius: 4px;
    position: relative;
    top: -2px;
    margin-right: 8px;
    border: 1px solid palette('zgray', 'mid-dark');
  }
}

// Notifications List
// -----------------------------------------------------------------------------

.report-graph-notification {
  background-color: palette('zgray', 'x-light');
  text-align: center;

  // Medium Devices
  @include tablet {
    height: 465px;
  }
}

// -----------------------------------------------------------------------------
