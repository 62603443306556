@import 'responsive';
// Tooltip Styles
// -----------------------------------------------------------------------------

.mat-tooltip {
  background-color: palette('zbrown', 'x-dark');
  -webkit-box-shadow: 0 0 10px palette('dark-ghost', 50);
  box-shadow: 0 0 10px palette('dark-ghost', 50);
  font-size: 0.8rem !important;

  &.mat-tooltip-multiline {
    height: auto !important;
    white-space: pre-line !important;
    width: 90px;
    text-align: left;
  }

  &.mat-tooltip-auto {
    width: auto !important;
  }
}

// Errors Tooltip
// -----------------------------------------------------------------------------

.tooltip-errors {
  position: relative;
  z-index: 99999;

  &:hover .tooltip-errors-table {
    display: inline-table;
    opacity: 1;
  }
}

.tooltip-errors-label {
  cursor: pointer;
}

.tooltip-errors-table-wrapper {
  position: absolute;
  top: 40px;
  left: 0;
  height: auto;
  min-width: 400px;
  @include for-phone {
    min-width: 296px;
    left: -120px;
  }
}

.tooltip-errors-table-wrapper-top {
  top: auto;
  bottom: 35px;

  .tooltip-errors-table:before {
    display: none;
  }

  .tooltip-errors-table:after {
    position: absolute;
    bottom: -10px;
    content: ' ';
    display: block;
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 15px solid palette('zgray', 'x-light');
    @include for-phone {
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      text-align: center;
    }
  }
}

.tooltip-errors-table {
  display: none;
  opacity: 0;
  background: palette('zgray', 'x-light');
  -webkit-box-shadow: 0 0 25px palette('zgray', 'base');
  box-shadow: 0 0 25px palette('zgray', 'base');
  border-radius: 5px;
  padding: 1rem;
  @include for-phone {
    width: 100%;
  }

  .table-row-text {
    font-size: 0.8rem;
    padding: 1rem 0.5rem;
  }

  &::before {
    position: absolute;
    top: -13px;
    content: ' ';
    display: block;
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid palette('zgray', 'x-light');
  }

  td {
    border-bottom: 1px solid palette('zgray', 'mid-light');
  }

  tr:first-child .table-row-text {
    padding-top: 0;
  }

  tr:last-child td,
  tr:last-child .table-row-text {
    border: 0;
    padding-bottom: 0;
  }
}

.tooltip-fixed {
  position: absolute;
  left: -92px;
  top: -25px;
  display: inline-block;
  border-bottom: 1px dotted #4c4643;
  z-index: 1;

  .tooltiptext {
    width: 207px;
    height: 39px;
    background-color: #4c4643;
    color: #fff;
    text-align: center;
    border-radius: 4px;
    padding: 12px 15px 12px 15px;
    z-index: 1;
    bottom: 150%;
    display: flex;
    align-items: center;
    font-size: 14px;

    &::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: #4c4643 transparent transparent transparent;
    }

    icon {
      position: absolute;
      right: 13px;
      margin-top: -2px;
    }
  }
}
