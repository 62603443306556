// Code
// -----------------------------------------------------------------------------

.code-container {
  max-height: 250px;
  padding: .5rem 1rem;
  margin-top: 1.5rem;
  border: 1px solid palette("zgray", "mid-light");
  border-radius: 4px;
  overflow: auto;
}


// -----------------------------------------------------------------------------
