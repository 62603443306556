@font-face {
  font-family: 'itau-icons';
  src:  url('fonts/itau-icons.eot?hsj040');
  src:  url('fonts/itau-icons.eot?hsj040#iefix') format('embedded-opentype'),
    url('fonts/itau-icons.ttf?hsj040') format('truetype'),
    url('fonts/itau-icons.woff?hsj040') format('woff'),
    url('fonts/itau-icons.svg?hsj040#itau-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.itau-icons {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'itau-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  
  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.itau-icons-horas:before {
  content: "\e900";
}
.itau-icons-account_filled:before {
  content: "\e901";
}
.itau-icons-account_outline:before {
  content: "\e902";
}
.itau-icons-add_filled:before {
  content: "\e903";
}
.itau-icons-add_outline:before {
  content: "\e904";
}
.itau-icons-add:before {
  content: "\e905";
}
.itau-icons-aimed_credit_filled:before {
  content: "\e906";
}
.itau-icons-aimed_credit_outline:before {
  content: "\e907";
}
.itau-icons-air_conditioning_filled:before {
  content: "\e908";
}
.itau-icons-air_conditioning_outline:before {
  content: "\e909";
}
.itau-icons-alert_filled:before {
  content: "\e90a";
}
.itau-icons-alert_outline:before {
  content: "\e90b";
}
.itau-icons-and:before {
  content: "\e90c";
}
.itau-icons-arrow_circle_down_filled:before {
  content: "\e90d";
}
.itau-icons-arrow_circle_down_outline:before {
  content: "\e90e";
}
.itau-icons-arrow_circle_left_filled:before {
  content: "\e90f";
}
.itau-icons-arrow_circle_left_outline:before {
  content: "\e910";
}
.itau-icons-arrow_circle_right_filled:before {
  content: "\e911";
}
.itau-icons-arrow_circle_right_outline:before {
  content: "\e912";
}
.itau-icons-arrow_circle_up_filled:before {
  content: "\e913";
}
.itau-icons-arrow_circle_up_outline:before {
  content: "\e914";
}
.itau-icons-arrow_downward:before {
  content: "\e915";
}
.itau-icons-arrow_upward:before {
  content: "\e916";
}
.itau-icons-art_filled:before {
  content: "\e917";
}
.itau-icons-art_outline:before {
  content: "\e918";
}
.itau-icons-at:before {
  content: "\e919";
}
.itau-icons-atm_filled:before {
  content: "\e91a";
}
.itau-icons-atm_outline:before {
  content: "\e91b";
}
.itau-icons-automated_investing_filled:before {
  content: "\e91c";
}
.itau-icons-automated_investing_outline:before {
  content: "\e91d";
}
.itau-icons-automated_payment_filled:before {
  content: "\e91e";
}
.itau-icons-automated_payment_outline:before {
  content: "\e91f";
}
.itau-icons-bar_graph_filled:before {
  content: "\e920";
}
.itau-icons-bar_graph_outline:before {
  content: "\e921";
}
.itau-icons-barcode_simple:before {
  content: "\e922";
}
.itau-icons-barcode:before {
  content: "\e923";
}
.itau-icons-bike_filled:before {
  content: "\e924";
}
.itau-icons-bike_outline:before {
  content: "\e925";
}
.itau-icons-building_filled:before {
  content: "\e926";
}
.itau-icons-building_insurance_filled:before {
  content: "\e927";
}
.itau-icons-building_insurance_outline:before {
  content: "\e928";
}
.itau-icons-building_outline:before {
  content: "\e929";
}
.itau-icons-caixa_shortcut:before {
  content: "\e92a";
}
.itau-icons-calculator:before {
  content: "\e92b";
}
.itau-icons-calendar_filled:before {
  content: "\e92c";
}
.itau-icons-calendar_outline:before {
  content: "\e92d";
}
.itau-icons-call_shortcut:before {
  content: "\e92e";
}
.itau-icons-car_filled:before {
  content: "\e92f";
}
.itau-icons-car_outline:before {
  content: "\e930";
}
.itau-icons-card_filled:before {
  content: "\e931";
}
.itau-icons-card_info_filled:before {
  content: "\e932";
}
.itau-icons-card_info_outline:before {
  content: "\e933";
}
.itau-icons-card_machine_filled:before {
  content: "\e934";
}
.itau-icons-card_machine_outline:before {
  content: "\e935";
}
.itau-icons-card_outline:before {
  content: "\e936";
}
.itau-icons-cards_filled:before {
  content: "\e937";
}
.itau-icons-cards_outline:before {
  content: "\e938";
}
.itau-icons-cash_register_filled:before {
  content: "\e939";
}
.itau-icons-cash_register_outline:before {
  content: "\e93a";
}
.itau-icons-certificate_filled:before {
  content: "\e93b";
}
.itau-icons-certificate_outline:before {
  content: "\e93c";
}
.itau-icons-chart_filled:before {
  content: "\e93d";
}
.itau-icons-chart_outline:before {
  content: "\e93e";
}
.itau-icons-chat_dots_filled:before {
  content: "\e93f";
}
.itau-icons-chat_dots_outline:before {
  content: "\e940";
}
.itau-icons-chat_filled:before {
  content: "\e941";
}
.itau-icons-chat_outline:before {
  content: "\e942";
}
.itau-icons-check_payment_filled:before {
  content: "\e943";
}
.itau-icons-check_payment_outline:before {
  content: "\e944";
}
.itau-icons-check_square_filled:before {
  content: "\e945";
}
.itau-icons-check_square_outline:before {
  content: "\e946";
}
.itau-icons-check:before {
  content: "\e947";
}
.itau-icons-checklist:before {
  content: "\e948";
}
.itau-icons-cicle:before {
  content: "\e949";
}
.itau-icons-circle_filled:before {
  content: "\e94a";
}
.itau-icons-circle_outline:before {
  content: "\e94b";
}
.itau-icons-close:before {
  content: "\e94c";
}
.itau-icons-code_filled:before {
  content: "\e94d";
}
.itau-icons-code_outline:before {
  content: "\e94e";
}
.itau-icons-coffee_filled:before {
  content: "\e94f";
}
.itau-icons-coffee_outline:before {
  content: "\e950";
}
.itau-icons-connections_filled:before {
  content: "\e951";
}
.itau-icons-connections_outline:before {
  content: "\e952";
}
.itau-icons-consigned_credit_filled:before {
  content: "\e953";
}
.itau-icons-consigned_credit_outline:before {
  content: "\e954";
}
.itau-icons-contacts_filled:before {
  content: "\e955";
}
.itau-icons-contacts_outline:before {
  content: "\e956";
}
.itau-icons-contract_filled:before {
  content: "\e957";
}
.itau-icons-contract_outline:before {
  content: "\e958";
}
.itau-icons-credit_balance_filled:before {
  content: "\e959";
}
.itau-icons-credit_balance_outline:before {
  content: "\e95a";
}
.itau-icons-credit_calendar_filled:before {
  content: "\e95b";
}
.itau-icons-credit_calendar_outline:before {
  content: "\e95c";
}
.itau-icons-credit_card_insurance_filled:before {
  content: "\e95d";
}
.itau-icons-credit_card_insurance_outline:before {
  content: "\e95e";
}
.itau-icons-credit_filled:before {
  content: "\e95f";
}
.itau-icons-credit_insurance_filled:before {
  content: "\e960";
}
.itau-icons-credit_insurance_outline:before {
  content: "\e961";
}
.itau-icons-credit_outline:before {
  content: "\e962";
}
.itau-icons-credit_shortcut:before {
  content: "\e963";
}
.itau-icons-debit_filled:before {
  content: "\e964";
}
.itau-icons-debit_outline:before {
  content: "\e965";
}
.itau-icons-delete_filled:before {
  content: "\e966";
}
.itau-icons-delete_outline:before {
  content: "\e967";
}
.itau-icons-desktop_filled:before {
  content: "\e968";
}
.itau-icons-desktop_outline:before {
  content: "\e969";
}
.itau-icons-digital_filled:before {
  content: "\e96a";
}
.itau-icons-digital_outline:before {
  content: "\e96b";
}
.itau-icons-digital_payment_filled:before {
  content: "\e96c";
}
.itau-icons-digital_payment_outline:before {
  content: "\e96d";
}
.itau-icons-discount_filled:before {
  content: "\e96e";
}
.itau-icons-discount_outline:before {
  content: "\e96f";
}
.itau-icons-document_filled:before {
  content: "\e970";
}
.itau-icons-document_outline:before {
  content: "\e971";
}
.itau-icons-double_check:before {
  content: "\e972";
}
.itau-icons-download:before {
  content: "\e973";
}
.itau-icons-drop_filled:before {
  content: "\e974";
}
.itau-icons-drop_outline:before {
  content: "\e975";
}
.itau-icons-ecology_filled:before {
  content: "\e976";
}
.itau-icons-ecology_outline:before {
  content: "\e977";
}
.itau-icons-edit_filled:before {
  content: "\e978";
}
.itau-icons-edit_outline:before {
  content: "\e979";
}
.itau-icons-education_filled:before {
  content: "\e97a";
}
.itau-icons-education_outline:before {
  content: "\e97b";
}
.itau-icons-ellipse:before {
  content: "\e97c";
}
.itau-icons-email_filled:before {
  content: "\e97d";
}
.itau-icons-email_outline:before {
  content: "\e97e";
}
.itau-icons-excel_filled:before {
  content: "\e97f";
}
.itau-icons-excel_outline:before {
  content: "\e980";
}
.itau-icons-exchange_filled:before {
  content: "\e981";
}
.itau-icons-exchange_outline:before {
  content: "\e982";
}
.itau-icons-facebook_filled:before {
  content: "\e983";
}
.itau-icons-facebook_outline:before {
  content: "\e984";
}
.itau-icons-favourite_location_outline:before {
  content: "\e985";
}
.itau-icons-filter_filled:before {
  content: "\e986";
}
.itau-icons-filter_outline:before {
  content: "\e987";
}
.itau-icons-finance_shortcut:before {
  content: "\e988";
}
.itau-icons-folder_filled:before {
  content: "\e989";
}
.itau-icons-folder_outline:before {
  content: "\e98a";
}
.itau-icons-food_filled:before {
  content: "\e98b";
}
.itau-icons-food_outline:before {
  content: "\e98c";
}
.itau-icons-happy_face_filled:before {
  content: "\e98d";
}
.itau-icons-happy_face_outline:before {
  content: "\e98e";
}
.itau-icons-health_filled:before {
  content: "\e98f";
}
.itau-icons-health_insurance_filled:before {
  content: "\e990";
}
.itau-icons-health_insurance_outline:before {
  content: "\e991";
}
.itau-icons-health_outline:before {
  content: "\e992";
}
.itau-icons-healthcare_filled:before {
  content: "\e993";
}
.itau-icons-healthcare_outline:before {
  content: "\e994";
}
.itau-icons-heart_filled:before {
  content: "\e995";
}
.itau-icons-heart_outline:before {
  content: "\e996";
}
.itau-icons-help_filled:before {
  content: "\e997";
}
.itau-icons-help_outline:before {
  content: "\e998";
}
.itau-icons-home_filled:before {
  content: "\e999";
}
.itau-icons-home_insurance_filled:before {
  content: "\e99a";
}
.itau-icons-home_insurance_outline:before {
  content: "\e99b";
}
.itau-icons-home_outline:before {
  content: "\e99c";
}
.itau-icons-housing_credit_filled:before {
  content: "\e99d";
}
.itau-icons-housing_credit_outline:before {
  content: "\e99e";
}
.itau-icons-housing_finance_filled:before {
  content: "\e99f";
}
.itau-icons-housing_finance_outline:before {
  content: "\e9a0";
}
.itau-icons-idea_filled:before {
  content: "\e9a1";
}
.itau-icons-idea_outline:before {
  content: "\e9a2";
}
.itau-icons-image_filled:before {
  content: "\e9a3";
}
.itau-icons-image_outline:before {
  content: "\e9a4";
}
.itau-icons-info_filled:before {
  content: "\e9a5";
}
.itau-icons-info_outline:before {
  content: "\e9a6";
}
.itau-icons-inss:before {
  content: "\e9a7";
}
.itau-icons-instagram_filled:before {
  content: "\e9a8";
}
.itau-icons-instagram_outline:before {
  content: "\e9a9";
}
.itau-icons-installment_insurance_filled:before {
  content: "\e9aa";
}
.itau-icons-installment_insurance_outline:before {
  content: "\e9ab";
}
.itau-icons-insurance:before {
  content: "\e9ac";
}
.itau-icons-investments_filled:before {
  content: "\e9ad";
}
.itau-icons-investments_outline:before {
  content: "\e9ae";
}
.itau-icons-ir:before {
  content: "\e9af";
}
.itau-icons-iso:before {
  content: "\e9b0";
}
.itau-icons-iToken_filled:before {
  content: "\e9b1";
}
.itau-icons-iToken_message_filled:before {
  content: "\e9b2";
}
.itau-icons-iToken_message_outline:before {
  content: "\e9b3";
}
.itau-icons-iToken_outline:before {
  content: "\e9b4";
}
.itau-icons-iToken_signal_filled:before {
  content: "\e9b5";
}
.itau-icons-iToken_signal_outline:before {
  content: "\e9b6";
}
.itau-icons-juridic_filled:before {
  content: "\e9b7";
}
.itau-icons-juridic_insurance_filled:before {
  content: "\e9b8";
}
.itau-icons-juridic_insurance_outline:before {
  content: "\e9b9";
}
.itau-icons-juridic_outline:before {
  content: "\e9ba";
}
.itau-icons-juridical_person_filled:before {
  content: "\e9bb";
}
.itau-icons-juridical_person_outline:before {
  content: "\e9bc";
}
.itau-icons-key_filled:before {
  content: "\e9bd";
}
.itau-icons-key_insurance_filled:before {
  content: "\e9be";
}
.itau-icons-key_insurance_outline:before {
  content: "\e9bf";
}
.itau-icons-key_outline:before {
  content: "\e9c0";
}
.itau-icons-keyboard_arrow_down_flat:before {
  content: "\e9c1";
}
.itau-icons-keyboard_arrow_down:before {
  content: "\e9c2";
}
.itau-icons-keyboard_arrow_left:before {
  content: "\e9c3";
}
.itau-icons-keyboard_arrow_right:before {
  content: "\e9c4";
}
.itau-icons-keyboard_arrow_up_flat:before {
  content: "\e9c5";
}
.itau-icons-keyboard_arrow_up:before {
  content: "\e9c6";
}
.itau-icons-kid_insurance_filled:before {
  content: "\e9c7";
}
.itau-icons-kid_insurance_outline:before {
  content: "\e9c8";
}
.itau-icons-lego_filled:before {
  content: "\e9c9";
}
.itau-icons-lego_outline:before {
  content: "\e9ca";
}
.itau-icons-loading_filled:before {
  content: "\e9cb";
}
.itau-icons-loading_outline:before {
  content: "\e9cc";
}
.itau-icons-loan_filled:before {
  content: "\e9cd";
}
.itau-icons-loan_outline:before {
  content: "\e9ce";
}
.itau-icons-location_on_filled:before {
  content: "\e9cf";
}
.itau-icons-location_on_outline:before {
  content: "\e9d0";
}
.itau-icons-lock_desktop_filled:before {
  content: "\e9d1";
}
.itau-icons-lock_desktop_outline:before {
  content: "\e9d2";
}
.itau-icons-lock_document_filled:before {
  content: "\e9d3";
}
.itau-icons-lock_document_outline:before {
  content: "\e9d4";
}
.itau-icons-lock_filled:before {
  content: "\e9d5";
}
.itau-icons-lock_key_filled:before {
  content: "\e9d6";
}
.itau-icons-lock_key_outline:before {
  content: "\e9d7";
}
.itau-icons-lock_open_filled:before {
  content: "\e9d8";
}
.itau-icons-lock_open_outline:before {
  content: "\e9d9";
}
.itau-icons-lock_outline:before {
  content: "\e9da";
}
.itau-icons-manager_filled:before {
  content: "\e9db";
}
.itau-icons-manager_outline:before {
  content: "\e9dc";
}
.itau-icons-manager_shortcut_filled:before {
  content: "\e9dd";
}
.itau-icons-medicine_filled:before {
  content: "\e9de";
}
.itau-icons-medicine_outline:before {
  content: "\e9df";
}
.itau-icons-menu:before {
  content: "\e9e0";
}
.itau-icons-microphone_filled:before {
  content: "\e9e1";
}
.itau-icons-microphone_outline:before {
  content: "\e9e2";
}
.itau-icons-money_circle_filled:before {
  content: "\e9e3";
}
.itau-icons-money_circle_outline:before {
  content: "\e9e4";
}
.itau-icons-money_transaction:before {
  content: "\e9e5";
}
.itau-icons-money:before {
  content: "\e9e6";
}
.itau-icons-more_credit_filled:before {
  content: "\e9e7";
}
.itau-icons-more_credit_outline:before {
  content: "\e9e8";
}
.itau-icons-more_horiz:before {
  content: "\e9e9";
}
.itau-icons-movie_filled:before {
  content: "\e9ea";
}
.itau-icons-movie_outline:before {
  content: "\e9eb";
}
.itau-icons-neew_card_filled:before {
  content: "\e9ec";
}
.itau-icons-negotiation:before {
  content: "\e9ed";
}
.itau-icons-new_card_outline:before {
  content: "\e9ee";
}
.itau-icons-news_filled:before {
  content: "\e9ef";
}
.itau-icons-news_outline:before {
  content: "\e9f0";
}
.itau-icons-notification_filled:before {
  content: "\e9f1";
}
.itau-icons-notification_outline:before {
  content: "\e9f2";
}
.itau-icons-options_filled:before {
  content: "\e9f3";
}
.itau-icons-options_insurance_filled:before {
  content: "\e9f4";
}
.itau-icons-options_insurance_outline:before {
  content: "\e9f5";
}
.itau-icons-options_outline:before {
  content: "\e9f6";
}
.itau-icons-package_delivery_filled:before {
  content: "\e9f7";
}
.itau-icons-package_delivery_outline:before {
  content: "\e9f8";
}
.itau-icons-pager_filled:before {
  content: "\e9f9";
}
.itau-icons-pager_outline:before {
  content: "\e9fa";
}
.itau-icons-part_filled:before {
  content: "\e9fb";
}
.itau-icons-part_outline:before {
  content: "\e9fc";
}
.itau-icons-partners_filled:before {
  content: "\e9fd";
}
.itau-icons-partners_outline:before {
  content: "\e9fe";
}
.itau-icons-payment_filled:before {
  content: "\e9ff";
}
.itau-icons-payment_outline:before {
  content: "\ea00";
}
.itau-icons-pdf_filled:before {
  content: "\ea01";
}
.itau-icons-pdf_outline:before {
  content: "\ea02";
}
.itau-icons-people_filled:before {
  content: "\ea03";
}
.itau-icons-people_outline:before {
  content: "\ea04";
}
.itau-icons-percentage:before {
  content: "\ea05";
}
.itau-icons-personal_insurance_filled:before {
  content: "\ea06";
}
.itau-icons-personal_insurance_outline:before {
  content: "\ea07";
}
.itau-icons-personal_portability_filled:before {
  content: "\ea08";
}
.itau-icons-personal_portability_outline:before {
  content: "\ea09";
}
.itau-icons-pet_filled:before {
  content: "\ea0a";
}
.itau-icons-pet_insurance_filled:before {
  content: "\ea0b";
}
.itau-icons-pet_insurance_outline:before {
  content: "\ea0c";
}
.itau-icons-pet_outline:before {
  content: "\ea0d";
}
.itau-icons-phone_filled:before {
  content: "\ea0e";
}
.itau-icons-phone_outline:before {
  content: "\ea0f";
}
.itau-icons-pizza_graph_filled:before {
  content: "\ea10";
}
.itau-icons-pizza_graph_outline:before {
  content: "\ea11";
}
.itau-icons-play_filled:before {
  content: "\ea12";
}
.itau-icons-play_outline:before {
  content: "\ea13";
}
.itau-icons-portability_filled:before {
  content: "\ea14";
}
.itau-icons-portability_outline:before {
  content: "\ea15";
}
.itau-icons-print_filled:before {
  content: "\ea16";
}
.itau-icons-print_outline:before {
  content: "\ea17";
}
.itau-icons-prize_filled:before {
  content: "\ea18";
}
.itau-icons-prize_outline:before {
  content: "\ea19";
}
.itau-icons-progress_bar_filled:before {
  content: "\ea1a";
}
.itau-icons-progress_bar_outline:before {
  content: "\ea1b";
}
.itau-icons-publish_filled:before {
  content: "\ea1c";
}
.itau-icons-radio_filled:before {
  content: "\ea1d";
}
.itau-icons-radio_outline:before {
  content: "\ea1e";
}
.itau-icons-receipt_filled:before {
  content: "\ea1f";
}
.itau-icons-receipt_outline:before {
  content: "\ea20";
}
.itau-icons-rectangle_filled:before {
  content: "\ea21";
}
.itau-icons-rectangle_outline:before {
  content: "\ea22";
}
.itau-icons-refresh:before {
  content: "\ea23";
}
.itau-icons-refund_filled:before {
  content: "\ea24";
}
.itau-icons-refund_outline:before {
  content: "\ea25";
}
.itau-icons-save_filled:before {
  content: "\ea26";
}
.itau-icons-save_outline:before {
  content: "\ea27";
}
.itau-icons-savings_filled:before {
  content: "\ea28";
}
.itau-icons-savings_outline:before {
  content: "\ea29";
}
.itau-icons-search_filled:before {
  content: "\ea2a";
}
.itau-icons-search_outline:before {
  content: "\ea2b";
}
.itau-icons-send_filled:before {
  content: "\ea2c";
}
.itau-icons-send_outline:before {
  content: "\ea2d";
}
.itau-icons-services_filled:before {
  content: "\ea2e";
}
.itau-icons-services_outline:before {
  content: "\ea2f";
}
.itau-icons-settings_filled:before {
  content: "\ea30";
}
.itau-icons-settings_outlined:before {
  content: "\ea31";
}
.itau-icons-share_filled:before {
  content: "\ea32";
}
.itau-icons-share_outline:before {
  content: "\ea33";
}
.itau-icons-shield_filled:before {
  content: "\ea34";
}
.itau-icons-shield_outline:before {
  content: "\ea35";
}
.itau-icons-ship_filled:before {
  content: "\ea36";
}
.itau-icons-ship_insurance_filled:before {
  content: "\ea37";
}
.itau-icons-ship_insurance_outline:before {
  content: "\ea38";
}
.itau-icons-ship_outline:before {
  content: "\ea39";
}
.itau-icons-shirt_10_filled:before {
  content: "\ea3a";
}
.itau-icons-shirt_10_outline:before {
  content: "\ea3b";
}
.itau-icons-shirt_filled:before {
  content: "\ea3c";
}
.itau-icons-shirt_outline:before {
  content: "\ea3d";
}
.itau-icons-shopping_cart_filled:before {
  content: "\ea3e";
}
.itau-icons-shopping_cart_outline:before {
  content: "\ea3f";
}
.itau-icons-shopping_filled:before {
  content: "\ea40";
}
.itau-icons-shopping_outline:before {
  content: "\ea41";
}
.itau-icons-shortcut_filled:before {
  content: "\ea42";
}
.itau-icons-shortcut_outline:before {
  content: "\ea43";
}
.itau-icons-smartphone_add_filled:before {
  content: "\ea44";
}
.itau-icons-smartphone_add_outline:before {
  content: "\ea45";
}
.itau-icons-smartphone_apps_filled:before {
  content: "\ea46";
}
.itau-icons-smartphone_apps_outline:before {
  content: "\ea47";
}
.itau-icons-smartphone_check_filled:before {
  content: "\ea48";
}
.itau-icons-smartphone_check_outline:before {
  content: "\ea49";
}
.itau-icons-smartphone_filled:before {
  content: "\ea4a";
}
.itau-icons-smartphone_outline:before {
  content: "\ea4b";
}
.itau-icons-smoking_filled:before {
  content: "\ea4c";
}
.itau-icons-smoking_outline:before {
  content: "\ea4d";
}
.itau-icons-solutions_filled:before {
  content: "\ea4e";
}
.itau-icons-solutions_outline:before {
  content: "\ea4f";
}
.itau-icons-sport_filled:before {
  content: "\ea50";
}
.itau-icons-sport_outline:before {
  content: "\ea51";
}
.itau-icons-square_filled:before {
  content: "\ea52";
}
.itau-icons-square_outline:before {
  content: "\ea53";
}
.itau-icons-star_filled:before {
  content: "\ea54";
}
.itau-icons-star_outline:before {
  content: "\ea55";
}
.itau-icons-stock:before {
  content: "\ea56";
}
.itau-icons-subtract_outline:before {
  content: "\ea57";
}
.itau-icons-sustainability_filled:before {
  content: "\ea58";
}
.itau-icons-sustainability_outline:before {
  content: "\ea59";
}
.itau-icons-tablet_filled:before {
  content: "\ea5a";
}
.itau-icons-tablet_outline:before {
  content: "\ea5b";
}
.itau-icons-target_filled:before {
  content: "\ea5c";
}
.itau-icons-target_outline:before {
  content: "\ea5d";
}
.itau-icons-text_filled:before {
  content: "\ea5e";
}
.itau-icons-three_way:before {
  content: "\ea5f";
}
.itau-icons-thumb_down_filled:before {
  content: "\ea60";
}
.itau-icons-thumb_down_outline:before {
  content: "\ea61";
}
.itau-icons-thumb_up_filled:before {
  content: "\ea62";
}
.itau-icons-thumb_up_outline:before {
  content: "\ea63";
}
.itau-icons-ticket_filled:before {
  content: "\ea64";
}
.itau-icons-ticket_outline:before {
  content: "\ea65";
}
.itau-icons-time_filled:before {
  content: "\ea66";
}
.itau-icons-time_outline:before {
  content: "\ea67";
}
.itau-icons-toggle_filled:before {
  content: "\ea68";
}
.itau-icons-toggle_outline:before {
  content: "\ea69";
}
.itau-icons-tool_filled:before {
  content: "\ea6a";
}
.itau-icons-tool_outline:before {
  content: "\ea6b";
}
.itau-icons-transfer:before {
  content: "\ea6c";
}
.itau-icons-travel_filled:before {
  content: "\ea6d";
}
.itau-icons-travel_insurance_filled:before {
  content: "\ea6e";
}
.itau-icons-travel_insurance_outline:before {
  content: "\ea6f";
}
.itau-icons-travel_outline:before {
  content: "\ea70";
}
.itau-icons-tv:before {
  content: "\ea71";
}
.itau-icons-twitter_filled:before {
  content: "\ea72";
}
.itau-icons-twitter_outline:before {
  content: "\ea73";
}
.itau-icons-two_way:before {
  content: "\ea74";
}
.itau-icons-union_filled:before {
  content: "\ea75";
}
.itau-icons-union_outline:before {
  content: "\ea76";
}
.itau-icons-upload:before {
  content: "\ea77";
}
.itau-icons-vacation_filled:before {
  content: "\ea78";
}
.itau-icons-vacation_outline:before {
  content: "\ea79";
}
.itau-icons-vehicle_credit_filled:before {
  content: "\ea7a";
}
.itau-icons-vehicle_credit_outline:before {
  content: "\ea7b";
}
.itau-icons-vehicle_financing_filled:before {
  content: "\ea7c";
}
.itau-icons-vehicle_financing_outline:before {
  content: "\ea7d";
}
.itau-icons-vehicle_insurance_filled:before {
  content: "\ea7e";
}
.itau-icons-vehicle_insurance_outline:before {
  content: "\ea7f";
}
.itau-icons-visibility_filled:before {
  content: "\ea80";
}
.itau-icons-visibility_off_filled:before {
  content: "\ea81";
}
.itau-icons-visibility_off_outline:before {
  content: "\ea82";
}
.itau-icons-visibility_outline:before {
  content: "\ea83";
}
.itau-icons-wallet_filled:before {
  content: "\ea84";
}
.itau-icons-wallet_outline:before {
  content: "\ea85";
}
.itau-icons-wardrobe_filled:before {
  content: "\ea86";
}
.itau-icons-wardrobe_outline:before {
  content: "\ea87";
}
.itau-icons-warning_filled:before {
  content: "\ea88";
}
.itau-icons-warning_outline:before {
  content: "\ea89";
}
.itau-icons-wifi:before {
  content: "\ea8a";
}
.itau-icons-wing_filled:before {
  content: "\ea8b";
}
.itau-icons-wing_outline:before {
  content: "\ea8c";
}
.itau-icons-winking_face_filled:before {
  content: "\ea8d";
}
.itau-icons-winking_face_outline:before {
  content: "\ea8e";
}
.itau-icons-word_filled:before {
  content: "\ea8f";
}
.itau-icons-word_outline:before {
  content: "\ea90";
}
.itau-icons-world_filled:before {
  content: "\ea91";
}
.itau-icons-world_outline:before {
  content: "\ea92";
}
.itau-icons-accessibility_filled:before {
  content: "\ea93";
}
.itau-icons-accessibility_outline:before {
  content: "\ea94";
}
.itau-icons-add_alt:before {
  content: "\ea95";
}
.itau-icons-arrow_down:before {
  content: "\ea96";
}
.itau-icons-arrow_up:before {
  content: "\ea97";
}
.itau-icons-bike_alt_outline:before {
  content: "\ea98";
}
.itau-icons-building_favorite_filled:before {
  content: "\eaaf";
}
.itau-icons-building_favorite_outline:before {
  content: "\eab0";
}
.itau-icons-bus_filled:before {
  content: "\ea99";
}
.itau-icons-bus_outline:before {
  content: "\ea9a";
}
.itau-icons-car_alt_filled:before {
  content: "\eae1";
}
.itau-icons-car_alt_outline:before {
  content: "\eae0";
}
.itau-icons-cards_alt_filled:before {
  content: "\ea9b";
}
.itau-icons-cards_alt_outline:before {
  content: "\ea9c";
}
.itau-icons-challenge_filled:before {
  content: "\ea9d";
}
.itau-icons-challenge_outline:before {
  content: "\ea9e";
}
.itau-icons-check_alt:before {
  content: "\eaa0";
}
.itau-icons-check_circle:before {
  content: "\eaa1";
}
.itau-icons-check_square_empty_outline:before {
  content: "\eaa2";
}
.itau-icons-checklist_filled:before {
  content: "\eacf";
}
.itau-icons-chip_filled:before {
  content: "\eaa3";
}
.itau-icons-chip_outline:before {
  content: "\eaa4";
}
.itau-icons-copy_filled:before {
  content: "\eaa5";
}
.itau-icons-copy_outline:before {
  content: "\eaa6";
}
.itau-icons-csv_filled:before {
  content: "\eaa7";
}
.itau-icons-csv_outline:before {
  content: "\eaa8";
}
.itau-icons-diary_filled:before {
  content: "\eaa9";
}
.itau-icons-diary_outline:before {
  content: "\eaaa";
}
.itau-icons-digital_wallet_filled:before {
  content: "\eaab";
}
.itau-icons-digital_wallet_outline:before {
  content: "\eaac";
}
.itau-icons-euro:before {
  content: "\eaad";
}
.itau-icons-external_link_filled:before {
  content: "\eaae";
}
.itau-icons-external_link_outline:before {
  content: "\eab1";
}
.itau-icons-face_id:before {
  content: "\eab4";
}
.itau-icons-favourite_location_filled:before {
  content: "\eab5";
}
.itau-icons-filter_alt_filled:before {
  content: "\eab7";
}
.itau-icons-filter_alt_outline:before {
  content: "\eab8";
}
.itau-icons-fingerprint:before {
  content: "\eab9";
}
.itau-icons-five:before {
  content: "\eaba";
}
.itau-icons-flash_disabled_filled:before {
  content: "\eabb";
}
.itau-icons-flash_disabled_outline:before {
  content: "\eabc";
}
.itau-icons-flash_enabled_filled:before {
  content: "\eabd";
}
.itau-icons-flash_enabled_outline:before {
  content: "\eabe";
}
.itau-icons-fraud_insurance_filled:before {
  content: "\eabf";
}
.itau-icons-fraud_insurance_outline:before {
  content: "\eac0";
}
.itau-icons-gift_filled:before {
  content: "\eac1";
}
.itau-icons-gift_outillne:before {
  content: "\eac2";
}
.itau-icons-hair_dryer_filled:before {
  content: "\eac3";
}
.itau-icons-hair_dryer_outline:before {
  content: "\eac4";
}
.itau-icons-health_alt_filled:before {
  content: "\eaeb";
}
.itau-icons-health_alt_outline:before {
  content: "\eae8";
}
.itau-icons-help_alt_filled:before {
  content: "\eac5";
}
.itau-icons-help_alt_outline:before {
  content: "\eac6";
}
.itau-icons-id_filled:before {
  content: "\eac7";
}
.itau-icons-id_outline:before {
  content: "\eac8";
}
.itau-icons-invoice_filled:before {
  content: "\eac9";
}
.itau-icons-invoice_outline:before {
  content: "\eaca";
}
.itau-icons-itau_pass_filled:before {
  content: "\eacb";
}
.itau-icons-itau_pass_outline:before {
  content: "\eacc";
}
.itau-icons-metro_filled:before {
  content: "\eacd";
}
.itau-icons-metro_outline:before {
  content: "\eace";
}
.itau-icons-motorcycle_outline:before {
  content: "\ead1";
}
.itau-icons-nfc:before {
  content: "\ead2";
}
.itau-icons-open_banking_filled:before {
  content: "\ead3";
}
.itau-icons-open_banking_outline:before {
  content: "\ead4";
}
.itau-icons-pause_filled:before {
  content: "\ead5";
}
.itau-icons-pause_outline:before {
  content: "\ead6";
}
.itau-icons-phone_insurance_filled:before {
  content: "\ead7";
}
.itau-icons-phone_insurance_outline:before {
  content: "\ead8";
}
.itau-icons-pix_filled:before {
  content: "\ead9";
}
.itau-icons-pix_outline:before {
  content: "\eada";
}
.itau-icons-positive_registration:before {
  content: "\eadb";
}
.itau-icons-printer_filled:before {
  content: "\eadc";
}
.itau-icons-printer_outline:before {
  content: "\eadd";
}
.itau-icons-qr_code_filled:before {
  content: "\eade";
}
.itau-icons-qr_code_sent_filled:before {
  content: "\eadf";
}
.itau-icons-qr_code_sent_outline:before {
  content: "\eae2";
}
.itau-icons-radio_button_filled:before {
  content: "\eae3";
}
.itau-icons-radio_button_outline:before {
  content: "\eae4";
}
.itau-icons-recharge:before {
  content: "\eae5";
}
.itau-icons-reply_filled:before {
  content: "\eae6";
}
.itau-icons-reply_outline:before {
  content: "\eae7";
}
.itau-icons-reversal_filled:before {
  content: "\eae9";
}
.itau-icons-reversal_outline:before {
  content: "\eaea";
}
.itau-icons-ship_alt_filled:before {
  content: "\eaee";
}
.itau-icons-ship_alt_outline:before {
  content: "\eaef";
}
.itau-icons-sign_out_outline:before {
  content: "\eaf0";
}
.itau-icons-store_filled:before {
  content: "\eaf1";
}
.itau-icons-store_outline:before {
  content: "\eaf2";
}
.itau-icons-subtract_alt_filled:before {
  content: "\eaf3";
}
.itau-icons-subtract_alt_outline:before {
  content: "\eaf4";
}
.itau-icons-subtract_filled:before {
  content: "\eaf5";
}
.itau-icons-swipe:before {
  content: "\eaff";
}
.itau-icons-teeth_outline:before {
  content: "\eafe";
}
.itau-icons-time_alt_filled:before {
  content: "\eafd";
}
.itau-icons-time_alt_outline:before {
  content: "\eafc";
}
.itau-icons-truck_alt_outline:before {
  content: "\eafb";
}
.itau-icons-truck_outline:before {
  content: "\eafa";
}
.itau-icons-txt_filled:before {
  content: "\eaf9";
}
.itau-icons-txt_outline:before {
  content: "\eaf8";
}
.itau-icons-whatsapp_filled:before {
  content: "\eaf7";
}
.itau-icons-whatsapp_outline:before {
  content: "\eaf6";
}
