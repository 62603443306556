// Datepicker Styles
// -----------------------------------------------------------------------------

.datepicker {
  height: 74px;
  padding: 1rem 0;
  background-color: palette('zgray', 'x-light');
  box-sizing: border-box;
}

.datepicker-trigger {
  @extend .btnIcon;
  @include itauicons('\e92d');
  float: left;
  margin-right: 0.5rem;
  background: transparent !important;
}

.datepicker-separator {
  display: block;
  width: 3px;
  height: 3px;
  background-color: palette('zgray', 'light');
  border-radius: 3px;
}

.datepicker-date {
  @extend %body-dark-sbold;
  border: 0;
  width: 80px;
}

.datepicker-range-label {
  @extend %caption-dark;
  text-transform: lowercase;
  margin-left: 3px;
  margin-bottom: 3px;
}

.datepicker-periods {
  height: 42px;
  line-height: 42px;
}

// DatePicker Range X-Light
// -----------------------------------------------------------------------------

.datepicker-range-xlight {
  padding-bottom: 5px;
  border-bottom: 1px solid palette('zgray', 'light');

  .datepicker-trigger,
  .datepicker-range-label {
    color: palette('zgray', 'x-light');
  }

  .datepicker-range-label {
    margin: 0;
    margin-bottom: 0.5rem;
  }

  .datepicker-trigger {
    font-size: 1.3rem;
    margin-left: -4px;
  }

  .datepicker-trigger:hover {
    color: palette('brand', 'mid-dark');
  }

  .datepicker-date {
    background: transparent;
    color: palette('zgray', 'x-light');
  }
}

// -----------------------------------------------------------------------------
