// Watermark
// -----------------------------------------------------------------------------

.watermark {
  width: 100%;
  height: 23rem;
  padding: 7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;

  .icon {
    font-size: 5rem;
    color: palette('zbrown', 'dark');
    height: 7rem;
    width: 7rem;
    line-height: 7rem;
  }

  .watermark_text {
    font-size: 24px;
    text-align: center;
    color: palette('zbrown', 'dark');
  }
}

// -----------------------------------------------------------------------------
