// Typography
// -----------------------------------------------------------------------------

/*------------------------------------------------------------------------------
# Base Placeholders
# Display
# Headline
# Text Contained
# Title
# Subtitle
# Body 1
# Body 2
# Body 3
# Body 4
# Caption
# Paragraph
# State Colors
------------------------------------------------------------------------------*/

// Base Placeholders
// -----------------------------------------------------------------------------

%title-size {
  font-size: 1.25rem;
  line-height: 1.25rem;
}

%subtitle-size {
  font-size: 0.875rem;
  line-height: 1rem;
}

%body-size {
  font-size: 0.875rem;
  line-height: 1rem;
}

%caption-size {
  font-size: 0.75rem;
  line-height: 1rem;
}

// Display
// -----------------------------------------------------------------------------

.display-sm-dark,
%display-sm-dark {
  @extend %font-xbold;
  font-size: 1.3rem;
  color: palette('zbrown', 'dark');
}

.display-sm-xdark,
%display-sm-xdark {
  @extend %font-xbold;
  font-size: 1.3rem;
  color: palette('zbrown', 'x-dark');
}

.display-sm-light,
%display-sm-light {
  @extend %font-light;
  font-size: 1.3rem;
}

.display-sm-xlight,
%display-sm-xlight {
  @extend %font-xbold;
  font-size: 1.3rem;
  color: palette('zgray', 'x-light');
}

.display-md-dark,
%display-md-dark {
  @extend %font-xbold;
  color: palette('zbrown', 'dark');
  font-size: 2rem;
}

.display-md-xdark,
%display-md-xdark {
  @extend %font-xbold;
  color: palette('zbrown', 'x-dark');
  font-size: 2rem;
}

.display-md-light,
%display-md-light {
  @extend %font-light;
  font-size: 2rem;
}

.display-md-xlight,
%display-md-xlight {
  @extend %font-xbold;
  color: palette('zgray', 'x-light');
  font-size: 2rem;
}

.display-lg-dark,
%display-lg-dark {
  @extend %font-xbold;
  color: palette('zbrown', 'dark');
  font-size: 2.815rem;
}

.display-lg-xlight,
%display-lg-xlight {
  @extend %font-xbold;
  color: palette('zgray', 'x-light');
  font-size: 2.815rem;
}

.display-sm-xdark,
%display-sm-xdark {
  @extend %font-xbold;
  font-size: 1.3rem;
  color: palette('zbrown', 'x-dark');
}

.display-sm-dark,
%display-sm-dark {
  @extend %font-xbold;
  font-size: 1.3rem;
  color: palette('zbrown', 'dark');
}

.display-sm-xlight,
%display-sm-xlight {
  @extend %font-xbold;
  font-size: 1.3rem;
  color: palette('zgray', 'x-light');
}

.display-sm-light,
%display-sm-light {
  @extend %font-light;
  font-size: 1.3rem;
}

// Headline
// -----------------------------------------------------------------------------

.headline-dark,
%headline-dark {
  @extend %font-xbold;
  @extend %title-size;
}

.headline-dark,
.headline-dark .zf-icon {
  color: palette('zbrown', 'dark') !important;
}

.headline-middark,
%headline-middark {
  @extend %font-xbold;
  @extend %title-size;
  color: palette('zbrown', 'mid-dark');
}

.headline-light,
%headline-light {
  @extend %font-light;
  @extend %title-size;
}

.headline-xlight,
%headline-xlight {
  @extend %font-xbold;
  @extend %title-size;
  color: palette('zgray', 'x-light');
}

.headline-xlight-sbold,
%headline-xlight-sbold {
  @extend %font-bold;
  @extend %title-size;
  color: palette('zgray', 'x-light');
}

// Text Contained
// -----------------------------------------------------------------------------

.text-contained,
%text-contained {
  margin: 0;
  padding: 0;
}

// Title
// -----------------------------------------------------------------------------

.title-xxdark,
%title-xxdark {
  @extend %font-xbold;
  @extend %title-size;
  color: palette('zbrown', 'xx-dark');
}

.title-xdark,
%title-xdark {
  @extend %font-xbold;
  @extend %title-size;
  color: palette('zbrown', 'x-dark');
}

.title-dark,
%title-dark {
  @extend %font-xbold;
  @extend %title-size;
  color: palette('zbrown', 'dark');
}

.title-xlight,
%title-xlight {
  @extend %font-xbold;
  @extend %title-size;
  color: palette('zgray', 'x-light');
}

.title-xlight-sbold,
%title-xlight-sbold {
  @extend %font-bold;
  @extend %title-size;
  color: palette('zgray', 'x-light');
}

// Subtitle
// -----------------------------------------------------------------------------

.subtitle-xxdark,
%subtitle-xxdark {
  @extend %subtitle-size;
  @extend %font-xbold;
  // font-size: 1.25rem;
  color: palette('zbrown', 'xx-dark');
}

.subtitle-xdark,
%subtitle-xdark {
  @extend %subtitle-size;
  @extend %font-xbold;
  // font-size: 1.25rem;
  color: palette('zbrown', 'x-dark');
}

.subtitle-dark,
%subtitle-dark {
  @extend %font-light;
  @extend %subtitle-size;
  color: palette('zbrown', 'dark');
}

.subtitle-dark-bold,
%subtitle-dark-bold {
  @extend %font-xbold;
  @extend %subtitle-size;
  color: palette('zbrown', 'dark');
}

.subtitle-dark-sbold,
%subtitle-dark-sbold {
  @extend %font-bold;
  @extend %subtitle-size;
  color: palette('zbrown', 'dark');
}

.subtitle-xlight,
%subtitle-xlight {
  @extend %font-light;
  @extend %subtitle-size;
  color: palette('zgray', 'x-light');
}

.subtitle-xlight-sbold,
%subtitle-xlight-sbold {
  @extend %font-bold;
  @extend %subtitle-size;
  color: palette('zgray', 'x-light');
}

// Body 1
// -----------------------------------------------------------------------------

.body-sm-xdark,
%body-sm-xdark {
  @extend %body-size;
  color: palette('zgray', 'x-dark');
}

.body-sm-dark,
%body-sm-dark {
  @extend %body-size;
  color: palette('zbrown', 'dark');
}

.body-sm-dark-sbold {
  @extend %body-sm-dark;
  font-weight: 600;
}

.body-sm-middark,
%body-sm-middark {
  @extend %body-size;
  color: palette('zbrown', 'mid-dark');
}

.body-sm-xlight,
%body-sm-xlight {
  @extend %body-size;
  color: palette('zgray', 'x-light');
}

.body-xdark,
%body-xdark {
  color: palette('zgray', 'x-dark');
}

// Body 2
// -----------------------------------------------------------------------------

.body-bold-mid-brown,
%body-bold-mid-brown {
  @extend %body-size;
  @extend %font-bold;
  color: palette('zbrown', 'mid-dark');
}

.body-bold-success,
%body-bold-success {
  @extend %body-size;
  @extend %font-bold;
  color: palette('state', 'success');
}

// Body 3
// -----------------------------------------------------------------------------

.body-basedark,
%body-basedark {
  @extend %body-size;
  color: palette('zbrown');
}

.body-dark,
%body-dark {
  @extend %body-size;
  color: palette('zbrown', 'dark');
}

.body-middark,
%body-middark {
  @extend %body-size;
  color: palette('zbrown', 'mid-dark');
}

// Body 4
// -----------------------------------------------------------------------------

.body-brand-sbold,
%body-brand-sbold {
  @extend %body-size;
  @extend %font-bold;
  color: palette('brand', 'mid-dark');
}

.body-xdark-sbold,
%body-xdark-sbold {
  @extend %body-size;
  @extend %font-bold;
  color: palette('zbrown', 'x-dark');
}

.body-dark-sbold,
%body-dark-sbold {
  @extend %body-size;
  @extend %font-bold;
  color: palette('zbrown', 'dark');
}

.body-middark-sbold,
%body-middark-sbold {
  @extend %body-size;
  @extend %font-bold;
  color: palette('zbrown', 'mid-dark');
}

.body-xlight-sbold,
%body-xlight-sbold {
  @extend %body-size;
  @extend %font-bold;
  color: palette('zgray', 'x-light');
}

.body-sbold-danger,
%body-sbold-danger {
  @extend %body-size;
  @extend %font-bold;
  color: palette('state', 'danger');
}

.body-sbold-success,
%body-sbold-success {
  @extend %body-size;
  @extend %font-bold;
  color: palette('state', 'success');
}

.body-sbold-warning,
%body-sbold-warning {
  @extend %body-size;
  @extend %font-bold;
  color: palette('state', 'warning');
}

.body-sbold-info,
%body-sbold-info {
  @extend %body-size;
  @extend %font-bold;
  color: palette('state', 'info');
}

.body-extra-large {
  font-size: 5.5rem;
}

// Caption
// -----------------------------------------------------------------------------

.caption-base,
%caption-base {
  @extend %caption-size;
  color: palette('state', 'base');
}

.caption-dark,
%caption-dark {
  @extend %caption-size;
  color: palette('zbrown', 'dark');
}

.caption-middark,
%caption-middark {
  @extend %caption-size;
  color: palette('zbrown', 'mid-dark');

  .lt {
    text-decoration: line-through;
  }

  .success {
    color: palette('state', 'success-xlight');
  }
}

.caption-xlight,
%caption-xlight {
  @extend %caption-size;
  color: palette('zgray', 'x-light');

  b {
    font-weight: 700;
  }
}

.caption-danger,
%caption-danger {
  @extend %caption-size;
  color: palette('state', 'danger');
}

.caption-success,
%caption-success {
  @extend %caption-size;
  color: palette('state', 'success');
}

.caption-success-xlight,
%caption-success-xlight {
  @extend %caption-size;
  color: palette('state', 'success-xlight');
}

// Paragraph
// -----------------------------------------------------------------------------

.text-paragraph-dark {
  @extend %body-dark;
  line-height: 1.5rem;
}

// State Colors
// -----------------------------------------------------------------------------

.text-base {
  color: palette('state', 'base') !important;
}

.text-danger {
  color: palette('state', 'danger') !important;
}

.text-info {
  color: palette('state', 'info') !important;
}

.text-success {
  color: palette('state', 'success') !important;
}

.text-success-xlight {
  color: palette('state', 'success-xlight') !important;
}

.text-warning {
  color: palette('state', 'warning') !important;
}

.text-warning-xlight {
  color: palette('state', 'warning-xlight') !important;
}

.text-white {
  color: #fff !important;
}

.strong-text {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.learn-more-text {
  text-decoration: underline;
  font-weight: 700;
}
// -----------------------------------------------------------------------------

:root {
  --title-xl-font-size: 32px;
  --title-xl-line-height: 40px;

  --title-lg-font-size: 24px;
  --title-lg-line-height: 32px;

  --title-md-font-size: 20px;
  --title-md-line-height: 30px;

  --title-sm-font-size: 18px;
  --title-sm-line-height: 28px;

  --text-xl-font-size: 20px;
  --text-xl-line-height: 30px;

  --text-lg-font-size: 18px;
  --text-lg-line-height: 28px;

  --text-md-font-size: 16px;
  --text-md-line-height: 24px;

  --text-sm-font-size: 14px;
  --text-sm-line-height: 22px;

  --text-xs-font-size: 12px;
  --text-xs-line-height: 18px;
}

$title-sizes: xl lg md sm;

@each $size in $title-sizes {
  .title-#{$size} {
    font-size: var(--title-#{$size}-font-size);
    line-height: var(--title-#{$size}-line-height);
  }
}

$text-sizes: xl lg md sm xs;

@each $size in $text-sizes {
  .text-#{$size} {
    font-size: var(--text-#{$size}-font-size);
    line-height: var(--text-#{$size}-line-height);
  }
}
