// Jaguar Overrides
// -----------------------------------------------------------------------------

// Toolbar
// -----------------------------------------------------------------------------

// Toolbar Brand
// -----------------------------------------------------------------------------

.toolbar-brand-logo {
  width: 100px;
  height: 45px;
  background-image: url('/assets/img/jaguar.png');
}

// Toolbar Navigation
// -----------------------------------------------------------------------------

.toolbar-navigation {
  .btn {
    text-transform: uppercase;
  }
}

// Toolbar Actions
// -----------------------------------------------------------------------------

.toolbar-actions {
  .toolbar-btn-actions {
    color: palette('zgray', 'x-light') !important;

    & .zf-icon {
      color: palette('zgray', 'x-light') !important;

      @media only screen and (max-width: 1024px) {
        color: palette('zgray', 'x-light') !important;
      }
    }

    &:hover {
      color: palette('zgray', 'x-light') !important;

      & .zf-icon {
        color: palette('zgray', 'x-light') !important;

        @media only screen and (max-width: 1024px) {
          color: palette('zgray', 'x-light') !important;
        }
      }
    }
  }
}

// Alert
// -----------------------------------------------------------------------------

.alert-dark-bg {
  background-color: palette('zgray', 'x-dark');

  span,
  .zf-icon,
  .alert-text {
    color: palette('zgray', 'x-light') !important;
  }
}

// Tooltip
// -----------------------------------------------------------------------------

.mat-tooltip {
  background-color: palette('zgray', 'x-dark');
}

// Buttons
// -----------------------------------------------------------------------------

.btn {
  border-radius: 0;
  box-shadow: 0 0 0 transparent !important;
}

// -----------------------------------------------------------------------------
