// Kanban Module
// -----------------------------------------------------------------------------

.kanban {
  height: 90%;

  &.mat-card {
    padding: 0;
    background-color: palette('zbrown', 'x-light');
    border-radius: 4px;
  }

  &.mat-card,
  &.mat-card:not([class*='mat-elevation-z']) {
    box-shadow: 0 3px 5px rgba(palette('zbrown', 'x-dark'), 0);
  }
}

// Kanban Modifiers
// -----------------------------------------------------------------------------

.kanban-fixed {
  height: 465px;
  overflow: hidden;
}

// Container
// -----------------------------------------------------------------------------

.kanban-item-wrapper {
  height: 100%;
  padding: 0 0.825rem;
  overflow-y: scroll;
}

// Kanban Elements
// -----------------------------------------------------------------------------

.kanban-card {
  margin-bottom: 0.875rem;
  cursor: pointer;

  &:first-child {
    margin-top: 2rem;
  }

  &:last-child {
    margin-bottom: 2rem;
  }

  &.mat-card {
    transition: all 0.2s ease;
    padding: 2rem;
  }

  &:hover.mat-card {
    background-color: lighten(palette('zbrown', 'x-light'), 5%);
  }
}

.kanban-card-time {
  @extend %body-middark;
  font-size: 0.875rem !important;
  text-align: right;
}

.kanban-card-header {
  .small {
    font-size: 14px;
  }
}

.kanban-card-client {
  @extend %body-middark;
  float: right;
  font-size: 0.8rem !important;
  @extend %font-xbold;
  img {
    width: 2rem;
    height: 2rem;
  }
}

.kanban-card-body {
  span {
    display: block;
    margin: 1.5rem 0 1rem 0;
    // @extend %body-dark-sbold;
    font-size: 1rem;
  }

  p {
    margin: 0;
    margin-bottom: 0.5rem;
    // @extend %body-middark;
    font-size: 0.825rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
  p.caption-success {
    @extend %caption-success;
    margin-top: 0.5rem;
  }
}

// -----------------------------------------------------------------------------
