// Negotiations
// -----------------------------------------------------------------------------
.resume-negotiations {
  padding: 1rem !important;

  .quantity {
    @extend %font-bold;
    font-size: 3rem;
    line-height: 4rem;
  }
}

.negotiations-tabs {
  top: 0;

  button {
    font-size: 1.25rem;
    @extend %font-regular;
    padding: 1rem;
    border-bottom: 2px solid transparent;

    &.selected {
      color: palette('brand', 'mid-dark');
      border-bottom: 2px solid palette('brand', 'mid-dark');
    }
  }
}

.qtd-negotiations {
  text-align: end;
  small {
    font-size: 0.8rem;
    font-family: 'itau_displaylight', sans-serif;
    color: #605751;
  }
}

.no-negotiations {
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 120px;

  p {
    padding: 10px;
    font-size: 1.25rem;
    font-family: 'itau_displaylight', sans-serif;
    color: #605751;
  }
}

.negotiations {
  z-index: 0;
  box-shadow: 0 3px 5px rgba(72, 64, 58, 0.2) !important;

  cdk-virtual-scroll-viewport {
    min-height: calc(95vh - 135px);
  }

  @include for-phone {
    overflow-x: auto;
  }

  @include for-desktop {
    /* Chrome, Edge and Safari */
    *::-webkit-scrollbar {
      height: 10px;
      width: 10px;
    }
    *::-webkit-scrollbar-track {
      border-radius: 5px;
      background-color: palette('zgray', 'mid-light');
    }

    *::-webkit-scrollbar-track:hover {
      background-color: palette('zgray', 'mid-light');
    }

    *::-webkit-scrollbar-track:active {
      background-color: palette('zgray', 'mid-dark');
    }

    *::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: palette('zgray', 'mid-dark');
    }

    *::-webkit-scrollbar-thumb:hover {
      background-color: palette('actions', 'warning');
    }

    *::-webkit-scrollbar-thumb:active {
      background-color: palette('actions', 'warning');
    }
  }

  .sticky {
    position: -webkit-sticky;
    position: sticky;
    z-index: 1;
    background: palette('zgray', 'x-light');
    top: 0;
    border-radius: 4px;
  }

  form {
    padding: 1rem 1rem 0;
    //height: 80px;

    .datepicker-range {
      padding-bottom: 0.5rem;
    }

    .body-sm-dark {
      @extend %font-bold;
    }
  }

  table {
    border-collapse: collapse;
    width: 100%;

    thead,
    tbody {
      display: table;
      width: 100%;
    }

    tbody {
      cursor: pointer;
    }

    tr {
      &:not(:first-child) {
        > td {
          border-top: 1px solid palette('zgray', 'light');
        }

        &:hover {
          background-color: palette('zgray', 'mid-light');
        }
      }

      &:not(.viewed) {
        @extend %font-bold;
      }

      &.viewed {
        background-color: palette('zgray', 'light');
      }
    }

    tr > th {
      .zf-icon {
        margin-left: 0.25rem;
        vertical-align: middle;
        color: palette('brand', 'mid-dark');
        font-size: 1rem;
        @extend %font-bold;
      }

      &:not(.active) .zf-icon {
        opacity: 0;
        transition: all 0.3s ease;
      }

      &:not(.active):hover .zf-icon {
        opacity: 0.7;
      }

      &.active .zf-icon {
        opacity: 1;
      }

      * {
        pointer-events: none !important;
      }
    }

    tr > th,
    tr > td {
      @extend %body-sm-dark;
      color: palette('zbrown', 'dark') !important;
      padding: 1.5rem 0 1.5rem 1rem !important;
      text-align: left;
      vertical-align: top;

      &:last-child {
        padding-right: 1rem !important;
      }
    }

    &.filter-negotiations,
    &.last-negotiations {
      tr > th,
      tr > td {
        &:not(:first-child),
        &:not(:last-child) {
          cursor: pointer;
        }

        &:first-child {
          min-width: calc(77px - 1rem);
        }

        &:nth-child(2n) {
          min-width: calc(141px - 1rem);
        }

        &:nth-child(3n) {
          min-width: calc(68px - 1rem);
        }

        &:nth-child(4n) {
          min-width: calc(340px - 1rem);
        }

        &:nth-child(5n) {
          min-width: calc(100px - 1rem);
        }

        &:nth-child(6n) {
          min-width: calc(274px - 1rem);
        }

        &:nth-child(7n) {
          min-width: calc(148px - 1rem);
        }

        &:last-child {
          min-width: calc(52px - 2rem);
        }

        &:nth-child(6n),
        &:nth-child(7n) {
          white-space: nowrap;
        }
      }

      tr > td {
        &:nth-child(7n) {
          font-size: 0.75rem !important;
          line-height: 1;
        }

        &:last-child {
          button {
            .zf-icon {
              color: palette('zgray', 'mid-dark');
              font-size: 1.25rem;
            }
          }
        }
      }
    }

    &.bank-pendency {
      tr > th,
      tr > td {
        &:not(:first-child) {
          cursor: pointer;
        }

        &:first-child {
          width: calc(93px - 1rem);
          padding-left: 2.5rem !important;
        }

        &:nth-child(2n) {
          width: calc(359px - 1rem);
        }

        &:nth-child(3n) {
          width: calc(69px - 1rem);
        }

        &:nth-child(4n) {
          width: calc(326px - 1rem);
        }

        &:nth-child(5n) {
          width: calc(181px - 1rem);
        }

        &:last-child {
          width: calc(172px - 1rem);
          padding-right: 2.5rem !important;
          white-space: nowrap;
        }
      }
    }
  }

  &.mat-card {
    padding: 0;
  }

  .mat-sort-header-arrow {
    color: palette('brand', 'mid-dark');
  }
}

.helper-height {
  height: 100%;
}

// -----------------------------------------------------------------------------
