// Custom List
// -----------------------------------------------------------------------------

.customList {
  display: flex;
  flex-direction: column;
}

.customList__item {
  display: flex;
  flex-direction: row;
  padding: 1.2rem 0;
  align-items: center;
  justify-content: space-between;

  &:first-child {
    padding-top: 0;
  }

  &:not(:last-child) {
    border-bottom: 2px solid palette('zgray', 'light');
  }

  & > div {
    display: flex;
    flex-direction: column;

    & > * {
      
      &:not(:last-child) {
        margin-bottom: .3rem;
      }
    }
  }

  & > * {

    &:not(:last-child) {
      padding-right: 1.25rem;
    }
  }
}


// Custom List - Publisher
// -----------------------------------------------------------------------------

.customList--publisher {

  .customList__item {
    min-height: 50px;
  }

  .customList__thumb {
    display: block;
    width: 10%;
    padding-right: 1.25rem;

    & img {
      height: 100%;
      max-height: 50px;
    }

  }

  .customList__actions {
    flex-direction: row;

    & > * {
      &:not(:last-child) {
        padding-right: 1.25rem;
      }
    }
  }

  .customList__legend {
    color: #979797;
  }
}



// -----------------------------------------------------------------------------
