// Gradient
// -----------------------------------------------------------------------------

@mixin gradient($c1, $c2, $from, $to) {
  background: $c1;
  background: -webkit-linear-gradient($from, $c1 0%, $c2 100%);
  background: -moz-linear-gradient($from, $c1 0%, $c2 100%);
  background: linear-gradient(to $to, $c1 0%, $c2 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(
    startColorstr="$c1", endColorstr="$c2", GradientType=1
  );
}

// Media Queries
// -----------------------------------------------------------------------------

@mixin mobile {
  @media (min-width: 250px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 560px) {
    @content;
  }
}

@mixin notebook {
  @media (min-width: 1140px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1280px) {
    @content;
  }
}

// Itaú Icons
// -----------------------------------------------------------------------------

@mixin itauicons($name, $before: true) {
  @if not $before {
    font-family: 'itau-icons' !important;
    content: $name;
  } @else {
    &::before {
      font-family: 'itau-icons' !important;
      content: $name;
    }
  }
}

// -----------------------------------------------------------------------------

//Display flex
// -----------------------------------------------------------------------------
@mixin dp-flex-position($flex-direction: row, $justify-content: initial, $align-items: initial) {
  display: flex;
  flex-direction: $flex-direction;
  justify-content: $justify-content;
  align-items: $align-items;
}
// -----------------------------------------------------------------------------
