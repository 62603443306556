// List
// -----------------------------------------------------------------------------

.list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.list-quota {

  li {
    @extend %body-sm-dark;
    margin-bottom: 1rem;
  }

  li span {
    display: inline-block;
    margin-right: 80px;
  }

}

.list-cars-remove {
  margin-top: 18px;
}


// -----------------------------------------------------------------------------
