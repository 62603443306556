@import 'responsive';
@import 'variableFontSize';

// Dialog Styles
// -----------------------------------------------------------------------------

.dialog {
  position: relative;
  padding: 2.5rem;
  border-radius: 4px;
  background-color: palette('zgray', 'x-light');
  -webkit-box-shadow: 0 0 10px palette('dark-ghost', 50), 0 0 50px palette('dark-ghost', 30);
  box-shadow: 0 0 10px palette('dark-ghost', 50), 0 0 50px palette('dark-ghost', 30);
  width: auto;
  max-width: 960px;
}

@include for-phone-tablet {
  .dialog {
    width: 100%;
  }
}

.dialog-header {
  margin-bottom: 2.25rem;

  &.mb0 {
    margin-bottom: 0 !important;
  }

  &.mb1 {
    margin-bottom: 1rem !important;
  }

  &.mb2 {
    margin-bottom: 2rem !important;
  }

  &.mb3 {
    margin-bottom: 3rem !important;
  }

  &.dialog-header-collapsed {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: palette('zbrown', 'x-light');
    margin: -2.5rem -2.5rem 2rem -2.5rem;
    padding: 2rem;
  }
}

.dialog-header-title {
  &.mat-dialog-title {
    @extend %title-dark;
    margin: 0;
  }
}

.dialog-header-subtitle {
  @extend %subtitle-dark;
  margin-top: 1rem;
}

.dialog-header-btn {
  width: 28px;
  height: 28px;
  padding: 0;
  margin: 0;
  border: 0;
  background-color: transparent;
  font-size: 1.5rem;
  color: palette('zbrown', 'dark');
  outline: 0;
  cursor: pointer;
  margin-top: -2px;
}

.dialog-header-close {
  @include itauicons('\e94c');
  position: absolute;
  top: 1rem;
  right: 1rem;

  &.dialog-header-close-primary {
    color: palette('brand', 'mid-dark');
  }
}

.dialog-content {
  &.mat-dialog-content {
    min-width: 900px;

    &.mb0 {
      margin-bottom: 0 !important;
    }

    &.mb1 {
      margin-bottom: 1rem !important;
    }

    &.mb2 {
      margin-bottom: 2rem !important;
    }

    &.mb3 {
      margin-bottom: 3rem !important;
    }
  }
}

.dialog-content-full {
  &.mat-dialog-content {
    min-width: auto;
  }
}

.dialog-content-no-scroll {
  &.mat-dialog-content {
    max-height: auto;
    overflow: visible;
  }
}

.dialog-actions {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2.25rem 0 0;
  padding: 0;
  border-radius: 8px;
  color: palette('zgray', 'x-light');
  z-index: 2;
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;

  & button:not(:first-child) {
    &:not([class*='dialog-actions']) {
      margin-top: 1rem;
    }
  }
}

// Dialog PDF Viewer
// -----------------------------------------------------------------------------

.dialog-pdf {
  position: relative;
  width: 100%;
  height: 100vh;
  padding: 0;
  margin: 0;
  background: transparent !important;
  -webkit-box-shadow: 0 0 0 transparent !important;
  box-shadow: 0 0 0 transparent !important;

  @include for-desktop {
    * {
      scrollbar-width: thin;
      scrollbar-color: palette('zgray', 'mid-dark') palette('zgray', 'mid-light');
    }
    *::-webkit-scrollbar {
      width: 7px;
      width: 7px;
    }
    *::-webkit-scrollbar-track {
      border-radius: 3px;
      background-color: palette('zgray', 'mid-light');
    }

    *::-webkit-scrollbar-track:hover {
      background-color: palette('zgray', 'mid-dark');
    }

    *::-webkit-scrollbar-track:active {
      background-color: palette('zgray', 'mid-dark');
    }

    *::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: palette('zgray', 'mid-dark');
    }

    *::-webkit-scrollbar-thumb:hover {
      background-color: palette('actions', 'warning');
    }

    *::-webkit-scrollbar-thumb:active {
      background-color: palette('actions', 'warning');
    }
  }

  &:hover .dialog-actions {
    opacity: 1;
  }

  button:not(.btn) {
    font-size: 1.5rem;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    outline: 0;
    -webkit-transition: color 0.2s ease;
    transition: color 0.2s ease;
    color: palette('zgray', 'x-light');

    &:hover {
      color: palette('brand', 'mid-dark');
    }
  }

  .dialog-header {
    margin: 0;
    position: relative;
    width: 100%;
    min-height: 10vh;
    padding: 1rem 5px 2rem;
    z-index: 1;
  }

  .dialog-header-title {
    &.mat-dialog-title {
      color: palette('zgray', 'x-light');
    }
  }

  .dialog-header-btn {
    font-size: 2rem;
  }

  .dialog-header-close {
    @include itauicons('keyboard_arrow_left');
    color: palette('zgray', 'x-light');
    margin-left: -15px;
  }

  .dialog-header-icon-close {
    @include itauicons('close');
    color: palette('zgray', 'x-light');
    margin-left: -15px;
  }

  .dialog-header-print {
    @include itauicons('\ea16');
    color: palette('zgray', 'x-light');
    position: relative;
    top: -1px;
    margin-right: 1rem;
  }

  .dialog-header-email {
    @include itauicons('\e97e');
    color: palette('zgray', 'x-light');
  }

  .dialog-content {
    //position: absolute;
    display: block;
    width: 100%;
    height: 90vh;
    max-height: 90vh;
    margin: 0;
    padding: 0;
    border-radius: 4px 4px 0 0;
    background: palette('zgray', 'x-light');
    z-index: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: -webkit-grab;
    cursor: grab;
    -webkit-box-shadow: 0 0 10px palette('dark-ghost', 50), 0 0 50px palette('dark-ghost', 30);
    box-shadow: 0 0 10px palette('dark-ghost', 50), 0 0 50px palette('dark-ghost', 30);

    overflow-y: auto;

    &.dialog-content--no-shadow {
      background-color: transparent !important;
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
    }

    &.print-contract {
      height: calc(100vh - 164px);
      max-height: calc(100vh - 164px);
    }
  }

  .dialog-actions {
    position: absolute;
    width: auto;
    height: 65px;
    margin: 0;
    padding: 0;
    top: 90vh;
    left: 50%;
    margin-top: initial;
    margin-left: -175px;
    background-color: palette('dark-ghost', 90);
    border-radius: 8px;
    color: palette('zgray', 'x-light');
    z-index: 2;
    opacity: 0;
    -webkit-transition: opacity 0.2s ease-in-out;
    transition: opacity 0.2s ease-in-out;
  }

  .dialog-actions-pagination {
    padding: 0 1rem;

    .dialog-actions-pagination-prev {
      @include itauicons('\e9c3');
    }

    .dialog-actions-pagination-text {
      margin: 0 0.5rem;
      font-size: 1rem;
      @extend %font-bold;
      width: 90px;
      text-align: center;
      position: relative;
      top: 1px;
    }

    .dialog-actions-pagination-next {
      @include itauicons('\e9c4');
    }
  }

  .dialog-actions-zoom {
    padding: 0 1rem;
    border-left: 1px solid palette('light-ghost', 20);

    > span {
      font-size: 1.2rem;
      margin: 0 1rem;
    }

    .dialog-actions-zoom-out {
      @include itauicons('\ea22');
    }

    .dialog-actions-zoom-in {
      @include itauicons('\e905');
    }
  }
}

.dialog-pdf-viewer {
  display: block;
  width: 100%;
}

// Video Dialog
// -----------------------------------------------------------------------------

.dialog-video {
  position: relative;
  padding: 0;
  width: 720px;
  height: 405px;
  border-radius: 5px;
  overflow: visible !important;

  .dialog-header {
    display: block;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
  }

  .dialog-header-btn {
    position: absolute;
    top: -30px;
    right: -5px;
  }

  .dialog-header-close {
    color: palette('zgray', 'x-light');
  }

  .dialog-content {
    width: 720px;
    height: 405px;
    overflow: hidden;
    border-radius: 5px;

    iframe {
      border-radius: 5px;
    }
  }
}

// Default Styles Override
// -----------------------------------------------------------------------------

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.7;
}

.cdk-overlay-dark-backdrop {
  background: rgba(palette('zgray', 'x-dark'), 1);
}

.mat-dialog-container {
  background: transparent;
  -webkit-box-shadow: 0 0 0 transparent !important;
  box-shadow: 0 0 0 transparent !important;
}

// -----------------------------------------------------------------------------

.btn-confirm {
  padding: 1rem 2.5rem;
  border: 0;
  border-radius: 4px;
  background-color: palette('brand', 'mid-dark');
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: 0 3px 5px rgba(palette('zgray', 'x-dark'), 0.2);
  box-shadow: 0 3px 5px rgba(palette('zgray', 'x-dark'), 0.2);
  font-size: 1rem;
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  outline: 0;

  &__danger {
    background-color: palette('state', 'danger') !important;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &__success {
    background-color: palette('state', 'success') !important;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .btn-close {
    transform: translate(50%, -50%);
    @extend %body-dark;
  }
}

// -----------------------------------------------------------------------------

.publication-dialog--backdrop {
  background: palette(zgray, x-dark);

  + .cdk-global-overlay-wrapper {
    z-index: 1001;
  }
}

.dialog-detail {
  padding: 0;
  border-radius: 12px;
  max-width: 1000px;

  .dialog-header {
    background-color: palette('brand', 'mid-dark');
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.7rem;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;

    .dialog-header-title {
      padding-left: 1rem;
      color: palette('zgray', 'x-light');
    }

    .dialog-header-subtitle {
      margin: 0;
      padding-right: 2rem;
    }

    .dialog-header-close {
      color: palette('zgray', 'x-light');
      top: 0.5rem;
      right: 0.5rem;
    }
  }

  .dialog-content {
    padding: 2rem 4rem;
  }
}

.vehicle-plate-chassi-search-dialog {
  @media (max-height: 661px) {
    height: 100%;
    max-height: fit-content;
  }
}
