// Progress Bar
// -----------------------------------------------------------------------------

.progress-dialog {
  position: relative;
  top: 5px;
  border-radius: 4px 4px 0 0;

  .mat-progress-bar-buffer {
    background-color: transparent;
  }
}

.progress-simulator-dashboard {
  @extend .progress-dialog;
  top: 0;
}


// Progress Card
// -----------------------------------------------------------------------------

.progress-card {
  @extend .progress-dialog;

  top: -56px;

  z-index: 1;
}


// -----------------------------------------------------------------------------
