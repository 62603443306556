:root {
  --header: 0px;
}

.voxel-theme-varejo {
  overflow: auto;
}

// Content Styles
// -----------------------------------------------------------------------------
.wrapper {
  position: relative;
  @extend .container;
}

.wrapper-full-height {
  height: calc(100vh - 112px);
  padding-top: 2rem;
}

.wrapper__inner {
  padding: 2.5rem;
}

.wrapper__inner--top {
  padding: 2.5rem 2.5rem 0;
}

// .wrapper__inner--top {
//   padding: 2.5rem;
// }

.app__main {
  min-height: calc(100vh - (82px + var(--header)));
}

.wrapper-layout {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 10px;
  align-items: center;
  justify-content: space-between;
  background-color: palette('zgray', 'x-light');

  .logo-itau {
    display: grid;
    grid-template-rows: 56px auto;
    grid-row-gap: 8px;
    justify-items: center;
    img {
      width: 100%;
      height: 56px;
    }
  }

  iframe {
    margin: auto;
  }

  img {
    height: 100vh;
    max-width: 20vw;
  }
}

.jlr,
.credlinelandrover,
.credlinejaguar {
  .logo-itau {
    grid-template-rows: 96px auto !important;

    img {
      height: 96px !important;
    }
  }
}

.app__footer {
  display: flex;
  justify-content: center;
  margin: 3rem auto 0.75rem;
}

@media screen and (max-width: 1199px) {
  .wrapper-layout {
    grid-auto-flow: row;
    grid-template-rows: 180px auto;
    grid-row-gap: 20px;
    align-items: flex-start;
    justify-content: center;
    padding-bottom: 10%;
    height: 100%;
    overflow-x: hidden;

    img {
      margin: auto;
      height: auto;
      max-width: 100%;
    }

    .img-direita {
      display: none;
    }
  }

  .jlr .logo-itau {
    height: 100%;
    align-items: flex-end;
  }
}
// -----------------------------------------------------------------------------
