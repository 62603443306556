// Customtabs
// -----------------------------------------------------------------------------

.customtabs {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: initial;
  border-bottom: 1px solid palette('zgray', 'light');
  margin: 0 auto;
  z-index: 1;

  &.tabs-inline {
    display: inline-flex;
    width: auto;
  }

  & button {
    width: 100%;
    font-size: 1rem;
    @extend %font-bold;
    color: palette('zgray', 'mid-dark');
    text-align: center;
    white-space: nowrap;
    padding: 1rem 1.3rem;
  }

  & > div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex: 1 1 0px;
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
  }

  & > div:not(.active) {
    & button {
      &:hover {
        color: rgba(palette('brand', 'mid-dark'), 0.9);
      }
    }
  }

  & .active {
    border-bottom: 2px solid palette('brand', 'mid-dark');

    & button {
      color: palette('brand', 'mid-dark');
    }
  }
}

// -----------------------------------------------------------------------------
