@import "responsive";

.expansion {
  .expansion-panel {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    border-bottom: 1px solid palette('dark-ghost', 20);

    &.expansion-panel-expanded {
      .expansion-panel-header {
        .expansion-button-toggle {
          transform: rotate(180deg);
        }
      }
    }

    .expansion-panel-header {
      > div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        padding: 1rem 1rem 0;
        cursor: pointer;
        &.expansion-hide-toggle {
          cursor: default;
        }
        .expansion-button-toggle {
          width: 1rem;
          height: 1rem;
          line-height: 1rem;
          margin-left: 0.5rem;
          transition: 150ms ease-in-out;

          .zf-icon {
            font-size: 1rem;
            color: palette('zbrown', 'mid-dark');
          }
        }
      }
    }

    .expansion-panel-content {
      > div {
        padding-right: 1rem;
        padding-left: 1rem;
      }
    }
  }
}

// custom style for material expansion panel
.material-custom-expansion {
  background-color: #ffffff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  .mat-expansion-panel {
    box-shadow: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 12px;

    .mat-expansion-panel-header {
      height: 56px;
      padding-right: 0px;

      @include for-phone {
        min-height: 56px;
        height: auto;
        padding: 16px;
      }

      .mat-content > div {
        width: 100%;

        p {
          font-size: 15px;
        }
      }
    }
  }

  .mat-expansion-indicator {
    margin-right: 1rem;
    padding-bottom: 0.25rem;
    &::after {
      color: palette('brand', 'base');
      border-width: 0 1px 1px 0;
      padding: 4px;
    }
  }

  .expansion {
    .expansion-panel {
      border: 0px;
      background-color: palette('zgray', 'light');
    }
    .expansion-panel-header {
      > div {
        align-items: center;
        padding: 0.75rem;
      }
    }
  }
}
