// Slider
// -----------------------------------------------------------------------------

.slider-container {
  border-bottom: 1px solid palette('zgray', 'light');
  clear: both;

  &__legend {
    padding: 18px 0 0;

    .text-base {
      margin-top: 13px;
    }
  }

  .btnLink {
    margin-top: 13px;
    font-size: 1rem;

    .dropdown-menu-title-icon {
      margin-right: 0;
      padding-left: 0.3rem;
    }
  }
}

.dropdown,
.dropdown-menu {
  strong.text-base {
    font-size: 1.1rem !important;
    @extend %font-regular;
  }
}

.pricing-slider {
  transform: translateY(-32px);

  &.locked {
    .ngx-slider-span.ngx-slider-pointer.ngx-slider-pointer-min,
    .ngx-slider-ticks .ngx-slider-tick,
    .ngx-slider-span.ngx-slider-bar.ngx-slider-selection,
    .ngx-slider-ticks .ngx-slider-tick.ngx-slider-selected {
      background-color: #c4c4c4;
    }
  }

  &.no-range {
    .ngx-slider-bar,
    .ngx-slider-tick {
      background: palette('brand', 'mid-dark');
    }
  }

  .ngx-slider {
    .ngx-slider-bar {
      left: 5px;
      height: 2px;
      //width: calc(100% - 10px);
    }

    .ngx-slider-selection {
      background: palette('brand', 'mid-dark');
      margin-left: 3px;
    }

    .ngx-slider-pointer.ngx-slider-pointer-min {
      background-color: palette('brand', 'mid-dark');
      border-radius: 100%;
      top: -7px;
      bottom: 0;
      width: 16px;
      height: 16px;
    }

    .ngx-slider-pointer.ngx-slider-pointer-max {
      background: url('/assets/img/default-return-slider.png') no-repeat center;
      background-size: cover;
      border-radius: 8px;
      top: -67px;
      bottom: 0;
      margin-left: -44px;
      width: 104px;

      @media only screen and (max-width: 900px) {
        width: 85px;
      }

      height: 151px;
      z-index: 0;
    }

    .ngx-slider-pointer:after {
      display: none;
    }

    .ngx-slider-bubble {
      top: 10px;
      bottom: auto;
    }

    .ngx-slider-tick {
      background: palette('brand', 'mid-dark');
      box-sizing: content-box;
      border: 1px solid palette('zgray', 'x-light');
      border-radius: 100%;
      top: 1px;
      margin-left: 5px;
      width: 4px;
      height: 4px;
    }

    .ngx-slider-tick-value {
      top: 16px;
    }

    .ngx-slider-tick-value::first-letter,
    .ngx-slider-tick-legend::first-letter {
      font-size: 1rem;
      @extend %font-bold;
    }

    .ngx-slider-tick-value {
      font-size: 0.75rem;
    }

    .ngx-slider-tick-legend {
      font-size: 0.9rem;
    }

    .ngx-slider-tick-legend {
      color: palette('state', 'base');
      top: 50px;
    }

    .ngx-slider-tick-legend::before {
      @include itauicons('\e9c2', false);
      color: palette('zgray', 'light');
      font-size: 1.1rem;
      position: absolute;
      width: 20px;
      height: 20px;
      top: -16px;
      left: -6px;
    }

    .ngx-slider-tick.ngx-slider-selected {
      background: palette('brand', 'mid-dark');
    }
  }
}

// -----------------------------------------------------------------------------
