// Tabs Styles
// -----------------------------------------------------------------------------

.tabs {
  margin: 2rem 0 5rem 0;
  border-bottom: 0;

  .mat-tab-link-container {
    overflow: auto !important;
    display: -webkit-box !important;
  }

  &.tabs_between {

    .mat-tab-links {
      display: flex;
      justify-content: space-between;
      .mat-tab-link {
        font-size: .875rem;
        margin-left: auto;
      }
    }
  }

  .mat-ink-bar {
    width: auto;
  }

}


// Tabs Elements
// -----------------------------------------------------------------------------

.tabs_link {
  @extend %body-xlight-sbold;
  font-size: .875rem !important;
  min-width: auto !important;
  margin: 0 2rem 0 0;
  padding: 0 !important;
  color: palette("zgray", "x-light");
  opacity: 1 !important;

  .mat-ripple-element {
    opacity: 0 !important;
  }

  &.mat-tab-link {
    @extend %font-bold;
  }
}


// -----------------------------------------------------------------------------
