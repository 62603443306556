.float-right {
  float: right !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.d-inline-grid {
  display: grid !important;
}

.text-align-center-webkit {
  text-align: -webkit-center !important;
}

.text-align-last-center {
  text-align-last: center;
}

.width-webkit-fill-available {
  width: -webkit-fill-available !important;

  @-moz-document url-prefix() {
    width: -moz-available !important;
  }
}

.min-w-auto {
  min-width: auto !important;
}

.height-fit-content {
  height: fit-content !important;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
  overflow: auto;
  pointer-events: auto;
}
.align-self-center {
  align-self: center;
}

::ng-deep .ng2-dropdown-menu__options-container {
  min-width: 320px;
}
