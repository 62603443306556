/* Majority phones */
@mixin for-phone {
  @media (max-width: 767px) {
    @content;
  }
}

/* Tablets Portrait and Landscape */
@mixin for-tablet {
  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    @content;
  }
}

/* For phone and tablet - commom usages */
@mixin for-phone-tablet {
  @media only screen and (max-width: 1023px) {
    @content;
  }
}

@mixin for-desktop {
  @media (min-width: 1025px) {
    @content;
  }
}
