// Timeline Module
// -----------------------------------------------------------------------------

.timeline {
  position: relative;
}

// Timeline Progress
// -----------------------------------------------------------------------------

.timeline-progress-step-1 {
  .timeline-line-fill {
    width: 0;
  }

  .timeline-list li:nth-child(1) {
    .timeline-list-item-mark {
      @extend .timeline-list-item-mark-active;
    }

    .timeline-list-item-label {
      @extend .timeline-list-item-label-active;
    }
  }
}

.timeline-progress-step-2 {
  .timeline-line-fill {
    width: 20%;
  }

  .timeline-list li:nth-child(1),
  .timeline-list li:nth-child(2) {
    .timeline-list-item-mark {
      @extend .timeline-list-item-mark-active;
    }

    .timeline-list-item-label {
      @extend .timeline-list-item-label-active;
    }
  }
}

.timeline-progress-step-3 {
  .timeline-line-fill {
    width: 40%;
  }

  .timeline-list li:nth-child(1),
  .timeline-list li:nth-child(2),
  .timeline-list li:nth-child(3) {
    .timeline-list-item-mark {
      @extend .timeline-list-item-mark-active;
    }

    .timeline-list-item-label {
      @extend .timeline-list-item-label-active;
    }
  }
}

.timeline-progress-step-4 {
  .timeline-line-fill {
    width: 60%;
  }

  .timeline-list li:nth-child(1),
  .timeline-list li:nth-child(2),
  .timeline-list li:nth-child(3),
  .timeline-list li:nth-child(4) {
    .timeline-list-item-mark {
      @extend .timeline-list-item-mark-active;
    }

    .timeline-list-item-label {
      @extend .timeline-list-item-label-active;
    }
  }
}

.timeline-progress-step-5 {
  .timeline-line-fill {
    width: 80%;
  }

  .timeline-list li:nth-child(1),
  .timeline-list li:nth-child(2),
  .timeline-list li:nth-child(3),
  .timeline-list li:nth-child(4),
  .timeline-list li:nth-child(5) {
    .timeline-list-item-mark {
      @extend .timeline-list-item-mark-active;
    }

    .timeline-list-item-label {
      @extend .timeline-list-item-label-active;
    }
  }
}

.timeline-progress-step-6 {
  .timeline-line-fill {
    width: 100%;
  }

  .timeline-list li:nth-child(1),
  .timeline-list li:nth-child(2),
  .timeline-list li:nth-child(3),
  .timeline-list li:nth-child(4),
  .timeline-list li:nth-child(5),
  .timeline-list li:nth-child(6) {
    .timeline-list-item-mark {
      @extend .timeline-list-item-mark-active;
    }

    .timeline-list-item-label {
      @extend .timeline-list-item-label-active;
    }
  }
}

// Timeline Line
// -----------------------------------------------------------------------------

.timeline-line {
  position: relative;
  left: 8%;
  width: 84%;
}

.timeline-line-fill,
.timeline-line-background {
  position: absolute;
  width: 100%;
  height: 2px;
  transition: width 1s ease;
  transform-origin: top left;
}

.timeline-line-fill {
  background-color: rgba(palette('zgray', 'x-light'), 1);
  z-index: 1;
}

.timeline-line-background {
  background-color: palette('zbrown', 'mid-dark');
  z-index: 0;
}

// Timeline List
// -----------------------------------------------------------------------------

.timeline-list {
  position: relative;
  top: -10px;
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  @media screen and (max-width: 576px) {
    flex-wrap: wrap;
    position: relative;
  }
}

// Timeline List Item
// -----------------------------------------------------------------------------
.timeline-list-item {
  width: 100%;
  text-align: center;
  &::before {
    content: ' ';
    @extend .timeline-line-background;
    top: calc((22px - 2px) / 2);
    max-width: 20%;
  }
  &:last-child::before {
    content: none;
  }
  &.timeline-list-item-completed::before {
    background: palette('zgray', 'x-light');
  }
  @media screen and (max-width: 576px) {
    display: flex;
    position: relative;
    height: 90px;
    &:last-child {
      height: 20px;
    }
    &::before {
      top: 26px;
      position: absolute;
      max-width: 2px;
      height: 60px;
      left: 10px;
    }
  }
}

.timeline-list-item-error {
  .timeline-list-item-mark {
    @extend .timeline-list-item-mark-error;
  }

  .timeline-list-item-status {
    @extend .timeline-list-item-status-active;
  }
}

.timeline-list-item-warn {
  .timeline-list-item-mark {
    @extend .timeline-list-item-mark-warn;
  }

  .timeline-list-item-status {
    @extend .timeline-list-item-status-active;
  }
}

.timeline-list-item-completed {
  .timeline-list-item-mark {
    @extend .timeline-list-item-mark-checked;
  }

  .timeline-list-item-label {
    @extend .timeline-list-item-label-active;
  }

  .timeline-list-item-action {
    @extend .timeline-list-item-action-active;
  }
}

// Timeline List Item Elements (Mark)
// -----------------------------------------------------------------------------

.timeline-list-item-mark {
  position: relative;
  display: block;
  box-sizing: content-box;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 2px solid palette('zbrown', 'mid-dark');
  background-color: palette('zbrown', 'dark');
  margin: 0 auto 1.188rem auto;
  // z-index: 3;
  transition: all 2s ease;
  @media screen and (max-width: 576px) {
    margin: 0px;
  }
}

.timeline-list-item-mark-active {
  border: 2px solid palette('zgray', 'x-light');
}

.timeline-list-item-mark-checked {
  @include itauicons('\e947');
  font-size: 1.1rem;
  @extend %font-bold;
  line-height: 19px;
  color: palette('zbrown', 'dark');
  border: 2px solid palette('zgray', 'x-light');
  background-color: palette('zgray', 'x-light');
}

.timeline-list-item-mark-error {
  @include itauicons('\e94c');
  font-size: 0.8rem;
  @extend %font-bold;
  line-height: 19px;
  color: palette('zgray', 'x-light');
  border: 2px solid palette('state', 'danger') !important;
  background-color: palette('state', 'danger') !important;
}

.timeline-list-item-mark-warn {
  font-size: 0.8rem;
  @extend %font-bold;
  line-height: 19px;
  color: palette('zgray', 'x-light');
  border: 2px solid palette('state', 'warning-xlight') !important;
  background-color: palette('state', 'warning-xlight') !important;

  &::before {
    display: block;
    content: '\0021';
  }
}

.timeline-list-item-mark-cancelled {
  @extend %font-bold;
  @include itauicons('\e94c');
  color: palette('zgray', 'x-light');
  font-size: 0.8rem;
  line-height: 19px;
  background-color: palette('state', 'danger') !important;
  border-color: palette('state', 'danger') !important;
}

// Timeline List Item Elements (Label)
// -----------------------------------------------------------------------------

.timeline-list-item-label {
  display: block;
  color: palette('zbrown', 'mid-dark');
  transition: all 1s ease;
  @extend %font-bold;
  @media screen and (max-width: 576px) {
    padding: 5px 15px;
    min-width: 160px;
    text-align: left;
  }
}

.timeline-list-item-buttons {
  @media screen and (max-width: 576px) {
    margin: 15px 0 0 -130px;
  }
}

.timeline-list-item-label-active {
  color: palette('zgray', 'x-light');
}

// Timeline List Item Elements (Action)
// -----------------------------------------------------------------------------

.timeline-list-item-action {
  @extend .btnLink--light;
  text-align: center;
  text-decoration: underline;
  display: block;
  transition: all 1s ease;
  margin: 1rem auto 0 auto;
}

.timeline-list-item-action-with-border {
  @extend .btnLink--light;
  text-align: center;
  display: block;
  transition: all 1s ease;
  margin: 1rem auto 0 auto;
  padding: 6px 16px;
  border-radius: 8px;
  border: 1px solid #ffff;
}

.timeline-list-item-actionButtonsGroup {
  @media screen and (max-width: 576px) {
    display: flex;
    margin: 0 0 0 -145px;
  }
  button {
    @media screen and (max-width: 576px) {
      margin-right: 24px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.timeline-list-item-action-active {
  display: block;
}

// Timeline List Item Elements (Status)
// -----------------------------------------------------------------------------

.timeline-list-item-status {
  @extend %body-sm-xlight;
  display: none;
  margin-top: 1rem;
}

.timeline-list-item-status-active {
  @extend %body-sm-xlight;
  display: block;
  margin-top: 1rem;
}

// Timeline Steps Length
// -----------------------------------------------------------------------------

.timeline-5-steps {
  .timeline-line {
    left: 10%;
    width: 80%;
  }

  &.timeline-progress-step-2 .timeline-line-fill {
    width: 25%;
  }

  &.timeline-progress-step-3 .timeline-line-fill {
    width: 50%;
  }

  &.timeline-progress-step-4 .timeline-line-fill {
    width: 75%;
  }

  &.timeline-progress-step-5 .timeline-line-fill {
    width: 100%;
  }
}

// -----------------------------------------------------------------------------
