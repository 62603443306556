// Card Variations - Post
// -----------------------------------------------------------------------------

.post {
  &.mat-card {
    padding: 0;
    border-radius: 5px;
    box-shadow: 0 3px 10px palette('dark-ghost', 10);
  }
}

.post-header {
}

.post-header-media {
  width: 100%;
  height: 150px;
  border-radius: 5px 5px 0 0;
  background-color: palette('zbrown', 'x-light');
  overflow: hidden;
}

.post-header-media-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.post-header-avatar {
  position: absolute;
  width: 50px;
  height: 50px;
  display: inline-block;
  border-radius: 50%;
  margin-top: -25px;
  margin-left: 1.5rem;
  vertical-align: middle;
}

.post-meta {
  margin: 3rem 2rem 0 2rem;
}

.post-meta-title {
  margin: 0;
  @extend %title-dark;
}

.post-body {
  padding: 1.5rem 2rem 1.5rem 2rem;
}

.post-body-form {
  padding: 3rem;
}

.post-body-text {
  @extend %body-middark;
  margin: 0;
  line-height: 1.7rem;
  height: 80px;
}

.post-footer {
  padding: 0 2rem 1.5rem 2rem;
  height: 50px;
}

.post-footer-link {
  @extend %body-brand-sbold;
  display: block;
  text-decoration: none;
  transition: color 0.2s ease;
  white-space: nowrap;
  border: 0;
  background: transparent;
  cursor: pointer;
  padding: 0;
  color: palette('actions', 'warning') !important;

  &:hover {
    color: darken(palette('actions', 'warning'), 15%);
  }
}

.post-footer-time {
  text-align: right;
  @extend %body-middark;
}

// -----------------------------------------------------------------------------
// Card Post Effects
// -----------------------------------------------------------------------------

.post {
  transition: box-shadow 0.5s ease;

  .post-header-media img {
    transition: transform 0.5s ease;
  }

  &:hover {
    box-shadow: 0 3px 20px rgba(palette('dark-ghost', 30), 0.2);

    .post-header-media img {
      transform: scale(1.1);
    }
  }
}

// Post Inline
// -----------------------------------------------------------------------------

.post-inline {
  position: relative;
  height: 106px;
  border: 1px solid palette('zgray', 'light');
  box-shadow: 0 0 0 transparent !important;

  .news {
    font-size: 0.625rem;
    position: absolute;
    right: 0;
    margin: 16px;

    @media only screen and (max-width: 768px) {
      position: static;
      padding-bottom: 10px;
    }
  }

  &:last-child {
    border: 0;
  }

  &:hover {
    .post-media-image {
      transform: scale(1.1);
    }
  }

  .post-media {
    width: 100%;
    height: 106px;
    border-radius: 0 !important;
    background-color: palette('zbrown', 'x-light');
    overflow: hidden;
  }

  .post-media-image {
    display: block;
    width: 106px;
    height: 106px;
    border-radius: 0 !important;
    transition: all 0.5s ease;
    background-size: cover;
    background-position: center center;
  }

  .post-body {
    padding: 1rem;
    text-align: left;
  }

  .post-body-title {
    @extend .body-dark-sbold;
    margin-top: 0;
    margin-bottom: 0.5rem;
  }

  .post-body-text {
    @extend .body-dark;
    line-height: 1.25rem;
    // margin-bottom: 1rem;

    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    height: auto;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .post-body-action {
    @extend .btnLink;
    width: 100%;
    text-align: right;
    position: relative;
    top: -2px;
  }
}

.swiper-pagination-card {
  display: block;
  width: 100%;
  text-align: center;
  margin-top: 1rem;

  .swiper-pagination-bullet {
    display: inline-block;
    margin: auto 4px;
    background-color: palette('zbrown', 'mid-light');
    opacity: 1;
  }

  .swiper-pagination-bullet-active {
    width: 8px;
    height: 8px;
    background-color: palette('actions', 'warning');
  }
}

// -----------------------------------------------------------------------------
