// Toolbar Config
// -----------------------------------------------------------------------------

$toolbar-height: 82px;

$toolbar-left-gradient-color: palette('brand', 'mid-dark');
$toolbar-right-gradient-color: palette('brand');

// Toolbar
// -----------------------------------------------------------------------------

.toolbar-min-height {
  min-height: 150px;

  @media only screen and (max-width: 1024px) {
    min-height: 250px;
  }
}

.toolbar {
  background-color: palette('brand', 'mid-dark');

  > .wrapper {
    display: flex;
    height: $toolbar-height;
    align-items: center;
    align-content: stretch;
  }

  > .wrapper-secondary {
    align-items: start;
  }

  .mt-20 {
    margin-top: 20vh;
  }
}

// Toolbar Brand
// -----------------------------------------------------------------------------

.toolbar-brand {
  margin-top: 0.15rem;
  margin-right: 4rem;
  cursor: pointer;
}

.toolbar-brand-logo {
  display: inline-block;
  width: 45px;
  height: 45px;
  margin-top: 0;
  margin-right: 1rem;
  background-image: url('/assets/img/logo.png');
  background-repeat: no-repeat;
  background-size: cover;
  vertical-align: middle;
}

.toolbar-brand-title {
  @extend %font-bold;
  display: inline-block;
  color: palette('zgray', 'x-light');
  font-size: 1.125rem;
  letter-spacing: 0.01rem;
  vertical-align: middle;
}

// Toolbar Navigation
// -----------------------------------------------------------------------------

.toolbar-navigation {
  display: none;
  width: auto;

  @include notebook {
    display: block;
  }

  > a,
  button {
    display: flex;
    float: left;
    height: $toolbar-height;
    align-items: center;
  }
}

.toolbar-btn {
  @extend %font-regular;
  position: relative;
  padding: 1rem;
  color: palette('zgray', 'x-light');
  font-size: 1.125rem;
  text-decoration: none;
  background-color: transparent;
  border-radius: 0;
  -webkit-box-shadow: 0 0 0 transparent;
  box-shadow: 0 0 0 transparent;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: transparent;
    -webkit-box-shadow: 0 0 0 transparent;
    box-shadow: 0 0 0 transparent;
  }

  &::before {
    position: absolute;
    top: -8px;
    left: 0;
    display: block;
    width: 100%;
    height: 4px;
    -webkit-box-shadow: 0 4px 0 palette('light-ghost', 50);
    box-shadow: 0 4px 0 palette('light-ghost', 50);
    content: ' ';
    opacity: 0;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  &:hover:before,
  &.is-active:before {
    top: -4px;
    opacity: 1;
  }

  &.is-active:before {
    -webkit-box-shadow: 0 4px 0 palette('zgray', 'x-light');
    box-shadow: 0 4px 0 palette('zgray', 'x-light');
  }
}

// Toolbar Actions
// -----------------------------------------------------------------------------

.toolbar-actions {
  position: relative;
  width: auto;

  > button {
    margin-left: 0.5rem;
  }

  .dropdown-navigation {
    @include notebook {
      display: none;
    }
  }

  .ng2-dropdown-menu {
    &.ng2-dropdown-menu--inside-element {
      position: absolute !important;
      top: 28px !important;
      right: 0 !important;
      left: initial !important;
    }
  }
}

.toolbar-actions-menu-notification {
  padding: 2rem 1rem 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  cursor: auto;

  .mat-form-field {
    font-size: 0.8rem;

    .mat-form-field-wrapper {
      padding-bottom: 0 !important;
    }
  }
}

.toolbar-actions-menu {
  position: relative;
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: 94px 140px;
  grid-row-gap: 10px;
  padding: 2rem 0.8rem 0.8rem;
  cursor: auto;

  .mat-form-field {
    font-size: 0.8rem;

    .mat-form-field-wrapper {
      padding-bottom: 0 !important;
    }
  }
}
.toolbar-actions-menu {
  &.notification {
    position: relative;
  }
}

.toolbar-actions-menu_title {
  font: var(--ids_textStyle_f01_s20_h28_wrg);
  font-weight: 400;
  margin: 1rem;
  cursor: default;
}

.toolbar-actions-perfil {
  display: grid;
  grid-auto-flow: column;
  justify-content: flex-start;
  grid-column-gap: 1rem;
}

.toolbar-actions-menu-address-background {
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: 32px 32px;
  grid-row-gap: 0.4rem;
}

.toolbar-actions-menu-address {
  display: flex;
  flex-direction: column;
  cursor: default;

  small {
    font-size: 0.6rem;
  }

  p {
    @extend %font-bold;
    display: inline-block;
    font-size: 0.8rem;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.toolbar-actions-menu-header {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: auto 70px;
  grid-column-gap: 10px;
  align-items: flex-start;
  justify-items: baseline;
  cursor: default;
}

.toolbar-actions-menu_actions {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.toolbar-actions-menu-permission.landrover,
.avatar {
  background-color: palette('zgray', 'x-dark') !important;
  color: palette('zgray', 'x-light') !important;
  border: 1px solid palette('zgray', 'x-dark');
}

.toolbar-actions-menu-permission.jaguar,
.avatar {
  background-color: palette('zgray', 'x-light') !important;
  color: palette('zgray', 'x-dark') !important;
}

.toolbar-actions-menu-permission,
.avatar.interno {
  background-color: palette('zgray', 'x-light');
  color: palette('brand', 'mid-dark');
  border: 1px solid palette('zgray', 'x-light');
}

.toolbar-actions-menu-permission,
.avatar.externo {
  background-color: palette('brand', 'mid-dark');
  color: palette('zgray', 'x-light');
  border: 1px solid palette('zgray', 'x-light');
}

.toolbar-actions-menu-permission {
  font-size: 0.6rem;
  padding: 6px;
  border-radius: 0.5rem;
}

.toolbar-actions-menu-header-title.landrover {
  .perfil {
    p,
    small {
      color: palette('zgray', 'x-dark');
    }
  }
}

.toolbar-actions-menu-header-title {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 40px auto;
  grid-column-gap: 0.5rem;
  align-items: center;

  .avatar {
    height: 35px;
    width: 35px;
    border-radius: 100%;
    padding: 5px;
    margin: auto;
    align-content: center;
    text-align: center;
  }

  .perfil {
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: 14px 20px 14px;

    p,
    small {
      color: palette('zgray', 'x-light');
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    p {
      @extend %font-bold;
      font-size: 1rem;
      line-height: 1.4rem;
      text-transform: capitalize;
    }

    small {
      color: palette('zgray', 'x-light');
      font-size: 0.7rem;
    }
  }
}

.toolbar-actions-menu-header-icon {
  position: relative;
  top: -3px;
  float: right;
  color: palette('zgray', 'x-light');
  font-size: 1.6rem;
  vertical-align: middle;
}

.toolbar-actions-menu-item {
  padding: 0 0.8rem;
  border-bottom: 1px solid palette('zgray', 'mid-light');
  overflow: hidden;

  > span {
    display: block;
  }

  &:last-child {
    border-bottom: 0;
  }
}

.toolbar-actions-list-button {
  .ids-list-item__content,
  .ids-list-item--line-off .ids-list-item__content {
    min-height: var(--ids_size_general_32x);
    padding: var(--ids_size_general_14x) 0 calc(var(--ids_size_general_14x) - var(--ids_size_border_small)) !important;
  }
}

.toolbar-actions-menu-button {
  @extend %font-regular;
  position: relative;
  display: flex;
  width: 100%;
  padding: 0.7rem 1rem;
  color: palette('zbrown', 'dark');
  font-size: 0.8rem;
  text-align: left;
  text-decoration: none;
  align-items: center;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  transition: color 0.2s ease;

  > img,
  > .zf-icon {
    margin-right: 1rem;
  }

  > img {
    width: 21px;
    height: auto;
  }

  &:hover {
    background-color: rgba(59, 59, 59, 0.1);
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.toolbar-btn-actions {
  @extend .btnIcon--light;
  position: relative;
  font-size: 1.7rem;
  overflow: visible;

  &:hover {
    color: palette('zgray', 'x-light');

    .zf-icon {
      color: palette('zgray', 'x-light');
    }
  }
}

.ids-contextual-button {
  padding: var(--ids_size_general_1x) var(--ids_size_general_16x) !important;
}

.ids-contextual-button--on-dark {
  border: 1px solid palette('zgray', 'x-light');
  color: palette('zgray', 'x-light');
  font: var(--ids_textStyle_f02_s14_h20_wbd);
}

.ids-contextual-button--on-light {
  border: currentcolor solid var(--ids_size_border_small);
  color: var(--ids_color_contrast_onLight);
  font: var(--ids_textStyle_f02_s14_h20_wbd);
}

.toolbar-btn-actions-new-ux-header {
  @extend .btnIcon--light;
  position: relative;
  font-size: 1.7rem;
  overflow: visible;
  margin-top: 10px;

  .zf-icon {
    color: #191a23;

    @media only screen and (max-width: 1024px) {
      color: white;
    }
  }

  &:hover {
    color: #191a23;
    .zf-icon {
      color: #191a23;

      @media only screen and (max-width: 1024px) {
        color: white;
      }
    }
  }
}

.avatar-btn.dark,
.avatar-btn.landrover {
  background-color: palette('zgray', 'x-dark');
  color: palette('zgray', 'x-light');
}

.avatar-btn.light,
.avatar-btn.jaguar {
  background-color: palette('zgray', 'x-light');
  color: palette('brand', 'mid-dark');
}

.avatar-btn {
  height: 28px;
  width: 28px;
  border-radius: 100%;
  align-content: center;
  text-align: center;
  font-size: 0.7rem;
  font-weight: 200;

  &:hover {
    font-size: 0.7rem;
  }
}

.toolbar-btn-actions-badge {
  @extend .btn-dot;
  position: absolute;
  top: -5px;
  right: -22px;
  display: block;

  &.toolbar-btn-actions-badge-covered {
    right: -11px;
  }
}

.btn-dot {
  @extend %font-bold;
  display: inline-block;
  width: 22px;
  height: 22px;
  color: palette('zgray', 'x-light');
  font-size: 8px;
  line-height: 22px;
  text-align: center;
  background-color: palette('state', 'info');
  border-radius: 11px;
  vertical-align: middle;

  &.btn-dot-tiny {
    width: 8px;
    height: 8px;
    border-radius: 4px;
  }

  &.btn-dot-success {
    background: palette('state', 'success');
  }

  &.btn-dot-danger {
    background: palette('state', 'danger');
  }

  &.btn-dot-warning {
    background: palette('state', 'warning');
  }
}

.main-navigation-component {
  gap: 32px !important;
  justify-content: space-between;
  display: flex;
  @media only screen and (max-width: 1024px) {
    display: grid !important;
    justify-content: unset;
    margin-top: 16px;
  }
}

// -----------------------------------------------------------------------------
