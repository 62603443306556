// Default Autocomplete
// -----------------------------------------------------------------------------

.autocomplete {
  position: relative;
  background-color: palette('zgray', 'x-light');
  border: 0;
  border-radius: 4px;
}

.autocomplete-input-label {
  font-size: 0.75rem;
  position: relative;
  top: 8px;
}

.autocomplete-input-container {
  position: relative;
  height: 53px;
  line-height: 53px;
}

.autocomplete-input-sufix {
  @include itauicons('\ea2b');
  position: absolute;
  top: 11px;
  right: 11px;
  font-size: 1.75rem;
  color: palette('zbrown', 'dark');
}

.autocomplete-input-control {
  box-sizing: border-box;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 53px;
  padding: 1rem;
  padding-right: 2rem;
  border: 0;
  background-color: transparent;

  font-size: 1.125rem;
  color: palette('zbrown', 'mid-dark');
  outline: 0;

  &::-webkit-input-placeholder {
    color: palette('zbrown', 'mid-dark');
  }

  &::-moz-placeholder {
    color: palette('zbrown', 'mid-dark');
  }

  &:-ms-input-placeholder {
    color: palette('zbrown', 'mid-dark');
  }

  &:-moz-placeholder {
    color: palette('zbrown', 'mid-dark');
  }
}

// Simulation Autocomplete Overrides
// -----------------------------------------------------------------------------

.simulator-autocomplete {
  .simulator-autocomplete-link {
    @extend .btnLink--light;
    position: absolute;
    transform: translateY(-16px);
    right: 0;
  }

  &-xlight {
    .forms-input {
      @extend .forms-input-xlight;
    }

    .simulator-autocomplete-link {
      @extend .btnLink--light;
    }
  }
}

.simulator-autocomplete-id {
  @extend .body-sm-xlight;
  position: absolute;
  bottom: -10px;
  left: 0;
}

.mat-autocomplete-panel {
  width: 100%;
}

.simulator-autocomplete-option {
  height: auto !important;
  margin: 12px 0;

  .simulator-autocomplete-option-image {
    display: inline-block;
    width: 100%;
    max-width: 50px;
    object-fit: contain;
  }

  span {
    display: block;
    min-height: 20px;
    margin: 0;
    padding: 0;
  }

  &.same-year:not(.mat-selected):not(.mat-active) {
    .simulator-autocomplete-option-title,
    .simulator-autocomplete-option-subtitle {
      color: palette('zbrown', 'dark') !important;
    }
  }

  &.other-year:not(.mat-selected):not(.mat-active) {
    .simulator-autocomplete-option-title,
    .simulator-autocomplete-option-subtitle {
      color: palette('zbrown', 'mid-dark') !important;
    }
  }

  .simulator-autocomplete-option-title {
    @extend .body-sm-dark;
    @extend %font-xbold;
  }

  .simulator-autocomplete-option-subtitle {
    @extend .body-sm-dark;
  }
}

.simulator-autocomplete-panel {
  .mat-option.mat-selected:not(.mat-option-multiple) {
    background: rgba(palette('zgray', 'x-dark'), 0.12);
  }

  .mat-option.mat-selected:not(.mat-option-disabled) {
    color: palette('actions', 'warning-light');
  }
}

// Admin Users
// -----------------------------------------------------------------------------

.admin-users-autocomplete {
  padding: 0.2rem 0.5rem;

  .mat-form-field-label,
  .mat-form-field.mat-focused {
    color: palette('zbrown', 'mid-dark') !important;
  }

  .mat-input-element {
    caret-color: palette('zbrown', 'mid-dark');
  }

  .mat-form-field-underline {
    background-color: palette('zbrown', 'mid-dark');
  }

  &.mat-form-field.mat-focused {
    .mat-form-field-ripple {
      background-color: palette('zbrown') !important;
    }
  }
}

// Quote Autocomplete
// -----------------------------------------------------------------------------

.quote-autocomplete {
  .autocomplete-input-label {
    top: -5px;
    color: palette('zbrown', 'mid-dark');
  }

  .autocomplete-input-container {
    position: relative;
    height: auto;
    line-height: normal;
    top: -4px;
  }

  .autocomplete-input-control {
    font-size: 1rem;
    border-bottom-color: palette('zgray', 'light');
    padding-bottom: 0.5rem;
    padding-left: 2rem;
    color: palette('zbrown', 'dark');
    @extend %font-bold;
  }

  .autocomplete-input-prefix {
    margin-top: 5px;
    font-size: 1.2rem;
    color: palette('zbrown', 'mid-dark');
  }
}

// Compact
// -----------------------------------------------------------------------------

.autocomplete-compact {
  border-radius: 4px;
  width: 100%;

  &.mat-form-field-appearance-legacy.mat-form-field .mat-form-field-wrapper {
    .mat-form-field-flex {
      padding: 0rem 1rem;
    }

    padding-bottom: 1em;

    .mat-form-field-underline {
      background-color: transparent;

      .mat-form-field-ripple {
        background-color: transparent;
      }
    }

    .mat-form-field-prefix,
    .mat-form-field-suffix {
      align-self: flex-end;
    }

    .mat-form-field-label,
    .mat-input-element {
      font-size: 1.16rem;
      color: palette('zbrown', 'mid-dark');
      padding-left: 0.6rem;
    }

    .mat-input-element {
      caret-color: palette('zbrown', 'mid-dark');
      margin-top: 0.2rem;
    }

    icon {
      font-size: 1.75rem;
    }
  }
}

// Home Autocomplete
// -----------------------------------------------------------------------------

.home-autocomplete {
  width: 100%;
  //margin-bottom: 0.2rem;
  border-radius: 8px;
  border: 1px solid #a3b5bf;
  background-color: #eff1f4;

  &.mat-form-field-appearance-legacy.mat-form-field .mat-form-field-wrapper {
    padding-bottom: 0.2rem;

    .mat-form-field-flex {
      padding: 0 0.9rem;

      .mat-form-field-infix {
        padding-bottom: 0.8rem;
        border-top: 0.5rem solid transparent;
      }
    }

    .mat-form-field-underline {
      background-color: transparent;

      .mat-form-field-ripple {
        background-color: transparent;
      }
    }

    .mat-form-field-prefix,
    .mat-form-field-suffix {
      align-self: center;
    }

    .mat-form-field-label,
    .mat-input-element {
      font-size: 1.125rem;
      color: #191a23;
    }

    .mat-input-element {
      caret-color: #191a23;
      margin-top: 0.2rem;
    }

    icon {
      font-size: 1.75rem;
      color: #191a23;
    }
  }
}
