$grid-columns: 24 !default;
$grid-gutter-width: 2rem !default;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1370px,
  xxl: 1400px,
) !default;

$container-max-widths: (
  sm: 100%,
  md: 100%,
  lg: 100%,
  xl: 1200px,
  xxl: 1370px,
) !default;

@import 'bootstrap/scss/bootstrap-grid';

$utilities: map-merge(
  $utilities,
  (
    'margin-start':
      map-merge(
        map-get($utilities, 'margin-start'),
        (
          class: ml,
        )
      ),
    'margin-end':
      map-merge(
        map-get($utilities, 'margin-end'),
        (
          class: mr,
        )
      ),
    'padding-start':
      map-merge(
        map-get($utilities, 'padding-start'),
        (
          class: pl,
        )
      ),
    'padding-end':
      map-merge(
        map-get($utilities, 'padding-end'),
        (
          class: pr,
        )
      ),
    'position': (
      property: position,
      values: static relative absolute fixed sticky,
    ),
    'font-family': (
      property: font-family,
      class: font,
      values: (
        monospace: var(--#{$variable-prefix}font-monospace),
      ),
    ),
    'font-size': (
      rfs: true,
      property: font-size,
      class: fs,
      values: $font-sizes,
    ),
    'font-style': (
      property: font-style,
      class: fst,
      values: italic normal,
    ),
    'font-weight': (
      property: font-weight,
      class: fw,
      values: (
        light: $font-weight-light,
        lighter: $font-weight-lighter,
        normal: $font-weight-normal,
        bold: $font-weight-bold,
        bolder: $font-weight-bolder,
      ),
    ),
    'line-height': (
      property: line-height,
      class: lh,
      values: (
        1: 1,
        sm: $line-height-sm,
        base: $line-height-base,
        lg: $line-height-lg,
      ),
    ),
    'text-align': (
      responsive: true,
      property: text-align,
      class: text,
      values: (
        start: left,
        end: right,
        center: center,
      ),
    ),
    'text-decoration': (
      property: text-decoration,
      values: none underline line-through,
    ),
    'text-transform': (
      property: text-transform,
      class: text,
      values: lowercase uppercase capitalize,
    ),
    'white-space': (
      property: white-space,
      class: text,
      values: (
        wrap: normal,
        nowrap: nowrap,
      ),
    ),
    'word-wrap': (
      property: word-wrap word-break,
      class: text,
      values: (
        break: break-word,
      ),
      rtl: false,
    ),
    'width': (
      property: width,
      class: w,
      values: (
        25: 25%,
        50: 50%,
        75: 75%,
        100: 100%,
        auto: auto,
      ),
    ),
    'max-width': (
      property: max-width,
      class: mw,
      values: (
        100: 100%,
      ),
    ),
    'viewport-width': (
      property: width,
      class: vw,
      values: (
        100: 100vw,
      ),
    ),
    'min-viewport-width': (
      property: min-width,
      class: min-vw,
      values: (
        100: 100vw,
      ),
    ),
    'height': (
      property: height,
      class: h,
      values: (
        25: 25%,
        50: 50%,
        75: 75%,
        100: 100%,
        auto: auto,
      ),
    ),
    'max-height': (
      property: max-height,
      class: mh,
      values: (
        100: 100%,
      ),
    ),
    'viewport-height': (
      property: height,
      class: vh,
      values: (
        100: 100vh,
      ),
    ),
    'min-viewport-height': (
      property: min-height,
      class: min-vh,
      values: (
        100: 100vh,
      ),
    ),
  )
);

@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/utilities/api';
@import 'bootstrap/scss/carousel';
