// Breadcrumb
// -----------------------------------------------------------------------------

.breadcrumb {
  position: absolute;
  display: flex;
  flex-direction: row;
  top: -2.3rem;
  z-index: 0;

  &:not(:disabled):hover {
    & > * {
      color: palette('brand', 'mid-dark');
    }
  }

  & .zf-icon {
    font-size: 0.8rem;
    position: relative;
    top: -0.15rem;
    display: inline-block;
    transform: rotate(180deg);
    color: palette('zgray', 'x-light');
  }

  &__link {
    @extend %font-regular;
    margin-left: 0.5rem;
    font-size: 0.9rem;
    color: palette('zgray', 'x-light');
    text-decoration: none;
  }
}

// -----------------------------------------------------------------------------
