.wrapper__email {
  margin: auto;
  display: grid;

  img {
    width: 312px;
    height: 56px;
  }

  section.header {
    display: grid;
    grid-template-columns: 24px auto;
  }

  .ids-button {
    max-height: 50px;
  }
}

.sms {
  width: 312px;
  grid-template-rows: 256px 50px;
  grid-row-gap: 16px;

  app-access-sms {
    display: grid;
    grid-template-rows: 94px 80px 50px;
    grid-row-gap: 16px;
  }
}

.email {
  width: 312px;
  grid-template-rows: 220px 50px;
  grid-row-gap: 16px;

  app-access-email {
    display: grid;
    grid-template-rows: 94px 110px;
    grid-row-gap: 16px;
  }
}

.email-senha {
  width: 359px;
  grid-template-rows: 486px 50px;
  grid-row-gap: 16px;

  app-access-email {
    display: grid;
    grid-template-rows: 28px 186px 146px;
    grid-row-gap: 16px;
  }
}

.email-senha-user-exists {
  width: 312px;
  grid-template-rows: 220px 50px;
  grid-row-gap: 16px;

  app-access-email {
    display: grid;
    grid-template-rows: 35px 168px;
    grid-row-gap: 16px;
  }
}

.success {
  width: 312px;
  grid-template-rows: 220px 50px;
  grid-row-gap: 16px;
}

.nova-senha {
  width: 359px;
  grid-template-rows: 410px 50px;
  grid-row-gap: 24px;

  app-access-new-password {
    display: grid;
    grid-template-rows: 28px 186px 146px;
    grid-row-gap: 24px;
  }
}

.redefinir-senha {
  width: 359px;
  grid-template-rows: 486px 50px;
  grid-row-gap: 8px;

  app-access-new-password {
    display: grid;
    grid-template-rows: 28px 296px 146px;
    grid-row-gap: 8px;
  }
}

section.info {
  margin-top: 10px;
  p {
    font: var(--ids_textStyle_f01_s16_h24_wbd);
    margin-bottom: 8px;
  }

  ul {
    display: grid;
    grid-template-rows: 24px;
    grid-row-gap: 8px;

    li {
      display: grid;
      grid-template-columns: 24px auto;
      grid-row-gap: 2px;
      align-items: center;
      font: var(--ids_textStyle_f02_s14_h20_wrg);
    }
  }
}

section {
  h1 {
    font: var(--ids_textStyle_f01_s20_h28_wrg);
  }
  p {
    font: var(--ids_textStyle_f02_s16_h24_wrg);
  }
}

voxel-form-field {
  voxel-icon {
    cursor: pointer;
  }
}

.input-welcome {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 16px;
}

.input-sms {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 16px;

  input {
    border: var(--ids_size_border_small) solid var(--ids_color_border_medium);
    border-radius: 10px;
    text-align: center;
    font-size: x-large;
    height: 56px;
    width: 56px;
  }
}

.color-success {
  color: var(--ids_color_feedback_success) !important;
}
.color-error {
  color: var(--ids_color_feedback_error) !important;
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.1);
}
