// Alert Module
// -----------------------------------------------------------------------------
.zf-alert {
  --alert-border-radius: 4px;

  --alert-feedback-danger: #b71c1c;
  --alert-feedback-error: #781b8e;
  --alert-feedback-warning: #f1ae2f;
  --alert-feedback-support: #106eb0;
  --alert-feedback-success: #007a47;
  --alert-feedback-primary: #ec7000;

  --alert-feedback-light-danger: #f8e9e9;
  --alert-feedback-light-error: #f2e9f4;
  --alert-feedback-light-warning: #fdf7eb;
  --alert-feedback-light-support: #e8f1f8;
  --alert-feedback-light-success: #e6f2ed;

  --alert-feedback-soft-warning: #FEF7EA;

  --alert-spacing: 8px 24px 8px 8px;

  --alert-text-color-dark: #231d19;
  --alert-text-color-light: #ffffff;

  display: table;
  border-radius: var(--alert-border-radius);
  color: var(--alert-text-color-light);
  height: auto;
  min-height: 4rem;
  // height: 4rem;

  &__wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--alert-spacing);
    width: 100%;
  }

  &__icon {
    font-size: 24px;
    line-height: 24px;
    padding: 12px;
  }

  &__header-text,
  &__error-list {
    @extend %font-regular;
    font-size: 16px;
    line-height: 24px;
    width: 100%;
  }

  &__header-text {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &__close {
    font-size: 16px;
    color: inherit;
    cursor: pointer;
    margin-left: 16px;

    //@media screen and (max-width: 1024px) {
    //  display: none;
    //}
  }

  &__error-list {
    padding: 0 52px 24px;
  }

  &--form-error {
    height: auto;
  }

  &--hidden {
    display: none !important;
  }

  &--status {
    --alert-border-radius: 0px;

    .zf-alert__wrapper {
      flex-direction: row;
    }

    .zf-alert__header {
      --alert-spacing: 8px 0;
    }
  }

  &--danger {
    background-color: var(--alert-feedback-danger);

    a {
      color: var(--alert-text-color-light);
    }
  }

  &--error {
    background-color: var(--alert-feedback-error);
  }

  &--warning {
    background-color: var(--alert-feedback-warning);
    color: var(--alert-text-color-dark);

    a {
      color: var(--alert-text-color-dark);
    }
  }

  &--support {
    background-color: var(--alert-feedback-support);
  }

  &--success {
    background-color: var(--alert-feedback-success);

    a {
      color: var(--alert-text-color-light);
    }
  }

  &--primary {
    background-color: var(--alert-feedback-primary);
  }

  &--multiple {
    background: #faf8f7;
    border: 1px solid #89837f;
    color: var(--alert-text-color-dark);
    .zf-alert__icon {
      color: var(--alert-feedback-primary);
    }
  }

  &--light {
    border: 1px solid;
    color: var(--alert-text-color-dark);

    &.zf-alert {
      &--danger {
        border-color: var(--alert-feedback-danger);
        background-color: var(--alert-feedback-light-danger);
      }

      &--error {
        border-color: var(--alert-feedback-error);
        background-color: var(--alert-feedback-light-error);
      }

      &--warning {
        border-color: var(--alert-feedback-warning);
        background-color: var(--alert-feedback-light-warning);
      }         

      &--support {
        border-color: var(--alert-feedback-support);
        background-color: var(--alert-feedback-light-support);
      }

      &--success {
        border-color: var(--alert-feedback-success);
        background-color: var(--alert-feedback-light-success);
      }
    }
  }

  &--endofflow {
    border-color: var(--alert-feedback-warning);
    border-left: 16px solid var(--alert-feedback-warning);

    .zf-alert__wrapper {
      flex-direction: column;
      place-items: flex-start;
      place-content: flex-start;
      height: 10rem;
      gap: 0;
      color: var(--alert-text-color-dark);
      background-color: var(--alert-feedback-light-warning);
    }

    .zf-alert {
      &__icon {
        margin-left: 0;
        margin-right: auto;
        padding: 0.75rem;
        font-size: 1.5rem;
        color: var(--alert-feedback-warning);
      }

      &__text {
        -webkit-line-clamp: 3;
      }

      &__close {
        display: none;
      }
    }
  }
  &--soft-warning {
    background-color: var(--alert-feedback-soft-warning);
    color: var(--alert-text-color-dark);
    border: 1px solid var(--alert-feedback-warning);
    .zf-alert__header-text {
      font-weight: 400;

    }

    a {
      color: var(--alert-text-color-dark);
    }
  }
}

.alert {
  @extend %body-sm-middark;
  background-color: palette('zbrown', 'x-dark');
  cursor: pointer;

  &.mat-card {
    padding: 1rem;
    box-shadow: 0 0 0 transparent !important;
  }
}

// Alert Elements
// -----------------------------------------------------------------------------

.alert-text {
  @extend %body-sm-xlight;

  .zf-icon {
    position: relative;
    top: -2px;
    margin-right: 0.5rem;
    color: palette('zgray', 'x-light');
    font-size: 1.3rem;
    vertical-align: middle;
  }

  b {
    @extend %font-regular;
  }

  strong {
    @extend %font-bold;
  }
}

// Close Trigger
// -----------------------------------------------------------------------------

.alert-close-wrapper {
  text-align: right;
}

.alert-close {
  @include itauicons('\e94c');
  border: 0;
  background-color: transparent;
  color: palette('zgray', 'x-light');
  font-size: 1.5rem;
  cursor: pointer;
  transition: all 0.2s ease;
  outline: 0;

  &:hover {
    transform: scale(1.1);
    color: palette('brand');
  }
}

// Alert Styles
// -----------------------------------------------------------------------------

.alert-text {
  padding: 0;
  background-color: transparent;
}

.alert-status {
  width: 100%;
  background-color: transparent;

  .alert-text {
    text-align: center;
    color: palette('zbrown', 'mid-dark');

    > b {
      display: block;
      margin-bottom: 1rem;
      @extend %font-xbold;
      font-size: 2rem;
      line-height: 2.5rem;
    }
  }

  .alert-text .zf-icon {
    font-size: 7rem;
    margin: 1.5rem 0;
    color: palette('zbrown', 'dark');
  }

  .alert-action {
    @extend %body-middark;
    display: block;
    text-decoration: none;
    text-align: center;

    .zf-icon {
      position: relative;
      top: 0px;
      margin-left: 1rem;
      font-size: 2rem;
    }
  }
}

.alert-float {
  position: absolute !important;
  right: 0;
  width: 250px;
  z-index: 1;
  transform: translateY(calc(100% + 1rem));

  &.mat-card {
    padding: 1rem;
  }

  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

// Alert Colors
// -----------------------------------------------------------------------------

.alert-xlight {
  .alert-text,
  .alert-text .zf-icon {
    color: palette('zgray', 'x-light');
  }
}

.alert-danger {
  .alert-text,
  .alert-text .zf-icon {
    color: palette('state', 'danger');
  }
}

.alert-success {
  .alert-text,
  .alert-text .zf-icon {
    color: palette('state', 'success');
  }
}

.alert-info {
  .alert-text,
  .alert-text .zf-icon {
    color: palette('state');
  }
}

.alert-pending {
  .alert-text,
  .alert-text .zf-icon {
    color: palette('state', 'info');
  }
}

.alert-warning {
  .alert-text,
  .alert-text .zf-icon {
    color: palette('state', 'warning');
  }
}

// -----------------------------------------------------------------------------
