// Document
// -----------------------------------------------------------------------------

@import '@zflow/itau-fonts/itaudisplay/stylesheet.css';
@import '@zflow/itau-icons/src/style.css';

// lt < rg < b < xb < hv < bk

.font-light,
%font-light {
  font-family: 'itau_displaylight', sans-serif;
  font-weight: 200;
}

.font-regular,
%font-regular {
  font-family: 'itau_displayregular', sans-serif;
  font-weight: 400;
}

.font-bold,
%font-bold {
  font-family: 'itau_displaybold', sans-serif;
  font-weight: 600;
}

.font-xbold,
%font-xbold {
  font-family: 'itau_displayxbold', sans-serif;
  font-weight: 700;
}

// useless
.font-heavy,
%font-heavy {
  font-family: 'itau_displayheavy', sans-serif;
  font-weight: 700;
}

// useless
.font-black,
%font-black {
  font-family: 'itau_displayblack', sans-serif;
  font-weight: 700;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  outline: 0;
}

html,
body {
  @extend %font-regular;
  font-size: 14px;
  margin: 0;
  background-color: palette('zbrown', 'x-light');
  overflow-x: hidden;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  ::selection {
    color: palette('zgray', 'x-light');
    background: palette('brand');
    /* WebKit/Blink Browsers */
  }

  ::-moz-selection {
    color: palette('zgray', 'x-light');
    background: palette('brand');
    /* Gecko Browsers */
  }

  // Medium Devices
  @include tablet {
    font-size: 16px;
  }

  @include desktop {
    /* Chrome, Edge and Safari */
    *::-webkit-scrollbar {
      height: 10px;
      width: 10px;
    }

    *::-webkit-scrollbar-track {
      border-radius: 5px;
      background-color: palette('zgray', 'mid-light');
    }

    *::-webkit-scrollbar-track:hover {
      background-color: palette('zgray', 'mid-light');
    }

    *::-webkit-scrollbar-track:active {
      background-color: palette('zgray', 'mid-dark');
    }

    *::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: palette('zgray', 'mid-dark');
    }

    *::-webkit-scrollbar-thumb:hover {
      background-color: palette('actions', 'warning');
    }

    *::-webkit-scrollbar-thumb:active {
      background-color: palette('actions', 'warning');
    }
  }
}

ol,
ul {
  &.no-list-style {
    list-style: none;
    padding: 0;
  }
}

a,
a:link,
a:visited {
  &.no-underline {
    // refactor with a 'text' class (maybe supress)
    color: inherit;
    font: inherit;
    text-decoration: none;
  }
}

body.hideChat {
  #lpChat,
  #lpCtaMinimized,
  div[id^='LPMcontainer'] {
    display: none !important;
  }
}

figure {
  margin: 0;
  padding: 0;
}

button {
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  font: inherit;
  outline: none;
  padding: 0;
}

// -----------------------------------------------------------------------------

b,
strong {
  @extend %font-bold;
}

p {
  @extend %font-regular;
  @extend %body-size;
  line-height: 1.25rem;
  margin-top: 0;
  margin-bottom: 0;
  &:not(:first-of-type) {
    margin-top: 1rem;
  }
}

hr {
  height: 1px;
  margin: 0;
  background-color: palette('zgray', 'mid-light');
  border: none;
}

// -----------------------------------------------------------------------------

.voxel-overlay-tooltip {
  z-index: 1 !important;
}
