// Accessory
// -----------------------------------------------------------------------------

.accessory-offer {
  min-height: 174px;

  .card-header {
    margin-bottom: 10px;

    .card-header-title {
      font-size: 18px !important;
      line-height: 22px;
    }
  }

  .accessory-offer-display {
    display: flex;
    align-items: center;
    min-height: 40px;
    overflow: visible;
    flex-wrap: wrap;
    min-height: 73px;
    padding-top: 10px;
    align-items: flex-start;
  }

  .accessory-offer-actions {
    margin-top: 12px;
  }

  &.accessory-disabled {
    opacity: 0.75;
  }
}

.car-insurance-offer {
  border-radius: var(--ids_size_cornerRadius_card_01);

  .card-header {
    margin-bottom: 10px;

    .card-header-title {
      font-size: 18px !important;
      line-height: 22px;
    }
  }

  .accessory-offer-display {
    display: flex;
    align-items: center;
    min-height: 40px;
    overflow: visible;
    flex-wrap: wrap;
    min-height: 73px;
    padding-top: 10px;
    align-items: flex-start;
  }

  &.accessory-disabled {
    opacity: 0.75;
  }
}

.service-offer {
  min-height: 174px;

  .service-offer-display {
    display: flex;
    align-items: center;
    min-height: 40px;
    margin-top: 16px;
    overflow: visible;
  }

  .service-offer-actions {
    margin-top: 12px;
  }
}

.insurance-option {
  @extend %body-dark;

  section {
    position: relative;
    padding: 24px 0;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }

    &:not(:first-child) {
      border-top: 1px solid palette('zgray', 'mid-light');
    }

    strong {
      @extend %body-dark-sbold;
      margin-bottom: 24px;
    }

    .forms-radio {
      position: absolute;
      top: 0;
      left: 0;
    }

    .insurance-option-company-logo {
      margin: 0 auto;
    }

    .insurance-option-display {
      margin-top: 16px;
      text-align: center;

      span {
        margin-bottom: 0.5rem;

        &.headline-dark {
          margin-bottom: 0.75rem;
        }
      }
    }
  }
}

.processing {
  //padding: 60px;
  border-top: 1px solid rgba(palette('zgray', 'x-light'), 0.2);

  .zf-icon {
    font-size: 76px;
  }
}

// -----------------------------------------------------------------------------
