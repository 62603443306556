//** Dashboard Display
// ---------------------------------------------------------------------------

.dashboardDisplay {
  height: 100%;
  display: flex;
  flex-direction: column;

  @include tablet {
    flex-direction: row;
  }

  .dashboardDisplay_item {
    width: 100%;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: row;
    flex: 0 1 auto;

    @include tablet {
      flex-direction: column;

      & {
        margin-right: 2rem;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    & > .card {
      width: 100%;
      height: 100%;
      padding: 1.5rem 1.5rem 1.5rem;
      display: flex;
      flex-direction: row;
    }

    .zf-icon {
      font-size: 3rem;
      margin-right: 1.5rem;
      color: palette('zbrown', 'dark');
    }

    & small {
      font-size: 1.6rem;
      @extend %font-regular;
      color: palette('zbrown', 'dark');
    }

    & legend {
      @extend %font-light;
      color: palette('zbrown', 'dark');
    }

    & p {
      margin: 0 0 1rem;
      font-size: 2.2rem;
      line-height: 2.8rem;
      @extend %font-bold;
      color: palette('zbrown', 'dark');
    }
  }
}

// ---------------------------------------------------------------------------
